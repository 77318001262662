import React, { useCallback, useMemo, useState, useEffect } from 'react'

import LegalMentionsAccordion from '../../../../components/presentationals/AutoDiagnostic/LegalMentionsAccordion/LegalMentionsAccordion'
import Steps from '../../../../components/presentationals/AutoDiagnostic/Steps/Steps'
import { useSessionStorage } from '../../../../hooks/useSessionStorage'
import { IAnalyticsDataLayer } from '../../../../utils/analytics/analytics'
import deepFindKey from '../../../../utils/misc/deepFindKey'
import { SESSION_STORAGE_KEY, STEPS_META } from '../../constants'
import { ACTIONS } from '../../steps/enjeux'
import ScrollAnchor from '../ScrollAnchor/ScrollAnchor'
import Question1Form from './Question1Form/Question1Form'
import Question2Form from './Question2Form/Question2Form'
import Question3Form from './Question3Form/Question3Form'
import Question4Form from './Question4Form/Question4Form'

interface IProps {
  state: any
  send: (action: any, payload?: any) => void
}

export default function Step1({ send, state }: IProps) {
  // The highest index question to have been edited
  const [highestQuestionIndex, setHighestQuestionIndex] = useState<number>(0)
  const [, setSessionStorageState] = useSessionStorage<IAnalyticsDataLayer>(SESSION_STORAGE_KEY, {})

  const questionIndex: number = useMemo(() => {
    const index = deepFindKey(state.meta, 'questionIndex')
    if (index > highestQuestionIndex) setHighestQuestionIndex(index)
    return index
  }, [state])

  useEffect(() => {
    setSessionStorageState(state.context)
  }, [questionIndex])

  const handleOnSubmit = useCallback(({ data }) => send(ACTIONS.NEXT, { data }), [send])

  return (
    <>
      <Steps steps={STEPS_META} currentIndex={0} />

      <div className="py-10 bg-green-dark-100">
        <div className="px-6 mx-auto lg:w-3/5 xl:w-1/2">
          {/* Question 1 */}
          <Question1Form
            onSubmit={handleOnSubmit}
            onEdit={() => send(ACTIONS.EDIT_QUESTION_1)}
            showForm={questionIndex === 1}
            isRevision={highestQuestionIndex > 1}
            dataTestId="question-1-form"
            initialValues={{
              workLifeQuality: 66.6
            }}
            context={state.context}
          />

          {/* Question 2 */}
          {highestQuestionIndex > 1 && (
            <>
              <ScrollAnchor active={questionIndex === 2 && highestQuestionIndex === 2} />
              <Question2Form
                onSubmit={handleOnSubmit}
                onEdit={() => send(ACTIONS.EDIT_QUESTION_2)}
                showForm={questionIndex === 2 || highestQuestionIndex === 2}
                isRevision={highestQuestionIndex > 2}
                disableEditionButton={questionIndex < 2}
                dataTestId="question-2-form"
                initialValues={{
                  'engagement-salarie': false,
                  'fideliser-talents': false,
                  'concilier-vie-privee-pro': false,
                  'ameliorer-marque': false,
                  'diminuer-absenteisme': false,
                  aucune: false
                }}
                context={state.context}
              />
            </>
          )}

          {/* Question 3 */}
          {highestQuestionIndex > 2 && (
            <>
              <ScrollAnchor active={questionIndex === 3 && highestQuestionIndex === 3} />
              <Question3Form
                onSubmit={handleOnSubmit}
                onEdit={() => send(ACTIONS.EDIT_QUESTION_3)}
                showForm={questionIndex === 3 || highestQuestionIndex === 3}
                isRevision={highestQuestionIndex > 3}
                disableEditionButton={questionIndex < 3}
                dataTestId="question-3-form"
                initialValues={{
                  'engagement-salarie': false,
                  'fideliser-talents': false,
                  'concilier-vie-privee-pro': false,
                  'ameliorer-marque': false,
                  'diminuer-absenteisme': false,
                  aucune: false,
                  other: ''
                }}
                context={state.context}
              />
            </>
          )}

          {/* Question 4 */}
          {highestQuestionIndex > 3 && (
            <>
              <ScrollAnchor active={questionIndex === 4 && highestQuestionIndex === 4} />
              <Question4Form
                onSubmit={handleOnSubmit}
                onEdit={() => send(ACTIONS.EDIT_QUESTION_4)}
                showForm={questionIndex === 4 || highestQuestionIndex === 4}
                disableEditionButton={questionIndex < 4}
                dataTestId="question-4-form"
                initialValues={{
                  employeesWithChild: 75
                }}
              />
            </>
          )}
        </div>
      </div>

      <LegalMentionsAccordion />
    </>
  )
}
