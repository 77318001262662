import React from 'react'
import Icon, { ICONS } from '../../components/presentationals/Icon'
import cs from 'classnames'

export function SlideCustomArrow(props) {
  const { className, classNameCustom, color, onClick, isPrev = false } = props
  return (
    <div onClick={onClick}>
      <Icon
        className={cs(className, classNameCustom)}
        name= {color == "orange" ? ( isPrev ? ICONS.SliderPrevArrowOrange : ICONS.SliderNextArrowOrange) : 
        (isPrev ? ICONS.SliderPrevArrow : ICONS.SliderNextArrow)}
        width="48"
        height="48"
      />
    </div>
  )
}
