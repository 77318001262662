/**
 * Creates a scale from a minimum, a maximum, and a step
 * @param start - The minimum value of the scale
 * @param stop - The maximum value of the scale
 * @param step - Distance between two values of the scale
 */
export default function computeScale(start: number, stop: number, step: number) {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
    const val = start + i * step
    return Math.ceil(val) === stop ? Math.ceil(val) : val
  })
}
