import React, { useEffect } from 'react'

import Steps from '../../../../components/presentationals/AutoDiagnostic/Steps/Steps'
import Button, { BUTTON_VARIANTS } from '../../../../components/presentationals/Button/Button'
import useScrollTopOnLoad from '../../../../hooks/useScrollTopOnLoad'
import { useSessionStorage } from '../../../../hooks/useSessionStorage'
import DoubleCards from '../../../../paragraphs/double_cards/Component'
import TabbableLists from '../../../../paragraphs/tabbable_lists/Component'
import { getComponent } from '../../../../paragraphs/utils'
import { IDrupalButton } from '../../../../types/drupal'
import {
  IAnalyticsDataLayer,
  IAnalyticsEvent,
  sendVirtualPageViewAnalytics
} from '../../../../utils/analytics/analytics'
import { SOUSCRIPTION_AVAILABLE_CLASS_SELECTOR } from '../../../../utils/iAdvize/showModal'
import { BUTTON_ACTIONS_NAMES } from '../../../../utils/paragraphs/buttonPropsFormatter'
import { IContext, SESSION_STORAGE_KEY, STEPS_META } from '../../constants'
import tabbableListsImages from './Step3TabbableListsImages'

interface IProps {
  state: any
  send: (action: any) => void
  button?: IDrupalButton
}

export default function Step3({ state, button }: IProps) {
  const {
    enjeux,
    entreprise: { companyId }
  } = state.context as IContext

  const focusedPackIdentifier = (enjeux.employeesWithChild ?? -1) < 50 ? 'well_being' : 'family'

  const [, setSessionStorageState] = useSessionStorage<IAnalyticsDataLayer>(SESSION_STORAGE_KEY, {})

  useScrollTopOnLoad()
  useEffect(() => {
    setSessionStorageState(state.context)

    const virtualPagename = `${document.location.pathname}/etape3`
      ; (window as IAdvizeWindow).iAdvize?.navigate(virtualPagename)

    // sending event asynchronously to await for sessionStorage sync
    setTimeout(() => {
      sendVirtualPageViewAnalytics(
        {
          virtualPagename,
          offerName:
            focusedPackIdentifier === 'well_being'
              ? 'Pack Flex Santé Bien-être'
              : 'Pack Flex Famille'
        },
        IAnalyticsEvent.RECO_AUTODIAGNOSTIQUE
      )
      sendVirtualPageViewAnalytics({
        virtualPagename,
        offerName:
          focusedPackIdentifier === 'well_being' ? 'Pack Flex Santé Bien-être' : 'Pack Flex Famille'
      })
    })
  }, [setSessionStorageState])

  return (
    <>
      <Steps steps={STEPS_META} currentIndex={2} />

      <section>
        {getComponent({
          typename: 'paragraph__packs_promo',
          extraProps: {
            // overriding the contributed button
            button: undefined,
            recommendFocusedPack: true,
            // at this point, employeesWithChild can't be null, but as TS wants to be sure...
            focusedPackIdentifier,
            numberOfEmployees: companyId?.trancheEffectifsEtablissement || 1
          }
        })}
        <div className="flex justify-center px-8 pb-8 bg-green-dark-100">
          <Button
            className="flex items-center mt-12 md:mt-0"
            variant={BUTTON_VARIANTS.PRIMARY}
            ariaLabel="Je souhaite souscrire à un pack"
            action={BUTTON_ACTIONS_NAMES.CONTACT_POPIN}
            actionIAdvizeAvailableClass={SOUSCRIPTION_AVAILABLE_CLASS_SELECTOR}
            analyticsDataLayer={{
              buttonLabel: "Je souhaite souscrire à un pack"
            }}
          >
            Je souhaite souscrire à un pack
          </Button>
        </div>

        {button && (
          <div className="px-4">
            <DoubleCards
              cards={[
                {
                  title: 'Besoin de services en plus ?',
                  description: 'Découvrez nos services à la carte',
                  button: button
                }
              ]}
            />
          </div>
        )}

        <TabbableLists
          title="3 bonnes raisons de choisir Flex"
          tabs={[
            {
              tabLabel: 'Pour les employés',
              list: [
                {
                  image: tabbableListsImages.Confiance,
                  title: 'Confiance',
                  description:
                    'Filiale du Groupe La Mutuelle Générale, Flex bénéficie de sa solidité et partage ses valeurs.'
                },
                {
                  image: tabbableListsImages.Satisfaction,
                  title: 'Satisfaction',
                  description:
                    'Chez Flex, la Relation Client est une priorité, tout est mis en œuvre pour votre satisfaction.'
                },
                {
                  image: tabbableListsImages.Souplesse,
                  title: 'Souplesse',
                  description:
                    'Flex propose des formules qui s’adaptent aux modes de vie des salariés et aux besoins des entreprises.'
                }
              ]
            }
          ]}
        />
      </section>
    </>
  )
}
