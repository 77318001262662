/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby'

import { IParagraph } from '..'
import Component, { IProps } from './Component'
import { unwrapMaybe as _ } from '../utils'
import { IPageContactQuery, IParagraph__Contact } from '../../graphql.types'

const propsFormatter = ({
  field_image,
  field_description_particulier_dr,
  field_title,
  field_legend,
  field_rich_description,
  field_informations_complementair,
  field_titre_droite_entreprise,
  field_titre_gauche_entreprise,
  field_titre_gauche_part,
  field_plain_description,
  field_image_entreprise,
  field_description_gauche_entrepr,
  field_description_gauche_particu,
  field_affichage_particulier,
  relationships
}: IParagraph__Contact): IProps => {
  return {
    image: {
      alt: _('alt', field_image?.alt),
      ..._('image', relationships?.field_image?.localFile?.childImageSharp),
    },
    field_image_entreprise: {
      alt: _('alt', field_image_entreprise?.alt),
      ..._('image', relationships?.field_image_entreprise?.localFile?.childImageSharp),
    },
    field_description_particulier_dr,
    field_title,
    field_legend,
    field_rich_description,
    field_informations_complementair: field_informations_complementair.value,
    field_titre_droite_entreprise,
    field_titre_gauche_entreprise,
    field_titre_gauche_part,
    field_plain_description,
    field_description_gauche_entrepr: field_description_gauche_entrepr.value,
    field_description_gauche_particu: field_description_gauche_particu.value,
    popin_succes: {
      ..._('popinsucces', relationships?.field_popin_succes)
    },
    popin_echec: {
      ..._('popinsucces', relationships?.field_popin_echec)
    },
    field_affichage_particulier
  }
}
const Query = () =>
  useStaticQuery<IPageContactQuery>(graphql`
  query AllContact {
    data: allParagraphContact {
      edges {
        node {
          field_affichage_particulier
          id
          relationships {
            field_popin_echec {
              field_title
              field_title_color
              field_color
              field_rich_description {
                value
              }
            }
            field_popin_succes {
              field_title
              field_title_color
              field_color
              field_rich_description {
                value
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            field_image_entreprise {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          parent_field_name
          field_image {
            alt
          }
          field_image_entreprise {
            alt
          }
          field_title
          field_legend
          field_rich_description {
            value
          }
          drupal_internal__revision_id
          field_informations_complementair {
            value
          }
          field_titre_droite_entreprise
          field_titre_gauche_entreprise
          field_description_particulier_dr
          id
          field_titre_gauche_part
          field_plain_description
          field_description_gauche_entrepr {
            value
          }
          field_description_gauche_particu {
            value
          }
        }
      }
    }
  } 
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
