import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import React from 'react'

import { IColor } from '../../types/gatsby/colors'
import { IImage } from '../../types/gatsby/images'

import styles from './Component.module.css'

export interface IProps {
  // A color to apply to the background - It needs to be one key of tailwind colors
  backgroundColor?: IColor
  /** The title that will be displayed using an H1 */
  title: string
  /** An optional description */
  description?: string
  /** The heading image */
  image?: IImage
}

export default function Component({
  backgroundColor = 'orange',
  title,
  description,
  image
}: IProps) {
  return (
    <>
      {image && (
        <div className="mx-auto bg-white">
          <GatsbyImage className={cs('object-cover w-full', styles.banner)} {...image} />
        </div>
      )}

      <section className="bg-white">
        <div className={cs('pt-12 pb-6', `bg-${backgroundColor}-100`)}>
          <div className="container mx-auto">
            <h1
              className="mb-5 text-2xl font-medium leading-none text-center whitespace-pre-line sm:text-4xl rich-contribution md:text-6xl font-brand text-purple-dark-500"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="w-12 h-1 mx-auto bg-orange-500 rounded"></div>
            {description && (
              <p className="mt-5 font-sans leading-normal text-center text-gray-800">
                {description}
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
