/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllButtonsQuery, IParagraph__Buttons } from '../../graphql.types'
import { IColor } from '../../types/gatsby/colors'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_flex_color,
  relationships
}: IParagraph__Buttons): IProps => {
  return {
    title: field_title,
    description: field_plain_description,
    backgroundColor: field_flex_color as IColor,
    buttons: _('buttons', relationships?.field_buttons).map(button => ({
      link: {
        href: button?.field_link?.uri_alias || button?.field_link?.uri
      },
      title: _('title', button?.field_title),
      target: button?.field_link_target,
      action: button?.field_button_action_id,
      style: button?.field_style
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllButtonsQuery>(graphql`
    query AllButtons {
      data: allParagraphButtons {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            field_plain_description
            field_flex_color
            relationships {
              field_buttons {
                field_title
                field_link {
                  uri
                  uri_alias
                }
                field_link_target
                field_button_action_id
                field_style
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
