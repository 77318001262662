import cs from 'classnames'
import React from 'react'

import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import { IDrupalButton } from '../../types/drupal/index'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

export interface IProps {
  cards: {
    title: string
    description: string
    button: IDrupalButton
  }[]
}

export default function Component({ cards }: IProps) {
  return (
    <section className="flex flex-col lg:flex-row">
      {cards.map((card, index) => (
        <div
          key={index}
          className={cs(
            'flex-1 px-6 pt-10 pb-12 mt-4 text-center bg-circle lg:mr-2',
            Boolean(index)
              ? 'bg-blue-100 bg-circle-top bg-circle-right bg-circle-blue'
              : 'bg-pink-100 bg-circle-bottom bg-circle-left bg-circle-purple lg:ml-2'
          )}
        >
          <h3 className="mb-4 text-3xl font-medium leading-none font-brand text-purple-dark-500">
            {card.title}
          </h3>
          <p className="mb-10 font-sans leading-normal text-gray-700 ">{card.description}</p>
          <Button
            className="mb-2"
            {...buttonPropsFormatter(card.button)}
            variant={!card.button.style || card.button.style === 'Défaut' ? BUTTON_VARIANTS.SECONDARY : card.button.style === "PRIMARY" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
          />
        </div>
      ))}
    </section>
  )
}
