import React, { useState, useLayoutEffect, MouseEvent } from 'react'
import Youtube, { Options } from 'react-youtube'
import cs from 'classnames'

import style from './YoutubeVideoModal.module.css'

interface IProps {
  videoId?: string
  uri?: string
  onBackdropClick?: (e: MouseEvent) => void
}


export default function YoutubeVideoModal({ videoId, uri, onBackdropClick }: IProps) {
  const [options, setOptions] = useState<Options>({
    width: (window.innerWidth * 0.9).toString(),
    height: (window.innerHeight * 0.8).toString(),
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      loop: 1,
      rel: 0,
      controls: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      iv_load_policy: 3
    }
  })

  useLayoutEffect(() => {
    const handleResize = () =>
      setOptions({
        ...options,
        width: (window.innerWidth * 0.9).toString(),
        height: (window.innerHeight * 0.8).toString()
      })

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  return (
    <div
      className={cs(
        'z-30 fixed top-0 left-0 h-screen overflow-hidden w-screen flex items-center',
        style.backdrop
      )}
      onClick={onBackdropClick}
    >
      <div className="mx-auto">
        {(!uri && videoId) && (
          <Youtube videoId={videoId} opts={options} />)}
        {(uri) && (
          <iframe src={ uri + "autoplay=1&loop=1&modestbranding=1&rel=0&iv_load_policy=3&controls=0"}
          width={(window.innerWidth * 0.9).toString()} 
          height={(window.innerHeight * 0.8).toString()} 
          allow="autoplay;"
          allowFullScreen
          frameBorder="0"
          />
        )}
        
      </div>
    </div>
  )
}