import cs from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useState } from 'react'
import RadioField, { RADIO_VARIANTS } from '../../components/formik/RadioField/RadioField';
import Icon, { ICONS } from '../../components/presentationals/Icon'
import toCommaFixed from '../../utils/misc/toCommaFixed';

function loadPack() {
  let data = useStaticQuery(graphql`
  query AllPackIndividuelSouscription {
    data: allNodePackIndividuel {
      nodes {
        relationships {
          field_pack {
            field_title
            field_price
            relationships {
              field_services {
                relationships {
                  field_service {
                    title
                  }
                }
              }
            }
            field_code_offre
            field_libelle_prix_ligne_1
            field_libelle_prix_ligne_2
            field_libelle_prix_ligne_3
            field_libelle_prix_ligne_4
            field_libelle_prix_ligne_5
            field_prix_ligne_2
            field_prix_ligne_3
            field_prix_ligne_4
          }
          field_option {
            field_price
            field_title
            relationships {
              field_list {
                field_code_offre
                field_option
              }
            }
            field_libelle_prix_ligne_1
            field_libelle_prix_ligne_2
            field_prix_ligne_2
          }
        }
      }
    }
  }
  `).data.nodes.map((pack: any) => (
    {
      option: {
        options: pack.relationships.field_option?.relationships.field_list,
        prix: pack.relationships.field_option?.field_price,
        libellePrix: pack.relationships.field_option?.field_libelle_prix_ligne_1,
        prix2: pack.relationships.field_option?.field_prix_ligne_2,
        libellePrix2: pack.relationships.field_option?.field_libelle_prix_ligne_2,
        titre: pack.relationships.field_option?.field_title,
      },
      pack: {
        prix: pack.relationships?.field_pack.field_price,
        libellePrix: pack.relationships.field_pack?.field_libelle_prix_ligne_1,
        prix2: pack.relationships.field_pack?.field_prix_ligne_2,
        libellePrix2: pack.relationships.field_pack?.field_libelle_prix_ligne_2,
        prix3: pack.relationships.field_pack?.field_prix_ligne_3,
        libellePrix3: pack.relationships.field_pack?.field_libelle_prix_ligne_3,
        prix4: pack.relationships.field_pack?.field_prix_ligne_4,
        libellePrix4: pack.relationships.field_pack?.field_libelle_prix_ligne_4,
        libellePrix5: pack.relationships.field_pack?.field_libelle_prix_ligne_5,
        titre: pack.relationships?.field_pack.field_title,
        services: pack.relationships?.field_pack?.relationships?.field_services,
        codeOffre: pack.relationships?.field_pack.field_code_offre,
      }
    })
  )
  return data;
}

export default function SouscriptionOptionsSelected({ onItemClick }) {
  const data: [] = loadPack()

  let params = new URLSearchParams();

  if (typeof window !== "undefined") {

    params = new URLSearchParams(location.search);

  }

  const [value, setFieldValue] = useState()

  const handleClick = useCallback(value => {
    setFieldValue(value);
    onItemClick(value);
  }, [setFieldValue])

  const offre = params.get("offre");

  if (value === undefined) {
    setFieldValue(offre);
  }

  const pack = data.find(pack => pack.pack.codeOffre === offre) ||
    data.find(pack => pack.option.options?.find((x: any) => x.field_code_offre === offre))
  if ((typeof window !== "undefined") && (pack === undefined)) {
    window.location = '/';
  }
  const isPackAvecOption = pack?.option?.options?.length > 0;

  return (
    <div className="w-full" >
      <div className="mb-3 hidden sm:flex">
        <div className="w-4/6 mr-5">
          <span className="text-xl text-purple-dark-500 font-bold font-brand">Votre pack</span>
        </div>
        {isPackAvecOption &&
          <div className="w-2/6">
            <span className="text-xl font-bold text-orange-500 font-brand">{value != pack?.pack.codeOffre ? 'Votre option' : 'J’ajoute une option au choix'}</span>
          </div>
        }
      </div>
      <div className="flex flex-col sm:flex-row md:mt-0">
        <div className={cs("mb-8 sm:mb-0", isPackAvecOption ? "sm:w-4/6 sm:mr-5" : "sm:w-full")}>
          <span className="text-xl text-purple-dark-500 font-bold sm:hidden">Votre pack</span>
          <div className="bg-white h-full px-8 py-6 rounded-xl shadow-lg">
            <div className="text-purple-dark-500 text-lg border-b border-purple-dark-500 min-h-16 flex">
              <div>
                <Icon
                  className="inline"
                  name={ICONS.Pack}
                  width="48"
                  height="48"
                />
              </div>
              <div className="flex w-full items-center">
                <div className="inline-grid sm:inline align-middle ml-2 sm:ml-4 w-full">
                  <span className="font-bold">« {pack?.pack.titre} »</span>
                </div>
                <span className="float-right text-xl lg:text-3xl font-bold min-w-max">{toCommaFixed((pack?.pack.prix3), 2)} € {pack?.pack.libellePrix3 ? pack?.pack.libellePrix3 : ''}</span>
              </div>
            </div>
            <div className="py-2">
              <ul className="text-sm leading-8">
                {pack?.pack.services.map((li, key) => (
                  <li key={key} className="mb-1 px-2">
                    <Icon
                      className="inline mr-2"
                      name={ICONS.CircleCheck}
                      width="24"
                      height="24"
                    />
                    {li.relationships.field_service.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={cs(isPackAvecOption ? "sm:w-2/6" : "hidden")}>
          <span className="text-xl font-bold text-orange-500 font-brand sm:hidden">{value != pack?.pack.codeOffre ? 'Votre option' : 'J’ajoute une option au choix'}</span>
          <div className="bg-white h-full px-8 py-6 rounded-xl shadow-lg">
            <div className="text-purple-dark-500 text-lg border-b border-purple-dark-500 min-h-16 flex">
              <div>
                <Icon
                  className="inline"
                  name={ICONS.Option}
                  width="48"
                  height="48"
                />
              </div>
              <div className="flex w-full items-center">
                <div className="inline-grid sm:inline align-middle ml-2 sm:ml-4 w-full">
                  <span className="font-bold">Option</span>
                </div>
                <span className="float-right text-xl lg:text-3xl font-bold min-w-max">{value != pack?.pack.codeOffre ? `${toCommaFixed(pack?.pack.prix4, 2)} € ${pack?.pack.libellePrix4}` : ''}</span>
              </div>
            </div>
            <div className="py-2">
              <ul className="text-sm leading-8">
                {pack?.option?.options?.map((li: string, index: number) => (
                  <li key={index}>
                    <RadioField
                      key={index}
                      className="mb-3 leading-6 px-2"
                      textClassName=""
                      text={li.field_option}
                      value={li.field_code_offre}
                      checked={value == li.field_code_offre}
                      onClick={handleClick}
                      variant={RADIO_VARIANTS.SIMPLE}
                    />
                  </li>
                ))}
                <li>
                  <RadioField
                    key='-1'
                    className="mb-3 px-2"
                    text="Non merci"
                    value={pack?.pack.codeOffre}
                    checked={value == pack?.pack.codeOffre}
                    onClick={handleClick}
                    variant={RADIO_VARIANTS.SIMPLE}
                  />
                </li>
                <input id="optionChoisi" type="checkbox" value={value} className="hidden" />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-16 py-3 text-right border-t-2 border-green-light-500">
        <span className="text-green-light-500 text-3xl font-bold font-brand">Total = {(value == pack?.pack.codeOffre || !isPackAvecOption) ? toCommaFixed(pack?.pack.prix3, 2) : toCommaFixed((pack?.pack.prix4 + pack?.pack.prix3), 2)} € {pack?.pack.libellePrix5}</span>
      </div>
    </div>
  )
}