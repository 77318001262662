/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IParagraph__Formulaire_Souscription } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_rich_description,
  relationships
}: IParagraph__Formulaire_Souscription): IProps => {
  return {
    title: field_title,
    cgv: field_rich_description?.value,
    button: {
      title: relationships?.field_button?.field_title,
      target: relationships?.field_button?.field_link_target,
      link: {
        href: relationships?.field_button?.field_link?.uri_alias || relationships?.field_button?.field_link?.uri
      }
    }
  }
}

const Query = () =>
  useStaticQuery(graphql`
    query AllSouscriptionFormulaire {
      data: allParagraphSouscriptionFormulaire {
          edges {
            node {
              drupal_internal__revision_id
              field_rich_description {
                value
              }
              field_title
              relationships {
                field_button {
                  field_link {
                    uri
                    uri_alias
                  }
                  field_title
                  field_style
                  field_link_target
                }
              }
            }
          }
      }      
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
