/**
 * Returns a human readable string from an string array
 * @param choices: the object containing chosen values
 */
export default function formatChoices(choices: string[]): string {
  switch (true) {
    case choices.length === 0:
      return ''
    case choices.length === 1:
      return choices[0]
    default:
      return `${choices.slice(0, -1).join(', ')} et ${choices[choices.length - 1]}`
  }
}
