/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllDoubleCardsQuery, IParagraph__Double_Cards } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({ relationships }: IParagraph__Double_Cards): IProps => {
  return {
    cards: _('simpleCards', relationships?.field_simple_cards).map(card => ({
      title: _('title', card?.field_title),
      description: _('description', card?.field_plain_description),
      button: {
        link: {
          href:
            card?.relationships?.field_button?.field_link?.uri_alias ||
            card?.relationships?.field_button?.field_link?.uri
        },
        target: card?.relationships?.field_button?.field_link?.uri,
        title: _('field_title', card?.relationships?.field_button?.field_title),
        action: card?.relationships?.field_button?.field_button_action_id
      }
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllDoubleCardsQuery>(graphql`
    query AllDoubleCards {
      data: allParagraphDoubleCards {
        edges {
          node {
            id
            drupal_internal__revision_id
            relationships {
              field_simple_cards {
                field_title
                field_plain_description
                relationships {
                  field_button {
                    field_title
                    field_link_target
                    field_link {
                      uri
                      uri_alias
                    }
                    field_button_action_id
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
