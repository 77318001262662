import { ILink } from '../../types/gatsby/links'
import { IImage } from '../../types/gatsby/images'
import { IDrupalButton } from '../../types/drupal'
import Icon, { ICONS } from '../../components/presentationals/Icon'
import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import React, { useState } from 'react'
import RadioField, { RADIO_VARIANTS } from '../../components/formik/RadioField/RadioField'
import GatsbyImage from 'gatsby-image'
import toCommaFixed from '../../utils/misc/toCommaFixed'

interface IService {
  name: string
  included: boolean
  link?: ILink
}

interface ISubPack {
  name: string
  code: string
  image: IImage
  price: number
  price2: number
  libellePrice: string
  libellePrice2: string
  mentionSalarie?: boolean
  marquerPrix?: string
  services: IService[]
  button: IDrupalButton
}

export interface IOptionSouscription {
  name: string
  code: string
}

export interface IOption {
  options: IOptionSouscription[]
  price: number
  price2: number
  libellePrice: string
  libellePrice2: string
  title: string
  button: IDrupalButton
}

export interface IOffre {
  pack: ISubPack
  option?: IOption
}

export interface IProps {
  title?: string
  description?: string
  offre: IOffre
}


export default function Component({ title, description, offre }: IProps) {
  const [selectedCode, setSelectedCode] = useState<string | null>(null)
  const [hasClick, setClick] = useState<boolean>(false)
  return (
    <section className="bg-green-light-100 bg-circle bg-circle-bottom bg-circle-left bg-circle-green">
      <a className="ancre" id="choix-formule" />
      <div className="container pt-16 pb-24 md:pt-8 md:pb-24 mx-auto text-center">
        {title && (<h2 className="md:px-16 mb-5 mt-10 text-3xl font-medium leading-none text-center sm:text-3xl font-brand text-purple-dark-500">{title}</h2>)}
        {description && (<p className="mx-auto text-center text-gray-700 md:text-xl md:w-2/3 px-2 md:px-16 mb-10 leading-5">{description}</p>)}
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="bg-white w-80 px-5 py-8 rounded-lg shadow-xl z-10">
            <div className="flex flex-col items-center">
              {offre.pack.image && <GatsbyImage className="w-32" {...offre.pack.image} />}
              <div className="w-6 h-1 mx-auto my-5 bg-orange-500 rounded-lg" />
              {offre.pack.price &&
                <p className="text-3xl font-bold whitespace-no-wrap text-purple-dark-500 font-brand">
                  <span>
                    {toCommaFixed(offre.pack.price, 2)} €
                    <small className="text-sm"> {offre.pack.libellePrice}</small>
                  </span>
                </p>
              }
              {offre.pack.price2 &&
                <p className="text-sm whitespace-no-wrap text-purple-dark-500 font-brand">
                  <span>
                    {toCommaFixed(offre.pack.price2, 2)} €
                    <small className="text-sm"> {offre.pack.libellePrice2}</small>
                  </span>
                </p>
              }
            </div>
            <div className="py-8">
              {offre.pack.services.map((service, serviceIndex) => (
                <div key={serviceIndex} className="flex items-center w-full py-2">
                  <div>
                    <Icon
                      name={service.included ? ICONS.CircleCheck : ICONS.CircleCross}
                      width="24"
                      height="24"
                    />
                  </div>
                  <p className="w-full pl-3 font-sans text-sm font-normal text-left text-gray-800">
                    {service.name}
                  </p>
                </div>
              ))}
            </div>
            <Button
              className="flex items-center w-full justify-center text-sm md:text-base"
              variant={BUTTON_VARIANTS.PRIMARY}
              ariaLabel='Je souscris au pack'
              href={"/souscription?offre=" + offre.pack.code}
            >
              {offre.pack?.button?.title ?? "Je souscris au pack"}
            </Button>
          </div>
          {offre.option && (
            <div className="bg-gray-100 w-76 md:w-80 md:mt-2 rounded-b-lg md:rounded-b-none md:rounded-r-lg h-full overflow-hidden shadow-xl md:mb-auto">
              <p className="bg-green-500 text-white font-bold font-brand text-left px-3 py-1 md:text-xl">{offre.option.title}</p>
              <div className="pt-6">
                {offre.option.options.map((option: IOptionSouscription) => (
                  <RadioField
                    key={option.code}
                    className="mb-6 text-left px-5"
                    text={option.name}
                    value={option.code}
                    onClick={(value) => {
                      setClick(false);
                      value === selectedCode ? setSelectedCode(null) : setSelectedCode(value)
                    }}
                    checked={option.code === selectedCode}
                    variant={RADIO_VARIANTS.SIMPLE}
                  />
                ))}
              </div>
              <div className="mx-5 mb-8">
                {offre.option.price &&
                  <p className="text-3xl font-bold whitespace-no-wrap text-purple-dark-500 font-brand">
                    <span>
                      {toCommaFixed(offre.option.price, 2)} €
                      <small className="text-sm"> {offre.option.libellePrice}</small>
                    </span>
                  </p>
                }
                {offre.option.price2 &&
                  <p className="text-sm whitespace-no-wrap text-purple-dark-500 font-brand">
                    <span>
                      {toCommaFixed(offre.option.price2, 2)} €
                      <small className="text-sm"> {offre.option.libellePrice2}</small>
                    </span>
                  </p>
                }
                {!selectedCode && hasClick && <p className="text-sm text-orange-500 mt-5 mb-3">Vous devez sélectionner une option</p>}
                <Button
                  className={'flex items-center w-full px-0 justify-center text-xs md:text-base' + (selectedCode && hasClick ? " mt-0" : " mt-5")}
                  variant={BUTTON_VARIANTS.SECONDARY}
                  ariaLabel="Je souscris au pack + option"
                  {...
                  !selectedCode ?
                    { onClick: () => { setClick(true) } } : { href: "/souscription?offre=" + selectedCode }
                  }
                >
                  {offre.option?.button?.title ?? "Je souscris au pack + option"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

