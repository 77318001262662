export default (email: string, subject?: string, body?: string) => {
  const base = `mailto:${email}`

  return [
    { name: 'subject', value: subject },
    { name: 'body', value: body }
  ].reduce((acc, curr, index) => {
    if (!curr.value) {
      return acc
    }
    acc += index === 0 ? '?' : '&'
    acc += `${curr.name}=${encodeURI(curr.value)}`

    return acc
  }, base)
}
