import { useMachine } from '@xstate/react'
import React, { useEffect, useState } from 'react'
import ContactComp from '../../components/ContactForm/ContactComp'
import useApiEndpoints from '../../hooks/useApiEndpoints'
import machine from '../../machines/contactForm'
import { IImage } from '../../types/gatsby/images'
import Img from "gatsby-image/withIEPolyfill"
import styles from './Component.module.css'
import validationSchema from '../../components/ContactForm/ContactForm.validationSchema'
import particulierValidationSchema from '../../components/ContactForm/ContactFormParticulier.validationSchema'
import Icon, { ICONS } from '../../components/presentationals/Icon'


export interface IProps {
  /** An image */
  image: IImage,
  field_description_particulier_dr: string,
  field_title: string,
  field_legend: string,
  field_rich_description: string,
  field_informations_complementair: string,
  field_titre_droite_entreprise: string,
  field_titre_gauche_entreprise: string,
  field_titre_gauche_part: string,
  field_plain_description: string,
  field_description_gauche_entrepr: string,
  field_description_gauche_particu: string,
  popin_succes: any,
  popin_echec: any,
  field_affichage_particulier: boolean
}

export default function Contact(
  { image, field_description_particulier_dr,
    field_title,
    field_legend,
    field_rich_description,
    popin_succes,
    popin_echec,
    field_informations_complementair,
    field_titre_droite_entreprise,
    field_titre_gauche_entreprise,
    field_titre_gauche_part,
    field_plain_description,
    field_description_gauche_entrepr,
    field_description_gauche_particu,
    field_affichage_particulier,
  }: IProps) {

  const [state, send] = useMachine(machine, {
    context: {
      apiEndpoints: useApiEndpoints()
    }
  })

  const [profil, setProfil] = useState(0);

  useEffect(() => {
    if (field_affichage_particulier && window.location.hash == '#particulier') {
      setProfil(1);
    }
  }, [])

  return (
    <>
      <div className="sm:flex">
        <div className="w-full align-middle">
          <Img
            objectFit="contain"
            imgStyle={{}}
            alt={image.alt}
            fluid={image.fluid}
            className="w-full align-middle sm:mb-4" />
          <div className="hidden sm:block pr-10">
            <div className="lg:float-right lg:w-4/6 ">
              <p className="p-4 lg:p-0 mb-6 text-3xl sm:text-lg lg:text-3xl font-bold md:text-4xl font-brand text-purple-dark-500 mt-10">{profil === 0 ? field_titre_gauche_entreprise : field_titre_gauche_part}</p>
              <div className="rich-contribution contact px-4 pb-4 lg:px-0 sm:pb-0" dangerouslySetInnerHTML={{ __html: profil === 0 ? field_description_gauche_entrepr : field_description_gauche_particu }} />
            </div>
          </div>
        </div>
        <div className={`${styles.bgColorOrange} px-4 sm:px-10 py-8 sm:py-16 w-full`}>
          <h3 className="mb-4 text-3xl sm:text-lg lg:text-3xl font-bold md:text-4xl font-brand text-purple-dark-500">
            {field_titre_droite_entreprise}
          </h3>
          <p className="mb-8 lg:w-4/6">{profil == 0 ? field_plain_description : field_description_particulier_dr}</p>
          {field_affichage_particulier &&
            <>
              <span className="font-bold font-brand text-purple-dark-500">Profil</span>
              <div className="lg:w-4/6 xl:w-6/12 mt-2 mb-5 flex">
                <button onClick={() => { setProfil(0) }} className={`${styles.buttonProfil} w-full mr-5 ${profil === 0 ? styles.active : ''}`}>
                  <Icon
                    name={profil === 0 ? ICONS.EntrepriseWhite : ICONS.Entreprise}
                    width="34"
                    height="34"
                    className='mr-1 fill-current text-orange-500 float-left ml-4 mr-0'
                  />
                  <span className={styles.verticalAlign}>Entreprise</span>
                </button>
                <button onClick={() => { setProfil(1) }} className={`${styles.buttonProfil} w-full ${profil === 1 ? styles.active : ''}`}>
                  <Icon
                    name={profil === 1 ? ICONS.ParticulierWhite : ICONS.Particulier}
                    width="34"
                    height="34"
                    className='mr-1 fill-current text-orange-500 float-left ml-4 mr-0'
                  />
                  <span className={styles.verticalAlign}>Particulier</span>
                </button>
              </div>
            </>}

          {(state.matches('contact') && profil === 0) && <ContactComp validator={validationSchema} state={state} consent={{ legend: field_legend }} profil={profil} send={send} popinSucces={popin_succes} popinEchec={popin_echec} />}
          {(state.matches('contact') && profil === 1) && <ContactComp validator={particulierValidationSchema} state={state} consent={{ legend: field_legend, mention: field_rich_description }} profil={profil} send={send} popinSucces={popin_succes} popinEchec={popin_echec} />}
          {profil == 0 && <div className="lg:w-4/6 text-xs" dangerouslySetInnerHTML={{ __html: field_informations_complementair }} />}
        </div>
        <div className="block sm:hidden">
          <p className="lg:float-right lg:w-4/6 p-4 lg:p-0 mb-6 text-3xl sm:text-lg lg:text-3xl font-bold md:text-4xl font-brand text-purple-dark-500 mt-6">{profil === 0 ? field_titre_gauche_entreprise : field_titre_gauche_part}</p>
          <div className="lg:float-right rich-contribution contact lg:w-4/6 px-4 pb-4 lg:px-0 sm:pb-0" dangerouslySetInnerHTML={{ __html: profil === 0 ? field_description_gauche_entrepr : field_description_gauche_particu }} />
        </div>

      </div>
    </>
  )
}

