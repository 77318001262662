import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import cs from 'classnames'

import validationSchema, { VALUES_MAPPING } from './Question2Form.validationSchema'
import Icon, { ICONS } from '../../../../../components/presentationals/Icon/'

import CheckboxField from '../../../../../components/formik/CheckboxField/CheckboxField'
import Question from '../../../../../components/presentationals/AutoDiagnostic/Question/Question'
import Button, { BUTTON_VARIANTS } from '../../../../../components/presentationals/Button/Button'
import formatChoices from '../../../../../utils/misc/formatChoices'

interface IProps {
  onEdit: () => void
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  showForm: boolean
  isRevision: boolean
  disableEditionButton: boolean
  context?: any
}

const Question2Form = ({
  onSubmit,
  onEdit,
  dataTestId,
  initialValues,
  showForm,
  isRevision,
  disableEditionButton,
  context
}: IProps) => {
  const formattedOnSubmit = useCallback(
    values => {
      onSubmit({
        data: Object.entries(values)
          .filter(choice => choice[1])
          .map(choice => VALUES_MAPPING[choice[0]])
      })
    },
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formattedOnSubmit}
      validateOnMount={false}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid, resetForm } = props

        const {
          enjeux: { mainIssues }
        } = context

        const checkedCount = Object.values(values).filter(Boolean).length

        return (
          <div data-testid={dataTestId}>
            <Question
              className={cs({ 'shadow-lg': showForm })}
              onClick={showForm && !disableEditionButton ? undefined : onEdit}
              editBtnDataTestId="question-2-edit-button"
              text={
                showForm ? (
                  <>
                    <b>
                      2/4. Quels sont vos principaux enjeux en matière de ressources humaines ?{' '}
                    </b>
                    2 réponses possibles
                  </>
                ) : (mainIssues || []).includes(VALUES_MAPPING['aucune']) ? (
                  <>
                    2/4. Je n'ai <b>aucun</b> enjeu en matière de ressource humaines.
                  </>
                ) : (
                  <>
                    2/4. Mes enjeux principaux sont&nbsp;: <b>{formatChoices(mainIssues || [])}</b>
                  </>
                )
              }
            >
              {showForm && (
                <Form className="pt-8">
                  <fieldset>
                    <div className="flex flex-wrap justify-between">
                      <legend className="sr-only">
                        2/4. Quels sont vos principaux enjeux en matière de ressources humaines ?
                        Deux réponses possibles.
                      </legend>
                      <CheckboxField
                        className="w-1/2 pb-2 pr-2 md:w-1/3"
                        checked={values['engagement-salarie']}
                        disabled={
                          (!values['engagement-salarie'] && checkedCount >= 2) ||
                          disableEditionButton
                        }
                        onClick={() => {
                          setFieldValue('aucune', false)
                          setFieldValue('engagement-salarie', !values['engagement-salarie'])
                        }}
                        icon={<Icon name={ICONS.Handshake} width="48" height="48" />}
                        text={VALUES_MAPPING['engagement-salarie']}
                      />
                      <CheckboxField
                        className="w-1/2 pb-2 pl-2 md:w-1/3 md:px-2"
                        checked={values['fideliser-talents']}
                        disabled={
                          (!values['fideliser-talents'] && checkedCount >= 2) ||
                          disableEditionButton
                        }
                        onClick={() => {
                          setFieldValue('aucune', false)
                          setFieldValue('fideliser-talents', !values['fideliser-talents'])
                        }}
                        icon={<Icon name={ICONS.HeadLightBulb} width="48" height="48" />}
                        text={VALUES_MAPPING['fideliser-talents']}
                      />
                      <CheckboxField
                        className="w-1/2 py-2 pr-2 md:w-1/3 md:pl-2 md:pr-0 md:pt-0"
                        checked={values['concilier-vie-privee-pro']}
                        disabled={
                          (!values['concilier-vie-privee-pro'] && checkedCount >= 2) ||
                          disableEditionButton
                        }
                        onClick={() => {
                          setFieldValue('aucune', false)
                          setFieldValue(
                            'concilier-vie-privee-pro',
                            !values['concilier-vie-privee-pro']
                          )
                        }}
                        icon={<Icon name={ICONS.Scale} width="48" height="48" />}
                        text={VALUES_MAPPING['concilier-vie-privee-pro']}
                      />
                      <CheckboxField
                        className="w-1/2 py-2 pl-2 md:w-1/3 md:pb-0 md:pl-0 md:pr-2"
                        checked={values['ameliorer-marque']}
                        disabled={
                          (!values['ameliorer-marque'] && checkedCount >= 2) || disableEditionButton
                        }
                        onClick={() => {
                          setFieldValue('aucune', false)
                          setFieldValue('ameliorer-marque', !values['ameliorer-marque'])
                        }}
                        icon={<Icon name={ICONS.BarChart} width="48" height="48" />}
                        text={VALUES_MAPPING['ameliorer-marque']}
                      />
                      <CheckboxField
                        className="w-1/2 pt-2 pr-2 md:w-1/3 md:px-1"
                        checked={values['diminuer-absenteisme']}
                        disabled={
                          (!values['diminuer-absenteisme'] && checkedCount >= 2) ||
                          disableEditionButton
                        }
                        onClick={() => {
                          setFieldValue('aucune', false)
                          setFieldValue('diminuer-absenteisme', !values['diminuer-absenteisme'])
                        }}
                        icon={<Icon name={ICONS.Absenteeism} width="48" height="48" />}
                        text={VALUES_MAPPING['diminuer-absenteisme']}
                      />
                      <CheckboxField
                        className="w-1/2 pt-2 pl-2 md:w-1/3"
                        checked={values['aucune']}
                        disabled={disableEditionButton}
                        onClick={() => {
                          if (!values['aucune']) resetForm()
                          setFieldValue('aucune', !values['aucune'])
                        }}
                        icon={<Icon name={ICONS.None} width="48" height="48" />}
                        text={VALUES_MAPPING['aucune']}
                      />
                    </div>
                  </fieldset>

                  {isRevision && (
                    <div className="pt-8 text-center">
                      <Button
                        className="flex justify-center w-full md:w-2/5"
                        variant={BUTTON_VARIANTS.PRIMARY}
                        onClick={handleSubmit}
                        disabled={!isValid}
                        ariaLabel="Enregistrer la modification"
                        analyticsDataLayer={{
                          buttonLabel: "Enregistrer la modification"
                        }}
                      >
                        Enregistrer la modification
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Question>

            {showForm && !isRevision && (
              <div className="mt-10 text-center">
                <Button
                  tabIndex={0}
                  className="flex justify-center w-full md:w-2/5"
                  dataTestId="trigger-next-button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  onClick={handleSubmit}
                  disabled={!isValid || disableEditionButton}
                  ariaLabel="Suivant"
                  analyticsDataLayer={{
                    buttonLabel: "Suivant"
                  }}
                >
                  Suivant
                </Button>
              </div>
            )}
          </div>
        )
      }}
    </Formik>
  )
}

export default Question2Form
