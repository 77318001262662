import React from 'react'
import { GetHandleProps } from 'react-compound-slider'
import cs from 'classnames'

interface IProps {
  handle: { id: any; percent: number }
  getHandleProps: GetHandleProps
  handleDirection?: 'bottom' | 'top'
  showPercents?: boolean
}

const SliderHandle = ({
  showPercents = true,
  handle: { id, percent },
  getHandleProps,
  handleDirection
}: IProps) => (
  <button
    type="button"
    tabIndex={0}
    style={{ left: `${percent}%` }}
    className={cs(
      'absolute z-20 flex items-center justify-center w-10 h-10 bg-white border border-orange-500 rounded-full shadow-lg cursor-pointer slider__handle',
      handleDirection === 'bottom' ? 'slider__handle--bottom' : 'slider__handle--top'
    )}
    {...getHandleProps(id)}
  >
    <div className="w-5 h-5 bg-orange-500 rounded-full"></div>
    <div className="absolute w-2 h-2 bg-white border-b border-r border-orange-500 slider__handle__needle" />

    {showPercents && (
      <span className="absolute text-2xl font-bold text-orange-500 w-max-co font-brand slider__handle__text">
        {Math.round(percent)} %
      </span>
    )}
  </button>
)

export default SliderHandle
