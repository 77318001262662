/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllColumnedDescListQuery, IParagraph__Columned_Desc_List } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_subtitle,
  field_image,
  relationships
}: IParagraph__Columned_Desc_List): IProps => {
  return {
    title: field_title,
    annotation: field_subtitle,
    image: field_image
      ? {
          alt: field_image.alt || '',
          ...relationships?.field_image?.localFile?.childImageSharp
        }
      : undefined,
    button: relationships?.field_button && {
      link: {
        href:
          relationships?.field_button?.field_link?.uri_alias ||
          relationships?.field_button?.field_link?.uri
      },
      title: _('title', relationships?.field_button?.field_title),
      target: relationships?.field_button?.field_link_target,
      action: relationships?.field_button?.field_button_action_id
    },
    list: _('list', relationships?.field_titled_descriptions).map(richDesc => ({
      // TODO Use real contributed title
      title: richDesc?.field_title,
      description: _('description', richDesc?.field_rich_description?.value)
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllColumnedDescListQuery>(graphql`
    query AllColumnedDescList {
      data: allParagraphColumnedDescList {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            field_subtitle
            field_image {
              alt
            }
            relationships {
              field_titled_descriptions {
                field_title
                field_rich_description {
                  value
                }
              }
              field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 992) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              field_button {
                field_title
                field_link_target
                field_button_action_id
                field_link {
                  uri
                  uri_alias
                }
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
