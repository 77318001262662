import React from 'react'
import { GetTrackProps } from 'react-compound-slider'

interface IProps {
  source: { percent: number }
  target: { percent: number }
  getTrackProps: GetTrackProps
}

const SliderTrack = ({ source, target, getTrackProps }: IProps) => (
  <div
    style={{ left: `${source.percent}%`, width: `${target.percent - source.percent}%` }}
    className="absolute z-10 h-1 bg-orange-500 rounded cursor-pointer slider__track"
    {...getTrackProps()}
  ></div>
)

export default SliderTrack
