import { object, mixed, string } from 'yup'

export const DEPARTMENT_REGEX = /^([0-9]{2,3})$/
export const ZIPCODE_REGEX = /^((?:0[1-9])|(?:[1-8][0-9])|(?:9[0-5]))([0-9]{3})$/

export const isZipcode = (value: string) => ZIPCODE_REGEX.test(value)
export const isDepartment = (value: string) => DEPARTMENT_REGEX.test(value)

/**
 * isSiren
 * Use Luhn algorithm to validate SIREN number
 * @param value
 */
export const isSiren = (value: string): boolean => {
  if (isNaN(parseInt(value)) || value.length < 9) {
    return false
  }

  let sum = 0
  let tmp
  for (let cpt = 0; cpt < value.length; cpt++) {
    if (cpt % 2 === 1) {
      tmp = parseInt(value.charAt(cpt)) * 2
      if (tmp > 9) {
        tmp -= 9
      }
    } else {
      tmp = parseInt(value.charAt(cpt))
    }
    sum += tmp
  }

  // If sum is a divisible by 10 then SIREN is valid
  return sum % 10 === 0
}

export default object().shape(
  {
    companyName: mixed().when('siren', {
      is: siren => !siren || !siren.length,
      then: string().required('La raison sociale est obligatoire'),
      otherwise: string()
    }),
    zipCode: mixed().when('siren', {
      is: siren => !siren || !siren.length,
      then: string()
        .required('Le code postal est obligatoire')
        .test({
          name: 'zipcodeOrDepartment',
          message: 'Veuillez entrer un code postal ou un département valide',
          // TODO Uncomment when SIREN API supports searches by department number
          test: value => !value || isZipcode(value) // || isDepartment(value)
        }),
      otherwise: string()
    }),
    siren: string()
      .test({
        name: 'siren',
        message: 'Le numéro SIREN est invalide',
        test: value => !value || isSiren(value)
      })
      .when(['companyName', 'zipCode'], {
        is: (companyName, zipCode) =>
          !(companyName && companyName.length) && !(zipCode && zipCode.length),
        then: string()
          .required('Le numéro SIREN est obligatoire')
          .test({
            name: 'siren',
            message: 'Le numéro SIREN est invalide',
            test: value => !value || isSiren(value)
          }),
        otherwise: string()
      })
  },
  [
    ['siren', 'companyName'],
    ['siren', 'zipCode']
  ]
)
