import React from 'react'

export interface IProps {
  title: string
}

export default function Component({ title }: IProps) {
  return (
    <div className="flex items-center justify-center my-4">
      <div className="w-8 border-t border-gray-300" />
      <span className="mx-3 font-bold uppercase font-brand text-purple-dark-500">{title}</span>
      <div className="w-8 border-t border-gray-300" />
    </div>
  )
}
