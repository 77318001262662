import GatsbyImage from 'gatsby-image'
import React from 'react'

import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import { IDrupalButton } from '../../types/drupal'
import { IImage } from '../../types/gatsby/images'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

export interface IProps {
  /** The title that will be displayed using an H2 */
  title: string
  /** An description that can contain HTML */
  description: string
  /** An image */
  image: IImage
  /**  */
  button: IDrupalButton
}

export default function Component({ title, description, image, button }: IProps) {
  return (
    <section className="bg-green-dark-100 bg-circle bg-circle-top bg-circle-left bg-circle-green-dark">
      <div className="container flex flex-col items-center justify-between pt-8 pb-8 mx-auto lg:justify-center md:flex-row">
        <div className="px-6 text-center md:pl-0 md:w-1/2 md:text-left">
          <h2 className="mt-8 mb-6 text-3xl font-medium leading-none md:mb-10 font-brand text-purple-dark-500 md:text-4xl md:text-left">
            {title}
          </h2>
          <div className="mx-auto mb-6 md:hidden">
            <GatsbyImage {...image} />
          </div>
          <div
            className="mb-10 font-sans leading-normal text-gray-700 md:w-2/3 rich-contribution"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Button
            className="mb-6"
            {...buttonPropsFormatter(button)}
          />
        </div>
        <div className="hidden w-1/3 md:block">
          <GatsbyImage {...image} />
        </div>
      </div>
    </section>
  )
}
