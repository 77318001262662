import React, { useCallback } from 'react'
import { ACTIONS } from '../../machines/contactForm/contact'
import ContactForm from './ContactForm'


export interface IProps {
  validator: any
  state: any
  consent: any
  profil: number
  send: (action: any, payload?: any) => void,
  popinSucces: any,
  popinEchec: any
}

export default function ContactComp({ validator, state, consent, profil, send, popinSucces, popinEchec }: IProps) {

  const handlePostContactForm = useCallback(event => {
    send(ACTIONS.POST_CONTACT, event)
  }, [
    send
  ])

  return (
    <div className="lg:w-4/6">
      {<ContactForm onSubmit={handlePostContactForm}
        validator={validator}
        consent={consent}
        profil={profil}
        context={state.context}
        isFetching={state.matches('contact.POSTFormulaireContact')}
        initialValues={state.context.contact}
        popinSucces={popinSucces} popinEchec={popinEchec} />}
    </div>
  )
}
