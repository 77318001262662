import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'

import validationSchema from './Question2Form.validationSchema'
import Button, { BUTTON_VARIANTS } from '../../../../../components/presentationals/Button/Button'
import RadioField from '../../../../../components/formik/RadioField/RadioField'
import { ICompany } from '../../../constants'

const getFormattedRadioButtonText = ({ uniteLegale, adresseEtablissement }: ICompany) =>
  `${uniteLegale.denominationUniteLegale ??
    uniteLegale.nomUniteLegale + ' ' + uniteLegale.prenom1UniteLegale}<br/>
   ${adresseEtablissement.numeroVoieEtablissement || ''} 
   ${adresseEtablissement.typeVoieEtablissement || ''}
   ${adresseEtablissement.libelleVoieEtablissement || ''}<br/>
   ${adresseEtablissement.codePostalEtablissement || ''} 
   ${adresseEtablissement.libelleCommuneEtablissement || ''}`.toLowerCase()

interface IProps {
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  context?: any
}

const Question2Form = ({ onSubmit, dataTestId, initialValues, context }: IProps) => {
  const {
    entreprise: { companyList }
  } = context

  const formattedOnSubmit = useCallback(
    values =>
      onSubmit({
        data: [companyList.find((company: ICompany) => company.siret === values.companyId)]
      }),
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid } = props

        const handleClick = useCallback(value => setFieldValue('companyId', value), [setFieldValue])
        const handlePressEnter = useCallback(() => isValid && handleSubmit(values), [handleSubmit])

        return (
          <Form>
            <fieldset>
              <div data-testid={dataTestId}>
                <p className="mb-4 font-bold text-purple-dark-500">
                  Veuillez sélectionner votre entreprise dans la liste suivante&nbsp;:
                </p>

                {companyList.map((company: ICompany) => (
                  <RadioField
                    key={company.siret}
                    className="mb-3"
                    textClassName="capitalize"
                    text={getFormattedRadioButtonText(company)}
                    value={company.siret}
                    onClick={handleClick}
                    onPressEnter={handlePressEnter}
                    checked={values['companyId'] === company.siret}
                  />
                ))}

                <div className="mt-10 text-center">
                  <Button
                    className="relative flex justify-center w-full md:w-2/5"
                    dataTestId="trigger-next-button"
                    iconClassname="absolute right-0 top-0 mr-4 mt-1 transform scale-75"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={handleSubmit}
                    disabled={!isValid}
                    ariaLabel="Je valide"
                    analyticsDataLayer={{
                      buttonLabel: "Je valide"
                    }}
                  >
                    Je valide
                  </Button>
                </div>
              </div>
            </fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Question2Form
