export type IColor =
  | 'orange'
  | 'pink'
  | 'green-dark'
  | 'blue'
  | 'purple'
  | 'green'
  | 'red'
  | 'red-dark'
  | 'gray'
  | 'white'
  | 'purple-dark'

export const COLORS: IMap<IColor> = {
  ORANGE: 'orange',
  PINK: 'pink',
  GREEN_DARK: 'green-dark',
  BLUE: 'blue',
  PURPLE: 'purple',
  PURPLE_DARK: 'purple-dark',
  GREEN: 'green',
  RED: 'red',
  RED_DARK: 'red-dark',
  GRAY: 'gray',
  WHITE: 'white'
}
