import React from "react";
import Modal from 'react-modal';
import { sendPopinClickAnalytics, sendPopinDisplayAnalytics } from "../../utils/analytics/analytics";
import Button, { BUTTON_VARIANTS } from "../presentationals/Button/Button";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    wordBreak: 'break-word',
    textAlign: 'center'
  }
};

export interface IProps {
  titleColor?: string,
  title?: string
  content?: string
  contentColor?: string
  buttonTitle?: string
  link?: string,
  onAfterClose?: () => {}
}

const ModalAction = ({
  titleColor,
  title,
  content,
  contentColor,
  buttonTitle,
  link,
  onAfterClose,
}: IProps) => {

  var subtitle: any;
  var contentModal: any;


  const [modalIsOpen, setIsOpen] = React.useState(true);

  function afterOpenModal() {
    if (titleColor) {
      subtitle.style.color = titleColor;
    }
    if (contentColor) {
      contentModal.style.color = contentColor;
    }
    sendPopinDisplayAnalytics({
      popinName: "Validation Formulaire"
    })
  }

  function closeModal(libelle: string | undefined = undefined) {
    sendPopinClickAnalytics({
      popinName: "Validation Formulaire",
      buttonLabel: libelle
    })
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        onAfterClose={onAfterClose}
        style={customStyles}
        contentLabel="Modal"
      >
        <h2 className="font-brand font-medium" ref={_subtitle => (subtitle = _subtitle)}>{title}</h2>
        <br></br>
        <div ref={_contentModal => (contentModal = _contentModal)} dangerouslySetInnerHTML={{ __html: content }}></div>
        <br></br>
        <a href={link}>
          <Button
            className="relative flex justify-center mt-5"
            dataTestId="trigger-next-button"
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={() => {
              closeModal(buttonTitle)
            }}
            analyticsDataLayer={{
              buttonLabel: buttonTitle
            }}
            iconClassname="absolute right-0 top-0 mr-4 mt-1 transform scale-75"
            ariaLabel="Je valide"
          >
            {buttonTitle}
          </Button>
        </a>
      </Modal>
    </div>
  );
}

export default ModalAction
