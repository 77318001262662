/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby'

import { IParagraph } from '..'
import Component, { IProps } from './Component'

const propsFormatter = (): IProps => {
  return {
    subject: 'Découvrir Flex',
    body:
      'J’ai découvert les services offerts par Flex ! Venez les consulter ici : https://www.flexservice.fr/.'
  }
}

const Query = () =>
  useStaticQuery(graphql`
    query AllMailSharing {
      data: allParagraphMailSharing {
        edges {
          node {
            id
            drupal_internal__revision_id
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
