import React, { ChangeEvent, KeyboardEvent, FormEvent, useState } from 'react'
import cs from 'classnames'
import { kebabCase } from 'lodash'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import './DateField.css'
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  id?: string
  className?: string
  dataTestId?: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (e: FormEvent) => void
  name?: string
  label?: string
  error?: string
  placeholder?: string
  pattern?: string
  maxLength?: number
  disabled?: boolean
  trimWhitespace?: boolean
  tabIndex?: number
  required?: boolean
}

const DateField = ({
  className,
  dataTestId,
  value,
  onChange,
  onPressEnter,
  onBlur,
  name,
  label,
  error,
  placeholder,
  pattern,
  maxLength,
  id = `input-${kebabCase(label)}`,
  tabIndex = 0,
  disabled = false,
  trimWhitespace = false,
  required = false
}: IProps) => {
  const [startDate, setStartDate] = useState();
  const range = (start, end) => {
    return new Array(start - end).fill().map((d, i) => start - i);
  };
  const years = range(getYear(new Date()), 1930);
  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre"
  ];
  return (
    <div className={className} data-testid={dataTestId}>
      <label htmlFor={id} className="font-bold font-brand text-purple-dark-500">
        {label}
      </label>
      <ReactDatePicker
        onChange={(date: Date) => { onChange(date); setStartDate(date) }}
        id={id}
        name={name}
        className={cs('w-full px-4 py-3 mt-2 border rounded-lg focus:outline-none input-date', {
          'border-red-600': error
        })}
        locale='fr'
        onBlur={onBlur}
        onKeyDown={e => e.key === 'Enter' && typeof onPressEnter !== 'undefined' && onPressEnter(e)}
        tabIndex={disabled ? -1 : tabIndex}
        selected={startDate}
        onSelect={(date) => setStartDate(date)}
        required={required}
        dateFormat="dd/MM/yyyy"
        aria-required={required}
        aria-invalid={!!error}
        aria-describedby={error}
        disabled={disabled}
        placeholderText={'jj/mm/aaaa'}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              justifyContent: "center"
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map(option => (
                <option key={option} value={option} selected={option === months[getMonth(date) - 1]}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
            <br />
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option} selected={option === getYear(date)}>
                  {option}
                </option>
              ))}
            </select>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
              {">"}
            </button>
          </div>
        )
        }

      />
      {error && <p className="mt-2 italic text-red-600">{error}</p>}
    </div >
  )
}

export default DateField
