/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllQuotesSliderQuery, IParagraph__Quotes_Slider } from '../../graphql.types'
import { IColor } from '../../types/gatsby/colors'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_flex_color,
  relationships
}: IParagraph__Quotes_Slider): IProps => {
  return {
    title: _('title', field_title),
    backgroundColor: _('color', field_flex_color as IColor),
    list: _('list', relationships?.field_quotes_slider_items).map(item => ({
      author: _('author', item?.field_baseline),
      quote: _('quote', item?.field_plain_description),
      avatar: {
        alt: _('alt', item?.field_image?.alt),
        ..._('image', item?.relationships?.field_image?.localFile?.childImageSharp)
      },
      button: {
        link: {
          href:
            item?.relationships?.field_button?.field_link?.uri_alias ||
            item?.relationships?.field_button?.field_link?.uri
        },
        title: item?.relationships?.field_button?.field_title,
        target: item?.relationships?.field_button?.field_link_target,
        action: item?.relationships?.field_button?.field_button_action_id,
      }
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllQuotesSliderQuery>(graphql`
    query AllQuotesSlider {
      data: allParagraphQuotesSlider {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_flex_color
            field_title
            relationships {
              field_quotes_slider_items {
                field_baseline
                field_plain_description
                field_image {
                  alt
                }
                relationships {
                  field_button {
                    field_link {
                      uri
                      uri_alias
                    }
                    field_title
                    field_button_action_id
                  }
                  field_image {
                    localFile {
                      childImageSharp {
                        fixed(height: 140, width: 140) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
