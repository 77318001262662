import React, { useEffect, useLayoutEffect } from 'react'

import Breadcrumbs from '../components/presentationals/Breadcrumbs/Breadcrumbs'
import Footer from '../components/withStaticQuery/Footer/Footer'
import Header from '../components/withStaticQuery/Header/Header'
import Seo from '../components/withStaticQuery/Seo/Seo'
import useHeaderData from '../hooks/useHeaderData'
import { getComponent, IContent } from '../paragraphs/utils'
import { sendScrollAnalytics, sendVirtualPageViewAnalytics } from '../utils/analytics/analytics'

export interface Props {
  pageContext: {
    title: string
    contents: IContent[]
    metatag?: {
      tag: string
      attributes: {
        content: string
        href: string
        name: string
        rel: string
      }
    }[]
    breadcrumb: {
      title: string
      uri: string | undefined
    }[]
  }
}

export default function OfferTemplate({ pageContext }: Props) {
  const page = {
    breadcrumb: pageContext.breadcrumb,
    metatag: pageContext.metatag
  }

  useEffect(() => {
    if (typeof (window as IFlexWindow).__flexMounted !== 'undefined') {
      // sending event asynchronously to await for sessionStorage sync
      setTimeout(() =>
        sendVirtualPageViewAnalytics({ virtualPagename: document.location.pathname })
      )
    } else {
      ; (window as IFlexWindow).__flexMounted = true
    }
  }, [])

  const [_scrollPercentage, _setScrollPercentage] = React.useState<number>(0)

  const scrollPercentage = React.useRef(_scrollPercentage);
  const setScrollPercentage = (data: number) => {
    scrollPercentage.current = data;
    _setScrollPercentage(data);
  };

  const pageName = pageContext.breadcrumb[pageContext.breadcrumb.length - 1].title

  const gtmId = useHeaderData().allHeader.nodes[0].gtmId

  useLayoutEffect(() => {
    const handleScroll = () => {
      let lastValue = scrollPercentage.current
      let scroll = ((document.documentElement.scrollTop || document.body.scrollTop) / ((document.documentElement.scrollHeight || document.body.scrollHeight) - document.documentElement.clientHeight) * 100)
      if (scroll > lastValue) {
        if (scroll < 30) {
          setScrollPercentage(25)
        } else if (scroll > 30 && scroll < 60) {
          setScrollPercentage(50)
        } else if (scroll > 60 && scroll < 90) {
          setScrollPercentage(75)
        } else if (scroll > 90) {
          setScrollPercentage(100)
        }
        if (lastValue != scrollPercentage.current) {
          sendScrollAnalytics({
            pageName: pageName,
            scrollPercentage: scrollPercentage.current.toString()
          })
        }
      }
    }

    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <Seo metatag={page.metatag} gtmId={gtmId} />
      <Header />
      <Breadcrumbs items={page.breadcrumb} />
      <main>{pageContext.contents.map(getComponent)}</main>
      <Footer />
      <script>window.dataLayer = window.dataLayer || [];</script>
    </>
  )
}
