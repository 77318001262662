import React, { useCallback } from 'react'
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'
import cs from 'classnames'

import './SliderField.css'

import SliderHandle from './SliderHandle'
import SliderTick from './SliderTick'
import SliderTrack from './SliderTrack'

interface IProps {
  min?: number
  max?: number
  step?: number
  acceptedValues?: number[]
  handleDirection?: 'bottom' | 'top'
  onChange: (newValue: number) => void
  name: string
  value: number
  disabled?: boolean
}

const SliderField = ({
  min = 0,
  max = 100,
  handleDirection = 'bottom',
  onChange,
  name,
  value,
  step,
  acceptedValues,
  disabled = false
}: IProps) => {
  const handleA11yFieldsChange = useCallback(({ target }) => onChange(Number(target.value)), [
    onChange
  ])

  return (
    <>
      <Slider
        className={cs('relative w-full', { 'opacity-50 pointer-events-none': disabled })}
        step={step ?? 1}
        domain={[min, max]}
        values={[value]}
        onUpdate={values => {
          if (step) {
            // for the cases where the step is a float number
            const val = Math.ceil(values[0]) === max ? Math.ceil(values[0]) : values[0]
            onChange(val)
          } else {
            onChange(values[0])
          }
        }}
        rootProps={{ 'aria-hidden': true }}
      >
        <Rail>
          {({ getRailProps }) => (
            <div
              className="absolute z-10 flex items-center w-full h-4 cursor-pointer slider__rail__wrapper"
              {...getRailProps()}
            >
              <div className="w-full bg-gray-300 slider__rail" />
            </div>
          )}
        </Rail>

        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {handles.map(handle => (
                <SliderHandle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  handleDirection={handleDirection}
                  showPercents={!step}
                />
              ))}
            </>
          )}
        </Handles>

        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <>
              {tracks.map(({ id, source, target }) => (
                <SliderTrack
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </>
          )}
        </Tracks>

        <Ticks values={acceptedValues ? acceptedValues : [min, max]}>
          {({ ticks }) => (
            <>
              {ticks.map(tick => (
                <SliderTick key={tick.id} tick={tick} actualValue={value} />
              ))}
            </>
          )}
        </Ticks>
      </Slider>

      <div className="sr-only">
        <label htmlFor={name}>{name}</label>
        {step && acceptedValues ? (
          <select
            value={value}
            name={name}
            id={name}
            onChange={handleA11yFieldsChange}
            disabled={disabled}
          >
            {acceptedValues.map(val => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </select>
        ) : (
          <input
            value={value}
            id={name}
            type="number"
            min={min}
            onChange={handleA11yFieldsChange}
            disabled={disabled}
          />
        )}
      </div>
    </>
  )
}

export default SliderField
