import React, { useCallback, useEffect, useMemo } from 'react'

import EditableQuestionSummary from '../../../../components/presentationals/AutoDiagnostic/EditableQuestionSummary/EditableQuestionSummary'
import Steps from '../../../../components/presentationals/AutoDiagnostic/Steps/Steps'
import useScrollTopOnLoad from '../../../../hooks/useScrollTopOnLoad'
import { useSessionStorage } from '../../../../hooks/useSessionStorage'
import {
  IAnalyticsDataLayer,
  sendVirtualPageViewAnalytics
} from '../../../../utils/analytics/analytics'
import deepFindKey from '../../../../utils/misc/deepFindKey'
import { SESSION_STORAGE_KEY, STEPS_META } from '../../constants'
import { ACTIONS } from '../../steps/entreprise'
import ScrollAnchor from '../ScrollAnchor/ScrollAnchor'
import Question1Form from './Question1Form/Question1Form'
import Question2Form from './Question2Form/Question2Form'
import Question3Form from './Question3Form/Question3Form'
import Question4Form from './Question4Form/Question4Form'

interface IProps {
  state: any
  send: (action: any, payload?: any) => void
}

export default function Step2({ state, send }: IProps) {
  const [, setSessionStorageState] = useSessionStorage<IAnalyticsDataLayer>(SESSION_STORAGE_KEY, {})

  useEffect(() => {
    const virtualPagename = `${document.location.pathname}/etape2`
      ; (window as IAdvizeWindow).iAdvize?.navigate(virtualPagename)

    // sending event asynchronously to await for sessionStorage sync
    setTimeout(() => sendVirtualPageViewAnalytics({ virtualPagename }))
  }, [])

  const questionIndex: number = useMemo(() => {
    return deepFindKey(state.meta, 'questionIndex')
  }, [state])

  useEffect(() => {
    setSessionStorageState(state.context)
  }, [questionIndex])

  const handleSubmit = useCallback(event => send(ACTIONS.NEXT, event), [send])
  const handleEditQuestionOne = useCallback(() => send(ACTIONS.EDIT_QUESTION_1), [send])
  const handleEditQuestionThree = useCallback(() => send(ACTIONS.EDIT_QUESTION_3), [send])
  const handlePostAutodiagnostic = useCallback(event => send(ACTIONS.POST_AUTODIAGNOSTIC, event), [
    send
  ])

  useScrollTopOnLoad()

  const {
    entreprise: { companyForm, companyId, companyContact }
  } = state.context

  return (
    <>
      <Steps steps={STEPS_META} currentIndex={1} />

      <div className="py-10 bg-green-dark-100">
        <div className="px-6 mx-auto lg:w-3/5 xl:w-1/2">
          {questionIndex === 1 && (
            <Question1Form
              dataTestId="question-1-form"
              onSubmit={handleSubmit}
              initialValues={companyForm}
              isFetching={
                state.matches('entreprise.fetchBySiren') ||
                state.matches('entreprise.fetchByNameAndZipCode')
              }
              context={state.context}
            />
          )}
          {questionIndex === 2 && (
            <>
              <ScrollAnchor active={true} />
              <Question2Form
                dataTestId="question-2-form"
                onSubmit={handleSubmit}
                initialValues={{ companyId: companyId?.siret ?? null }}
                context={state.context}
              />
            </>
          )}

          {questionIndex > 2 && (
            <EditableQuestionSummary onEdit={handleEditQuestionOne} className="mb-5">
              <b className="text-lg uppercase font-brand">
                {companyId.uniteLegale.denominationUniteLegale ??
                  companyId.uniteLegale.nomUniteLegale +
                  ' ' +
                  companyId.uniteLegale.prenom1UniteLegale}
              </b>
              <p className="text-sm">
                {companyId.adresseEtablissement.numeroVoieEtablissement}{' '}
                {companyId.adresseEtablissement.typeVoieEtablissement}{' '}
                {companyId.adresseEtablissement.libelleVoieEtablissement}
                <br />
                {companyId.adresseEtablissement.codePostalEtablissement}&nbsp;
                {companyId.adresseEtablissement.libelleCommuneEtablissement}
              </p>
            </EditableQuestionSummary>
          )}

          {questionIndex === 3 && (
            <>
              <ScrollAnchor active={true} />
              <Question3Form
                dataTestId="question-3-form"
                onSubmit={handleSubmit}
                initialValues={{
                  employees: parseInt(companyId.trancheEffectifsEtablissement, 10) || 0
                }}
                context={state.context}
              />
            </>
          )}
          {questionIndex > 3 && (
            <EditableQuestionSummary onEdit={handleEditQuestionThree} className="mb-10">
              <b className="text-lg uppercase font-brand">Nombre de salariés</b>
              <p className="text-3xl font-bold font-brand">
                {companyId.trancheEffectifsEtablissement}
              </p>
            </EditableQuestionSummary>
          )}
          {questionIndex === 4 && (
            <>
              <ScrollAnchor active={true} />
              <Question4Form
                dataTestId="question-4-form"
                onSubmit={handlePostAutodiagnostic}
                initialValues={companyContact}
                context={state.context}
                isFetching={state.matches('entreprise.postAutodiagnostic')}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
