import cs from 'classnames'
import React, { KeyboardEvent, useCallback } from 'react'

interface IProps {
  value: string
  className?: string
  textClassName?: string
  checked?: boolean
  onClick: (value: string) => void
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
  text: string
  tabIndex?: number
  disabled?: boolean,
  variant?: RADIO_VARIANTS,
}

export enum RADIO_VARIANTS {
  DEFAUT,
  SIMPLE,
}

const RadioField = ({
  className,
  textClassName,
  value,
  text,
  onClick,
  onPressEnter,
  tabIndex = 0,
  checked = false,
  disabled = false,
  variant = RADIO_VARIANTS.DEFAUT
}: IProps) => {
  const handleClick = useCallback(() => onClick(value), [value, onClick])
  const handleKeyDown = useCallback(
    e => {
      switch (e.key) {
        case 'Enter':
          typeof onPressEnter !== 'undefined' && onPressEnter(e)
          break
        case ' ':
          handleClick()
          e.preventDefault()
          break
        default:
          break
      }
    },
    [onPressEnter, handleClick]
  )

  return (
    <div
      tabIndex={tabIndex}
      className={cs(
        'flex w-full rounded-lg cursor-pointer',
        {
          'border py-4 items-center bg-white': variant === RADIO_VARIANTS.DEFAUT,
          'border-orange-500 shadow focus:outline-none': checked && variant === RADIO_VARIANTS.DEFAUT,
          'border-transparent shadow-sm': !checked && variant === RADIO_VARIANTS.DEFAUT,
          'opacity-50 pointer-events-none': disabled
        },
        className
      )}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <div
        className={cs('flex justify-center p-1 border rounded-full', {
          'border-orange-500': checked,
          'items-center': variant === RADIO_VARIANTS.DEFAUT,
          'h-full bg-white': variant === RADIO_VARIANTS.SIMPLE,
        })}
      >
        <div className={cs('w-3 h-3 rounded-full', { 'bg-orange-500': checked })} />
      </div>

      <div
        className={cs(
          'ml-3 text-sm',
          {
            'font-bold': variant === RADIO_VARIANTS.DEFAUT,
            'text-orange-500': checked && variant === RADIO_VARIANTS.DEFAUT,
            'text-gray-800': !checked
          },
          textClassName
        )}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default RadioField
