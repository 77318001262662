/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllCardsSliderQuery, IParagraph__Cards_Slider } from '../../graphql.types'
import { IColor } from '../../types/gatsby/colors'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_flex_color,
  field_taille_des_cartes,
  field_plain_description,
  relationships
}: IParagraph__Cards_Slider): IProps => {
  return {
    title: _('title', field_title),
    backgroundColor: field_flex_color as IColor,
    tailleCartes: _('tailleCartes', field_taille_des_cartes),
    description: _('description', field_plain_description),
    button: {
      link: {
        href: relationships?.field_button?.field_link?.uri_alias || relationships?.field_button?.field_link?.uri
      },
      title: relationships?.field_button?.field_title,
      target: relationships?.field_button?.field_link_target,
    },
    list: _('list', relationships?.field_cards_slider_items).map(item => ({
      surTitre: _('sur_titre', item?.field_sur_titre),
      title: _('title', item?.field_title),
      cible: _('cible', item?.field_cible),
      typeOffre: _('type_d_offre', item?.field_type_d_offre),
      description: _('description', item?.field_plain_description),
      isNew: _('isNew', item?.field_is_new),
      chronometre: _('chronometre', item?.field_chronometre),
      thematique: item?.relationships?.field_thematique?.name,
      button: {
        link: {
          href:
            item?.relationships?.field_button?.field_link?.uri_alias ||
            item?.relationships?.field_button?.field_link?.uri
        },
        title: _('title', item?.relationships?.field_button?.field_title),
        target: item?.relationships?.field_button?.field_link_target,
        action: item?.relationships?.field_button?.field_button_action_id,
        style: item?.relationships?.field_button?.field_style
      },
      image: {
        alt: _('alt', item?.field_image?.alt),
        ..._('image', item?.relationships?.field_image?.localFile?.childImageSharp)
      },
      socialMediaLinks: (
        item?.relationships?.field_social_links?.relationships?.field_social_links_items || []
      ).map(social => ({
        icon: {
          alt: social?.relationships?.field_social_network?.field_secondary_icon?.alt ?? '',
          ..._('image',
            social?.relationships?.field_social_network?.relationships?.field_secondary_icon
              ?.localFile?.childImageSharp
          )
        },
        link: {
          href: _('href', social?.field_link?.uri),
          title: social?.relationships?.field_social_network?.field_secondary_icon?.alt ?? ''
        }
      }))
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllCardsSliderQuery>(graphql`
    query AllCardsSlider {
      data: allParagraphCardsSlider {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            field_flex_color
            field_taille_des_cartes
            relationships {
              field_cards_slider_items {
                field_sur_titre
                field_title
                field_cible
                field_type_d_offre
                field_plain_description
                field_image {
                  alt
                  width
                  height
                }
                relationships {
                  field_image {
                    localFile {
                      childImageSharp {
                        fluid(maxHeight: 160) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  field_button {
                    field_title
                    field_link_target
                    field_link {
                      uri_alias
                      uri
                    }
                    field_button_action_id
                    field_style
                  }
                  field_social_links {
                    relationships {
                      field_social_links_items {
                        field_link {
                          title
                          uri
                        }
                        relationships {
                          field_social_network {
                            field_secondary_icon {
                              alt
                              width
                              height
                            }
                            relationships {
                              field_secondary_icon {
                                localFile {
                                  childImageSharp {
                                    fixed(height: 32) {
                                      ...GatsbyImageSharpFixed_withWebp
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  field_thematique {
                    name
                  }
                }
                field_is_new
                field_chronometre
              }
              field_button {
                field_link {
                  uri_alias
                }
                field_title
              }
            }
            field_plain_description
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
