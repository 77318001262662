import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import React from 'react'

import { IImage, IMenu, ISocialMediaLink } from '../../../types/drupal'
import { sendSocialShareAnalytics } from '../../../utils/analytics/analytics'
import buttonPropsFormatter from '../../../utils/paragraphs/buttonPropsFormatter'
import Button, { BUTTON_VARIANTS } from '../Button/Button'
import styles from './Footer.module.css'

export interface IProps {
  logo: IImage
  mobileLogo: IImage
  menu: IMenu[]
  socialMediaLinks: ISocialMediaLink[]
}

const currentYear = new Date().getFullYear()

export default function Footer({ mobileLogo, logo, menu, socialMediaLinks }: IProps) {
  return (
    <footer className="bg-gray-100">
      <div className="container relative flex items-start justify-between p-8 mx-auto xl:px-0">
        {logo.image.childImageSharp && (
          <GatsbyImage
            style={{ display: 'inherit' }}
            className="hidden w-48 h-24 md:block"
            {...logo.image.childImageSharp}
          />
        )}

        <div className="absolute top-0 left-0 pt-8 pl-8 md:hidden">
          {mobileLogo.image.childImageSharp && (
            <GatsbyImage
              style={{ display: 'inherit' }}
              className="w-48 h-24"
              {...mobileLogo.image.childImageSharp}
            />
          )}
        </div>

        <nav
          className={cs(styles.footer__links, 'w-full md:w-2/3 xl:w-1/2 md:ml-auto mt-32 md:mt-0')}
        >
          <ul className="flex flex-col flex-wrap items-center w-full text-sm text-center md:text-left md:items-start">
            {menu.map((entry, index) => (
              <li key={index} className="px-5 pb-3">
                <Button
                  className="flex items-center justify-between"
                  ariaLabel={entry.item.label}
                  {...buttonPropsFormatter({
                    action: entry.item.link.actionId,
                    link: entry.item.link,
                    title: entry.item.link.title,
                    target: entry.item.link.target
                  })}
                  variant={BUTTON_VARIANTS.LINK}
                >
                  <span className="font-sans font-normal text-gray-800 hover:text-orange-500 hover:underline">
                    {entry.item.label}
                  </span>
                </Button>
              </li>
            ))}
          </ul>
        </nav>

        <div className="absolute top-0 right-0 flex items-center p-0 pt-8 pr-8 md:static md:p-0">
          {socialMediaLinks.map((media, index) => (
            <Button
              key={index}
              className="flex items-center justify-between mr-2"
              variant={BUTTON_VARIANTS.LINK}
              target={media.link.target}
              href={media.link.href}
              onClick={() => sendSocialShareAnalytics({ targetUrl: media.link.href })}
              ariaLabel={media.link.title}
              disabledAnalytics={true}
            >
              {media.icon.image.childImageSharp && (
                <GatsbyImage
                  className="w-12 h-12 rounded-full"
                  {...media.icon.image.childImageSharp}
                />
              )}
            </Button>
          ))}
        </div>
      </div>

      <div className="flex justify-center w-full py-2 bg-white md:py-4 font-medium">
        &copy; {currentYear} Flex - Version {process.env.GATSBY_NUMERO_VERSION}
      </div>
    </footer>
  )
}
