import cs from 'classnames'
import React from 'react'

import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import { COLORS, IColor } from '../../types/gatsby/colors'
import { ILink } from '../../types/gatsby/links'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

export interface IProps {
  /** A list of one or two buttons */
  buttons: ILink[]
  /** An optional title */
  title?: string
  /** An optional description */
  description?: string
  /** An optional background color */
  backgroundColor?: IColor
}

const getButtonVariantFactory = (backgroundColor: IColor) => (index: number) => {
  if (backgroundColor === COLORS.WHITE) {
    return index == 0 ? BUTTON_VARIANTS.SECONDARY : BUTTON_VARIANTS.PRIMARY
  }
  return index == 0 ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.PRIMARY_LIGHT
}

const affichageTexteBlanc = (backgroundColor: IColor) => {
  return (
    backgroundColor === COLORS.GREEN_DARK ||
    backgroundColor === COLORS.GREEN ||
    backgroundColor === COLORS.PURPLE_DARK ||
    backgroundColor === COLORS.RED_DARK ||
    backgroundColor === COLORS.PINK ||
    backgroundColor === COLORS.BLUE
  )
}

export default function Component({
  title,
  description,
  buttons,
  backgroundColor = COLORS.WHITE
}: IProps) {
  const getButtonVariant = getButtonVariantFactory(backgroundColor)

  return (
    <section
      className={cs(
        { 'py-16': title || description, 'my-16': !title && !description },
        `bg-${backgroundColor}-500`
      )}
    >
      <div className="container mx-auto text-center">
        {title && (
          <h2
            className={cs('mb-6 text-3xl font-medium leading-none font-brand', {
              'text-white': backgroundColor && backgroundColor !== COLORS.WHITE,
              'text-purple-dark-500': !backgroundColor || backgroundColor === COLORS.WHITE,
              'md:mb-10': !description
            })}
          >
            {title}
          </h2>
        )}

        {description && (
          <p
            className={cs('mb-4 md:w-8/12 md:mx-auto font-bold text-lg', {
              'text-white': backgroundColor && affichageTexteBlanc(backgroundColor),
              'text-purple-dark-500': !backgroundColor || !affichageTexteBlanc(backgroundColor)
            })}
          >
            {description}
          </p>
        )}
        <div
          className={cs(
            { 'my-12': !title && !description, 'mt-8': title && description },
            'flex flex-col justify-center mx-auto md:flex-row lg:w-2/3'
          )}
        >
          {buttons.map((button, index) => (
            < Button
              className={cs('justify-center mx-2 mb-2 sm:mb-0', {
                'flex-1': buttons.length > 1
              })}
              key={`Buttons_${index}`}
              {...buttonPropsFormatter(button)}
              variant={button.style ? getButtonVariant(Object.keys(BUTTON_VARIANTS).filter((variant: any) => BUTTON_VARIANTS[variant] === button.style)[0]) : buttons.length === 1 && backgroundColor !== null ? BUTTON_VARIANTS.SECONDARY : getButtonVariant(index)}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
