import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import breakpoints from '../../assets/css/breakpoints'
import Button, { BUTTON_VARIANTS, ICON_POSITIONS } from '../../components/presentationals/Button/Button'
import Icon, { ICONS } from '../../components/presentationals/Icon/Icon'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IDrupalButton } from '../../types/drupal'
import { IImage } from '../../types/gatsby/images'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

import styles from './Component.module.css'

interface IItem {
  title?: string
  description: string
}

export interface IProps {
  /** The title that will be displayed using an H1 */
  title: string

  /** The heading image */
  image?: IImage

  lienButton?: IDrupalButton

  buttons?: IDrupalButton[]

  descriptions?: IItem[]
}

export default function Component({
  title,
  descriptions,
  image,
  buttons = [],
  lienButton,
}: IProps) {
  const isDesktop = useBreakpoint(breakpoints.md);
  return (
    <>
      <section
        className={!isDesktop ? cs(
          'bg-circle bg-circle-bottom bg-circle-left bg-circle-green-dark'
        ) : ''}
      >
        <div className="bg-green-dark-100 pt-5 pb-24">
          <div className="container mx-auto">
            <h1
              className="mb-2 mt-16 mb-4 text-3xl text-center font-medium leading-none whitespace-pre-line font-brand text-purple-dark-500">
              {title}
            </h1>
            <div className="w-12 h-1 mx-auto bg-orange-500 rounded"></div>
            <div className={"flex flex-col-reverse md:flex-row mt-16 md:mt-24"}>
              <div className="w-full pt-16 align-middle">
                <h2
                  className="mb-6 md:mb-5 text-3xl font-medium leading-none text-center md:text-left whitespace-pre-line font-brand text-purple-dark-500"
                >
                  {descriptions && descriptions[0]?.title}
                </h2>
                <div
                  className="text-center md:text-left"
                  dangerouslySetInnerHTML={{ __html: descriptions[0].description }}
                ></div>
                {buttons.length > 0 && (
                  <div className="flex flex-col md:flex-row mt-8 md:mt-5 mb-5 items-center">

                    {buttons.length > 0 && (

                      <Button
                        className={cs('flex items-center relative justify-center w-56 md:w-auto text-center', styles.phone, {
                          'mb-4 md:mb-0 md:mr-1': typeof buttons[1] !== 'undefined'
                        })}
                        {...buttonPropsFormatter(buttons[0])}
                      >

                      </Button>
                    )}

                    {buttons.length > 1 && (
                      <Button
                        className={cs(
                          'flex items-center justify-center w-56 md:w-auto md:ml-1 md:ml-5 text-center'
                        )}
                        {...buttonPropsFormatter(buttons[1])}
                      />
                    )}
                  </div>
                )}

                <div className="items-center mt-6 md:mt-4 sm:items-stretch">
                  {lienButton && (
                    <Button
                      className="flex items-center justify-center sm:justify-start"
                      iconPosition={ICON_POSITIONS.LEFT}
                      iconClassname="text-orange-500 -ml-2"
                      {...buttonPropsFormatter(lienButton)}
                      variant={BUTTON_VARIANTS.LINK}
                    >
                      <span className="text-base leading-none md:text-base">
                        {lienButton.title}
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              <div className={cs(
                'relative',
                styles.img
              )}>
                {image && <GatsbyImage className="w-full h-full" {...image} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
