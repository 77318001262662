import React, { useCallback, ChangeEvent, KeyboardEvent } from 'react'
import cs from 'classnames'

interface IProps {
  className?: string
  value: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  increment: (count: number) => void
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
  min?: number
  max?: number
  step?: number
  disabled?: boolean
}

const NumberField = ({
  className,
  value,
  onChange,
  increment,
  onPressEnter,
  min,
  max,
  step = 1,
  disabled = false
}: IProps) => {
  const memoizedIncrement = useCallback(() => increment(step), [step, increment])
  const memoizedDecrement = useCallback(() => increment(-step), [step, increment])

  return (
    <div className={cs('flex items-center', className)}>
      <button
        aria-hidden="true"
        className="relative flex items-center justify-center p-4 mr-8 font-bold text-orange-500 bg-white border border-orange-500 rounded-full"
        onClick={memoizedDecrement}
        disabled={typeof min !== 'undefined' ? value - step < min : false}
        type="button"
      >
        <p className="absolute text-3xl">-</p>
      </button>

      <input
        type="number"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        disabled={disabled}
        onKeyDown={e => e.key === 'Enter' && typeof onPressEnter !== 'undefined' && onPressEnter(e)}
        className={cs(
          'min-w-0 w-full px-4 py-3 border rounded-lg focus:outline-none text-2xl text-center'
        )}
      />

      <button
        aria-hidden="true"
        className="relative flex items-center justify-center p-4 ml-8 font-bold text-orange-500 bg-white border border-orange-500 rounded-full"
        onClick={memoizedIncrement}
        disabled={typeof max !== 'undefined' ? value + step > max : false}
        type="button"
      >
        <p className="absolute text-3xl">+</p>
      </button>
    </div>
  )
}

export default NumberField
