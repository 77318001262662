import React, { ChangeEvent, KeyboardEvent, ClipboardEvent, FormEvent } from 'react'
import cs from 'classnames'
import { kebabCase } from 'lodash'
import './TextAreaField.css'

interface IProps {
  id?: string
  className?: string
  dataTestId?: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (e: FormEvent) => void
  name?: string
  label?: string
  error?: string
  placeholder?: string
  maxLength?: number
  disabled?: boolean
  trimWhitespace?: boolean
  tabIndex?: number
  required?: boolean
}

const TextAreaField = ({
  className,
  dataTestId,
  value,
  onChange,
  onPressEnter,
  onBlur,
  name,
  label,
  error,
  placeholder,
  maxLength,
  id = `input-${kebabCase(label)}`,
  tabIndex = 0,
  disabled = false,
  required = false
}: IProps) => (
  <div className={className} data-testid={dataTestId}>
    <div className="flex">
      <label htmlFor={id} className="font-bold mb-2 font-brand text-purple-dark-500">
        {label}
      </label>
      <span className="float-right text-xs font-sans mb-1 text-right w-full self-end text-purple-dark-500">optionnel</span>
    </div>
    <textarea
      rows="6"
      id={id}
      className={cs('w-full px-4 py-3 border rounded-lg disabled-input focus:outline-none', {
        'border-red-600': error
      })}
      tabIndex={disabled ? -1 : tabIndex}
      title={placeholder}
      aria-label={label}
      name={name}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      value={value}
      onKeyDown={e => e.key === 'Enter' && typeof onPressEnter !== 'undefined' && onPressEnter(e)}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      aria-required={required}
      aria-invalid={!!error}
      aria-describedby={error}
    />
    {error && <p className="mt-2 italic text-red-600">{error}</p>}
  </div>
)

export default TextAreaField
