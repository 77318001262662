import React, { FunctionComponent } from 'react'

import { ReactComponent as ChevronOrange } from '../../../assets/svg/arrow_down_orange_next.svg'
import { ReactComponent as ArrowRightSvg } from '../../../assets/svg/arrow_right.inline.svg'
import { ReactComponent as ArrowMenu } from '../../../assets/svg/arrow_menu.inline.svg'
import { ReactComponent as ArrowDownWhiteSvg } from '../../../assets/svg/arrow-down_white.svg'
import { ReactComponent as EditButtonSvg } from '../../../assets/svg/edit_button.inline.svg'
import { ReactComponent as CircleCheckSvg } from '../../../assets/svg/circle_check.inline.svg'
import { ReactComponent as CheckSvg } from '../../../assets/svg/check.inline.svg'
import { ReactComponent as HandshakeSvg } from '../../../assets/svg/handshake.inline.svg'
import { ReactComponent as HeadLightBulbSvg } from '../../../assets/svg/head_lightbulb.inline.svg'
import { ReactComponent as ScaleSvg } from '../../../assets/svg/scale.inline.svg'
import { ReactComponent as BarChartSvg } from '../../../assets/svg/bar_chart.inline.svg'
import { ReactComponent as AbsenteeismSvg } from '../../../assets/svg/absenteeism.inline.svg'
import { ReactComponent as NoneSvg } from '../../../assets/svg/none.inline.svg'
import { ReactComponent as PlaySvg } from '../../../assets/svg/play.inline.svg'
import { ReactComponent as BurgerSvg } from '../../../assets/svg/burger.inline.svg'
import { ReactComponent as ChevronSvg } from '../../../assets/svg/chevron.inline.svg'
import { ReactComponent as CrossSvg } from '../../../assets/svg/cross.inline.svg'
import { ReactComponent as SpinnerSvg } from '../../../assets/svg/spinner.inline.svg'
import { ReactComponent as CircleCrossSvg } from '../../../assets/svg/circle_cross.inline.svg'
import { ReactComponent as PhoneSvg } from '../../../assets/svg/phone.inline.svg'
import { ReactComponent as SliderNextArrowSvg } from '../../../assets/svg/slider_next_arrow.svg'
import { ReactComponent as SliderPrevArrowSvg } from '../../../assets/svg/slider_prev_arrow.svg'
import { ReactComponent as SliderPrevArrowOrangeSvg } from '../../../assets/svg/arrow_down_orange_prev.svg'
import { ReactComponent as SliderNextArrowOrangeSvg } from '../../../assets/svg/arrow_down_orange_next.svg'
import { ReactComponent as BasicCheck } from '../../../assets/svg/basic-check.svg'
import { ReactComponent as EntrepriseWhite } from '../../../assets/svg/entreprise-white.svg'
import { ReactComponent as Entreprise } from '../../../assets/svg/entreprise.svg'
import { ReactComponent as Particulier } from '../../../assets/svg/particulier.svg'
import { ReactComponent as ParticulierWhite } from '../../../assets/svg/particulier-white.svg'
import { ReactComponent as Timer } from '../../../assets/svg/timer.svg'
import { ReactComponent as Pack } from '../../../assets/svg/Icon-pack.svg'
import { ReactComponent as Option } from '../../../assets/svg/Icon-option.svg'
import { ReactComponent as Calendar } from '../../../assets/svg/icons-calendar.svg'
import { ReactComponent as CheckboxUnchecked } from '../../../assets/svg/checkboxUnchecked.svg'
import { ReactComponent as Checkboxchecked } from '../../../assets/svg/checkboxChecked.svg'

export enum ICONS {
  ArrowRight = 'ArrowRight',
  ArrowMenu = 'ArrowMenu',
  ArrowDownWhiteSvg = 'ArrowDownWhiteSvg',
  EditButton = 'EditButton',
  Calendar = 'Calendar',
  CircleCheck = 'CircleCheck',
  CircleCross = 'CircleCross',
  Check = 'Check',
  Handshake = 'Handshake',
  HeadLightBulb = 'HeadLightBulb',
  Scale = 'Scale',
  BarChart = 'BarChart',
  Absenteeism = 'Absenteeism',
  None = 'None',
  Play = 'Play',
  Burger = 'Burger',
  Chevron = 'Chevron',
  ChevronOrange = 'ChevronOrange',
  Cross = 'Cross',
  Spinner = 'Spinner',
  Phone = 'Phone',
  SliderNextArrow = 'SliderNextArrow',
  SliderPrevArrow = 'SliderPrevArrow',
  SliderPrevArrowOrange = 'SliderPrevArrowOrange',
  SliderNextArrowOrange = 'SliderNextArrowOrange',
  BasicCheck = 'BasicCheck',
  EntrepriseWhite = 'EntrepriseWhite',
  Entreprise = 'Entreprise',
  Particulier = 'Particulier',
  ParticulierWhite = 'ParticulierWhite',
  Timer = 'Timer',
  Pack = 'Pack',
  Option = 'Option',
  CheckboxUnchecked = 'CheckboxUnchecked',
  Checkboxchecked = 'Checkboxchecked',
}

const ReactComponents = {
  [ICONS.ArrowRight]: ArrowRightSvg,
  [ICONS.ArrowMenu]: ArrowMenu,
  [ICONS.ArrowDownWhiteSvg]: ArrowDownWhiteSvg,
  [ICONS.EditButton]: EditButtonSvg,
  [ICONS.CircleCheck]: CircleCheckSvg,
  [ICONS.CircleCross]: CircleCrossSvg,
  [ICONS.Check]: CheckSvg,
  [ICONS.Handshake]: HandshakeSvg,
  [ICONS.HeadLightBulb]: HeadLightBulbSvg,
  [ICONS.Scale]: ScaleSvg,
  [ICONS.BarChart]: BarChartSvg,
  [ICONS.Absenteeism]: AbsenteeismSvg,
  [ICONS.None]: NoneSvg,
  [ICONS.Play]: PlaySvg,
  [ICONS.Burger]: BurgerSvg,
  [ICONS.Chevron]: ChevronSvg,
  [ICONS.ChevronOrange]: ChevronOrange,
  [ICONS.Cross]: CrossSvg,
  [ICONS.Spinner]: SpinnerSvg,
  [ICONS.Phone]: PhoneSvg,
  [ICONS.SliderNextArrow]: SliderNextArrowSvg,
  [ICONS.SliderPrevArrow]: SliderPrevArrowSvg,
  [ICONS.SliderPrevArrowOrange]: SliderPrevArrowOrangeSvg,
  [ICONS.SliderNextArrowOrange]: SliderNextArrowOrangeSvg,
  [ICONS.BasicCheck]: BasicCheck,
  [ICONS.EntrepriseWhite]: EntrepriseWhite,
  [ICONS.Entreprise]: Entreprise,
  [ICONS.Particulier]: Particulier,
  [ICONS.ParticulierWhite]: ParticulierWhite,
  [ICONS.Timer]: Timer,
  [ICONS.Pack]: Pack,
  [ICONS.Option]: Option,
  [ICONS.Calendar]: Calendar,
  [ICONS.CheckboxUnchecked]: CheckboxUnchecked,
  [ICONS.Checkboxchecked]: Checkboxchecked,
}

interface IIconProps {
  name: string
  className?: string
  width?: string
  height?: string
}

type IProps = Pick<IIconProps, Exclude<keyof IIconProps, 'name'>>

export default function Icon({
  name,
  width = name === ICONS.Spinner ? '1.3em' : '1em',
  height = name === ICONS.Spinner ? '1.3em' : '1em',
  ...props
}: IIconProps) {
  // @ts-ignore
  const ReactComponent: FunctionComponent<IProps> = ReactComponents[name]

  return <ReactComponent width={width} height={height} {...props} />
}
