/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IParagraph__Retour_Soucription } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_image,
  relationships
}: IParagraph__Retour_Soucription): IProps => {
  return {
    title: _('title', field_title),
    lienButton: {
      link: {
        href: relationships?.field_lien?.field_link?.uri_alias || relationships?.field_lien?.field_link?.uri
      },
      title: _('title', relationships?.field_lien?.field_title),
      target: relationships?.field_lien?.field_link_target,
      action: relationships?.field_lien?.field_button_action_id
    },
    image: field_image
      ? {
        alt: field_image.alt ?? '',
        ...relationships?.field_image?.localFile?.childImageSharp
      }
      : undefined,
    buttons: _('buttons', relationships?.field_buttons).map(button => ({
      link: {
        href: button?.field_link?.uri_alias || button?.field_link?.uri
      },
      title: _('title', button?.field_title),
      target: button?.field_link_target,
      action: button?.field_button_action_id
    })),
    descriptions: relationships?.field_titled_descriptions?.map(richDesc => ({
      // TODO Use real contributed title
      title: richDesc?.field_title,
      description: _('description', richDesc?.field_rich_description?.value)
    }))
  }
}

const Query = () =>
  useStaticQuery(graphql`
  query AllRetourSouscription {
    data: allParagraphRetourSouscription {
      edges {
        node {
          drupal_internal__revision_id
          field_image {
            alt
          }
          field_titled_descriptions {
            target_revision_id
          }
          field_title
          relationships {
            field_lien {
              field_title
              field_link {
                uri
                uri_alias
              }
              field_link_target
              field_button_action_id
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            field_buttons {
              field_link {
                uri
                uri_alias
              }
              field_link_target
              field_button_action_id
              field_title
            }
            field_titled_descriptions {
              field_rich_description {
                value
              }
              field_title
            }
          }
        }
      }
    }
  }  
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
