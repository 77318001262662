/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllBannerWithVideoQuery, IParagraph__Hero_Banner_With_Video } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_video_id,
  field_image,
  relationships,
  field_url_complete_video,
  field_alignement_texte,
  field_couleur_texte
}: IParagraph__Hero_Banner_With_Video): IProps => {
  return {
    title: _('title', field_title),
    description: field_plain_description,
    youtubeVideoId: field_video_id,
    image: {
      alt: _('alt', field_image?.alt),
      ..._('image', relationships?.field_image?.localFile?.childImageSharp)
    },
    lien: {
      uri: field_url_complete_video?.uri
    },
    buttons: _('buttons', relationships?.field_buttons).map(button => ({
      link: {
        href: button?.field_link?.uri_alias || button?.field_link?.uri
      },
      title: _('title', button?.field_title),
      target: button?.field_link_target,
      action: button?.field_button_action_id,
      style: _('style', button?.field_style),
      affichageFleche: _('affichageTexte', button?.field_affichage_fleche)
    })),
    alignementTexte: _('alignementTexte', field_alignement_texte),
    couleurTexte: _('Couleur', field_couleur_texte)
  }
}

const Query = () =>
  useStaticQuery<IAllBannerWithVideoQuery>(graphql`
    query AllBannerWithVideo {
      data: allParagraphHeroBannerWithVideo {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            field_plain_description
            field_video_id
            field_image {
              alt
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 640) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              field_buttons {
                field_title
                field_link {
                  uri_alias
                  uri
                }
                field_link_target
                field_button_action_id
                field_style
                field_affichage_fleche
              }
            }
            field_url_complete_video {
              uri
            }
            field_alignement_texte
            field_couleur_texte
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
