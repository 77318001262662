import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick'
import cs from 'classnames'
import GatsbyImage from 'gatsby-image'

import Button, {
  BUTTON_VARIANTS,
  ICON_POSITIONS
} from '../../components/presentationals/Button/Button'
import Icon, { ICONS } from '../../components/presentationals/Icon/Icon'
import { IDrupalButton } from '../../types/drupal/index'
import { IImage } from '../../types/gatsby/images'
import { ILink } from '../../types/gatsby/links'
import toCommaFixed from '../../utils/misc/toCommaFixed'

import { IAnalyticsEvent, sendClicZonePageAnalytics, sendVirtualPageViewAnalytics } from '../../utils/analytics/analytics'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'
import './Component.css'

export { default as data } from './__mocks__/data'

interface IService {
  name: string
  included: boolean
  link: ILink
}

interface ISubPack {
  name: string
  image: IImage
  price: number
  price2: number
  libellePrice: string
  libellePrice2: string
  mentionSalarie: boolean
  marquerPrix: string
  services: IService[]
  boutonSousPack: IDrupalButton
}

interface IPack {
  name: string
  identifier: string
  description: string
  subPacks: ISubPack[]
}

export interface IProps {
  title?: string
  description?: string
  serviceLink: boolean,
  packs: [IPack]
  button?: IDrupalButton
  focusedPackIdentifier?: string
  recommendFocusedPack?: boolean
  numberOfEmployees?: number
}

const SLIDER_CONFIG = {
  dots: false,
  infinite: false,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true,
        slidesToShow: 1.25,
        slidesToScroll: 1
      }
    }
  ]
}

export default function Component({
  title,
  description,
  serviceLink,
  packs,
  button,
  focusedPackIdentifier,
  numberOfEmployees = 1,
  recommendFocusedPack = false
}: IProps) {
  const slider = useRef<Slider>(null)

  const [focusedPack, setFocusedPack] = useState(
    packs.find(pack => pack.identifier === focusedPackIdentifier) || packs[0]
  )

  const emphasizedIndex =
    focusedPack.subPacks.length > 2
      ? focusedPack.subPacks.length - 2
      : focusedPack.subPacks.length - 1
  const [currentSlide, setCurrentSlide] = useState(emphasizedIndex)

  const onPackOrSlideChange = (packId: string, slide: number) => {
    sendVirtualPageViewAnalytics({
      virtualPagename: `${document.location.pathname}/${packId}/${slide}`
    })
  }

  useEffect(() => {
    const test = document.getElementsByClassName('slick-list')
    Array.prototype.forEach.call(test, function (sl) {
      sl.style.display = 'block'
    })
  });

  return (
    <section className="bg-white">
      <a className="ancre" id="packs-promo" />
      {packs.length > 1 && (
        <div className="flex items-stretch">
          {packs.map((pack, index) => (
            <button
              key={index}
              className={cs(
                'flex flex-col relative w-1/2 px-2 lg:px-10 py-6 md:py-10 mx-1 items-center text-white cursor-pointer',
                { 'bg-gray-100': focusedPack.identifier !== pack.identifier },
                {
                  'bg-green-dark-500 focus:outline-none': focusedPack.identifier === pack.identifier
                },
                { 'pt-12': typeof emphasizedIndex !== undefined }
              )}
              onClick={() => {
                setFocusedPack(pack)
                setCurrentSlide(emphasizedIndex)
                onPackOrSlideChange(pack.identifier, emphasizedIndex)
                if (!!slider.current) (slider.current as Slider)?.slickGoTo(emphasizedIndex)
              }}
            >
              {focusedPackIdentifier === pack.identifier && recommendFocusedPack && (
                <span className="absolute px-4 mx-auto mb-3 text-xs leading-6 whitespace-no-wrap bg-orange-500 rounded-full md:text-sm top-10">
                  Notre recommandation
                </span>
              )}

              <p
                className={cs(
                  'px-6 mb-1 text-lg font-bold leading-snug font-brand',
                  focusedPack.identifier !== pack.identifier && 'text-orange-500'
                )}
              >
                {pack.name}
              </p>
              <p
                className={cs(
                  'text-sm leading-snug',
                  focusedPack.identifier !== pack.identifier && 'text-gray-700'
                )}
              >
                {pack.description}
              </p>

              {focusedPack.identifier === pack.identifier && (
                <div className="absolute w-6 h-6 border-b-4 border-r-4 border-white pack__needle md:w-8 md:h-8">
                  <div className="w-full h-full bg-green-dark-500" />
                </div>
              )}
            </button>
          ))}
        </div>
      )}
      <div id="packs__wrapper" className="pt-8 text-center bg-green-dark-100 bg-circle bg-circle-bottom bg-circle-left bg-circle-green">
        <h3 className="px-16 mb-5 mt-10 text-3xl font-medium leading-none text-center sm:text-3xl font-brand text-purple-dark-500">{title}</h3>
        <p className="px-10 mx-auto text-center text-gray-700 md:text-xl md:w-1/2 mb-10 leading-5">{description}</p>
        <Slider
          {...SLIDER_CONFIG}
          ref={slider}
          className="text-center md:mx-2 xl:mx-48 2xl:mx-64"
          beforeChange={(_, nextIndex: number) =>
            setTimeout(() => {
              setCurrentSlide(nextIndex)
              onPackOrSlideChange(focusedPack.identifier, nextIndex)
            }, 400)
          }
          initialSlide={currentSlide}
        >
          {focusedPack.subPacks.map((subPack, subpackIndex) => (
            <div
              key={subpackIndex}
              className="subpack__wrapper pb-12 pr-4 sm:px-10 md:px-0 md:pr-4 flow-root h-full focus:outline-none"

            >
              <div
                className={cs(
                  'subpack flex flex-col items-center w-full h-full shadow-xl px-6 py-8 ml-12 bg-white rounded-lg md:w-full md:m-2 md:w-1/3',
                  { 'shadow-xl': currentSlide === subpackIndex }
                )}
              >
                {subPack.image.fixed && <GatsbyImage className="w-24 h-24" {...subPack.image} />}
                <div className="w-6 h-1 mx-auto my-5 bg-orange-500 rounded-lg md:mx-0" />
                {(subPack.marquerPrix != null && subPack.marquerPrix.length > 0) ? <p className="text-sm text-purple-dark-500 font-brand">{subPack.marquerPrix}</p> : ''}
                {subPack.price &&
                  <p className="text-3xl font-bold whitespace-no-wrap text-purple-dark-500 font-brand">
                    <span className={cs({ 'block mt-4 leading-3': numberOfEmployees > 1 })}>
                      {toCommaFixed(subPack.price * numberOfEmployees, 2)} €{' '}
                    </span>
                    {subPack.libellePrice &&
                      <small className="text-sm">{' ' + subPack.libellePrice}</small>
                    }
                    {!subPack.libellePrice &&
                      <small className="text-sm">
                        HT / {(subPack.mentionSalarie == null || subPack.mentionSalarie) ?
                          numberOfEmployees > 1 ? `${numberOfEmployees} salariés / ` : 'salarié / ' : ''
                        } mois
                      </small>
                    }
                  </p>
                }
                {subPack.price2 &&
                  <p className="text-sm pb-2 whitespace-no-wrap text-purple-dark-500 font-brand">
                    {toCommaFixed(subPack.price2 * numberOfEmployees, 2)}
                    {subPack.libellePrice &&
                      <small className="text-sm">{' € ' + subPack.libellePrice2}</small>
                    }
                    {!subPack.libellePrice2 &&
                      <>
                        € HT /
                        {(subPack.mentionSalarie == null || subPack.mentionSalarie) ?
                          numberOfEmployees > 1 ? `${numberOfEmployees} salariés / ` : 'salarié / ' : ''
                        } an
                      </>
                    }
                  </p>
                }
                {subPack.services.map((service, serviceIndex) => (
                  <div key={serviceIndex} className="flex items-center w-full py-2">
                    <div>
                      <Icon
                        name={service.included ? ICONS.CircleCheck : ICONS.CircleCross}
                        width="24"
                        height="24"
                      />
                    </div>
                    {!serviceLink ?
                      <Button
                        className="flex items-center w-full"
                        variant={BUTTON_VARIANTS.LINK}
                        href={service.link.link?.href}
                        target={service.link.target}
                        iconPosition={ICON_POSITIONS.RIGHT}
                        iconClassname="text-orange-500 ml-auto"
                        ariaLabel={service.name}
                        onClick={() => {
                          sendClicZonePageAnalytics({
                            buttonLabel: subPack.name,
                            zoneCategory: 'body'
                          })
                        }}
                      >
                        <p className="w-full pl-3 font-sans text-sm font-normal text-left text-gray-800">
                          {service.name}
                        </p>
                      </Button>
                      :
                      <p className="w-full pl-3 h-8 font-sans text-sm font-normal text-left text-gray-800 py-1">
                        {service.name}
                      </p>
                    }

                  </div >
                ))
                }
                {
                  subPack.boutonSousPack.link?.href &&
                  <div className="w-full mt-6">
                    <Button
                      className={cs("d-block items-center w-full",)}
                      variant={BUTTON_VARIANTS.PRIMARY}
                      href={subPack.boutonSousPack.link?.href}
                      iconClassname="text-orange-500 ml-auto"
                      ariaLabel={subPack.boutonSousPack.title}
                      onClick={() => {
                        sendClicZonePageAnalytics({
                          buttonLabel: subPack.name,
                          zoneCategory: 'body'
                        })
                      }}
                    >
                      {subPack.boutonSousPack.title}
                    </Button>
                  </div>
                }
              </div >
            </div >
          ))}
        </Slider >

        {button && (
          <Button
            className="flex items-center mt-6 mb-24 md:mt-0"
            analyticsEventName={IAnalyticsEvent.ME_RAPPELER}
            variant={!button.style || button.style === 'Défaut' ? BUTTON_VARIANTS.SECONDARY : button.style === "PRIMARY" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
            {...buttonPropsFormatter(button)}
          />
        )}
      </div >
    </section >
  )
}
