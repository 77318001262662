/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllBannerSimpleQuery, IParagraph__Hero_Banner_Simple } from '../../graphql.types'
import { IColor } from '../../types/gatsby/colors'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_flex_color,
  field_image,
  relationships
}: IParagraph__Hero_Banner_Simple): IProps => {
  return {
    title: _('title', field_title),
    description: field_plain_description,
    backgroundColor: field_flex_color as IColor,
    image: field_image
      ? {
          alt: field_image.alt ?? '',
          ...relationships?.field_image?.localFile?.childImageSharp
        }
      : undefined
  }
}

const Query = () =>
  useStaticQuery<IAllBannerSimpleQuery>(graphql`
    query AllBannerSimple {
      data: allParagraphHeroBannerSimple {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            field_plain_description
            field_flex_color
            field_image {
              alt
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 285) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
