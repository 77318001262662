import cs from 'classnames'
import React, { KeyboardEvent, useCallback } from 'react'

import Icon, { ICONS } from '../../presentationals/Icon/'

interface IProps {
  className?: string
  initialValue?: boolean
  icon: JSX.Element
  checked: boolean
  onClick: () => void
  text?: string
  disabled?: boolean
  tabIndex?: number
}

const CheckboxField = ({
  className,
  icon,
  text,
  checked,
  onClick,
  tabIndex = 0,
  disabled = false
}: IProps) => {
  const handleSpacePress = useCallback(
    (e: KeyboardEvent<HTMLLabelElement>) => {
      if (e.key === ' ' && typeof onClick !== 'undefined') {
        onClick()
        e.preventDefault()
      }
    },
    [onClick]
  )

  return (
    <div className={cs(className, 'w-48 h-40')}>
      <label
        tabIndex={disabled ? -1 : tabIndex}
        className={cs(
          'relative flex flex-col justify-around items-center w-full h-full pt-3 sm:pt-4 pb-2 sm:pb-3 text-center bg-gray-100 border-gray-600 rounded-lg border border-transparent cursor-pointer outline-none focus:border-orange-500 px-1 sm:px-2',
          {
            'border-orange-500 shadow': checked,
            'tracking-wide': !checked,
            'opacity-50 pointer-events-none': disabled
          }
        )}
        htmlFor={`checkbox-${text}`}
        onKeyDown={handleSpacePress}
      >
        {checked && (
          <div className="absolute top-0 right-0 p-2">
            <Icon name={ICONS.CircleCheck} width="24" height="24" />
          </div>
        )}

        {icon}
        <span
          className={cs(
            'pt-2 text-xs leading-snug select-none sm:text-sm sm:pt-3',
            { 'text-purple-dark-500 font-semibold': checked },
            { 'text-gray-700': !checked }
          )}
        >
          {text}
        </span>
      </label>
      <input
        value={text}
        checked={checked}
        className="hidden"
        type="checkbox"
        id={`checkbox-${text}`}
        onClick={onClick}
      />
    </div>
  )
}

export default CheckboxField
