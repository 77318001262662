import { Form, Formik } from 'formik'
import React, { useState, useCallback } from 'react'

import TextField from '../../../../../components/formik/TextField/TextField'

import Button, {
  BUTTON_VARIANTS,
  ICON_POSITIONS
} from '../../../../../components/presentationals/Button/Button'
import { useIAdvizeObserver } from '../../../../../hooks/useIAdvizeObserver'
import { IAnalyticsEvent } from '../../../../../utils/analytics/analytics'
import { AVAILABLE_CLASS_SELECTOR } from '../../../../../utils/iAdvize/showModal'
import { BUTTON_ACTIONS_NAMES } from '../../../../../utils/paragraphs/buttonPropsFormatter'
import validationSchema from './Question4Form.validationSchema'

interface IProps {
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  context?: any
  isFetching: boolean
}

const Question4Form = ({ onSubmit, dataTestId, initialValues, context, isFetching }: IProps) => {
  const formattedOnSubmit = useCallback(values => onSubmit({ data: values }), [onSubmit])

  const isIAdvizeAvailable = useIAdvizeObserver(AVAILABLE_CLASS_SELECTOR)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid, errors } = props

        // in order to show errors, keeping state of fields that have lost focus already
        const [blurredFields, setBlurredFields] = useState({
          lastname: false,
          firstname: false,
          email: false
        })

        const handleChange = useCallback(e => setFieldValue(e.target.name, e.target.value), [
          setFieldValue
        ])
        const handlePressEnter = useCallback(() => isValid && handleSubmit(), [handleSubmit])
        const handleBlur = useCallback(
          e => setBlurredFields({ ...blurredFields, [e.target.name]: true }),
          [blurredFields, setBlurredFields]
        )

        return (
          <Form>
            <fieldset>
              <div data-testid={dataTestId}>
                <h3 className="mb-6 text-3xl font-bold md:text-4xl font-brand text-purple-dark-500">
                  Contact entreprise
                </h3>

                <TextField
                  name="lastname"
                  className="mb-4"
                  label="Votre nom"
                  value={values['lastname']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.lastname ? (errors['lastname'] as string) : undefined}
                  disabled={isFetching}
                />

                <TextField
                  name="firstname"
                  className="mb-4"
                  label="Votre prénom"
                  value={values['firstname']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.firstname ? (errors['firstname'] as string) : undefined}
                  disabled={isFetching}
                />

                <TextField
                  name="email"
                  label="Votre e-Mail"
                  value={values['email']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.email ? (errors['email'] as string) : undefined}
                  disabled={isFetching}
                />

                <div className="mt-10 text-center">
                  {context.entreprise.invokeError && (
                    <div className="mb-2 italic text-red-500 whitespace-pre-wrap md:mb-4">
                      <span className="mr-1">{context.entreprise.invokeError}</span>
                      {isIAdvizeAvailable && (
                        <>
                          <br />
                          <span className="mr-1">Si les difficultés persistent vous pouvez</span>
                          <Button
                            className="italic text-red-500 underline"
                            action={BUTTON_ACTIONS_NAMES.CONTACT_POPIN}
                            variant={BUTTON_VARIANTS.NONE}
                            analyticsEventName={IAnalyticsEvent.ICONE_RAPPEL}
                            ariaLabel="Être rappelé"
                          >
                            demander à être rappelé(e).
                          </Button>
                        </>
                      )}
                    </div>
                  )}

                  <Button
                    className="relative flex justify-center w-full md:w-2/5"
                    dataTestId="trigger-next-button"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={handleSubmit}
                    loading={isFetching}
                    iconPosition={isFetching ? ICON_POSITIONS.RIGHT : undefined}
                    iconClassname="absolute right-0 top-0 mr-4 mt-1 transform scale-75"
                    disabled={!isValid || isFetching}
                    ariaLabel="Je valide"
                    analyticsDataLayer={{
                      buttonLabel: "Je valide"
                    }}
                  >
                    Je valide
                  </Button>
                </div>
              </div>
            </fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Question4Form
