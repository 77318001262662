import cs from 'classnames'
import React, { ReactNode } from 'react'

import Icon, { ICONS } from '../../Icon'

interface IProps {
  className?: string
  text: ReactNode
  children?: ReactNode
  onClick?: () => void
  editBtnDataTestId?: string
  hideChildren?: boolean
}

export default function QuestionEditable({
  className,
  text,
  onClick,
  children,
  editBtnDataTestId
}: IProps) {
  return (
    <div className={cs(className, 'p-5 mb-4 bg-white rounded-lg md:p-8')}>
      <div className="relative flex flex-row items-center justify-between">
        <p className="mr-16 text-lg leading-normal md:text-xl font-brand text-purple-dark-500">
          {text}
        </p>
        {onClick && (
          <button
            className="absolute right-0 flex items-center justify-center flex-shrink-0 w-12 h-12 text-4xl text-orange-500 rounded-full hover:bg-gray-100"
            onClick={onClick}
            data-testid={editBtnDataTestId}
          >
            <Icon name={ICONS.EditButton} />
          </button>
        )}
      </div>
      {children}
    </div>
  )
}
