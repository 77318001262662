import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import Link from 'gatsby-link'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import profile from '../../../assets/svg/profile.svg'
import { IButtonItem, IImage, IMenu } from '../../../types/drupal'
import { IAnalyticsEvent, sendClicZonePageAnalytics } from '../../../utils/analytics/analytics'
import Button, { BUTTON_VARIANTS, ICON_POSITIONS } from '../Button/Button'
import Icon, { ICONS } from '../Icon'
import styles from './HeaderDesktop.module.css'

interface IProps {
  className?: string
  mobileLogo: IImage
  logo: IImage
  buttons: IButtonItem[]
  menu: IMenu[]
}

export default function HeaderDesktop({ className, logo, mobileLogo, menu, buttons }: IProps) {
  const [isProfileOpen, setProfileOpen] = useState(false)
  const [openedSubmenu, setOpenedSubmenu] = useState(-1)

  /**
   * We need to close subnav when use press escape
   * for A11Y purpose.
   */
  const handleKeyPress = useCallback(event => {
    const { keyCode } = event

    const escapeKeyCode = 27
    if (keyCode === escapeKeyCode) {
      setOpenedSubmenu(-1)
      setProfileOpen(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const refMenu = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const handleResize = () => {
      let hauteur = refMenu?.current?.clientHeight;
      if (hauteur != 0) {
        document.documentElement.style.setProperty("--menu-height", hauteur + 'px');
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize();
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <header ref={refMenu} className={cs('z-30 fixed w-full', className)} onMouseLeave={() => setOpenedSubmenu(-1)}>
      <div className="relative w-full bg-white border-b">
        <nav
          role="navigation"
          aria-label="Menu principal"
          className={cs(
            'container flex items-stretch justify-between px-2 mx-auto xl:px-0',
            styles.header__container
          )}
        >
          <Link to="/" className={cs("py-3 xl:pr-16 flex-grow", styles.p_custom)}>
            {logo.image.childImageSharp && (
              <GatsbyImage
                alt={logo.alt}
                style={{ display: 'inherit' }}
                className="hidden w-48 h-16 lg:block"
                {...logo.image.childImageSharp}
                placeholderClassName="hidden"
              />
            )}
            {mobileLogo.image.childImageSharp && (
              <GatsbyImage
                alt={mobileLogo.alt}
                style={{ display: 'inherit' }}
                className="w-16 h-16 lg:hidden"
                {...mobileLogo.image.childImageSharp}
                placeholderClassName="hidden"
              />
            )}
          </Link>

          <ul className="flex items-center ml-auto text-sm font-medium text-center font-brand text-purple-dark-500 flex-shrink">
            {menu.map((menuItem, menuItemIndex) => (
              <li className={cs('h-full', styles.menu__item)} key={`menuItem_${menuItemIndex}`}>
                {menuItem.children.length ? (
                  <>
                    <button
                      aria-expanded={menuItemIndex === openedSubmenu}
                      onClick={() => {
                        openedSubmenu === menuItemIndex ? setOpenedSubmenu(-1) : setOpenedSubmenu(menuItemIndex)
                        setProfileOpen(false)
                        sendClicZonePageAnalytics({
                          buttonLabel: menuItem.item.label,
                          zoneCategory: 'header'
                        })
                      }}
                      className={cs(
                        'btn-link flex items-center h-full mx-2 font-bold leading-none border-b-4 border-transparent cursor-pointer hover:border-pink-500 focus:border-pink-500 whitespace-pre',
                        { 'border-pink-500': menuItemIndex === openedSubmenu }
                      )}
                    >
                      {menuItem.item.label}
                      <Icon
                        className={cs("inline text-orange-500 stroke-current ml-2", styles.header__menu_animation, { 'transform rotate-180': menuItemIndex === openedSubmenu })}
                        name={ICONS.ArrowMenu}
                        width="10"
                        height="15" />
                    </button>
                    <div
                      className={cs('absolute w-full bg-white left-0 border-b border-gray-200', {
                        hidden: openedSubmenu !== menuItemIndex
                      })}
                    >
                      <div className={cs("container p-0 flex flew-row mx-auto py-4", styles.containerSubMenu)}>
                        <div className={cs("text-left border-r-2", styles.description)}>
                          <Button
                            variant={BUTTON_VARIANTS.LINK}
                            target={menuItem.item.link.target}
                            href={menuItem.item.link.href}
                            ariaLabel={menuItem.item.label}
                            analyticsEventName={IAnalyticsEvent.CLIC_CTA}
                            analyticsDataLayer={{
                              buttonLabel: menuItem.item.label
                            }}
                            onClick={() => {
                              setOpenedSubmenu(-1)
                            }}
                          >
                            <span className="text-xl text-left">{menuItem.item.label}</span>
                          </Button>
                          <p className="text-dark-grey-100 text-left font-sans mt-4 pr-3">{menuItem.item.link.description}</p>
                        </div>
                        <ul className="flex flex-wrap flex-grow text-sm xl:w-3/5">
                          {menuItem.children.map((submenu, index) => (
                            <React.Fragment key={`menuItem_${menuItemIndex}_subItem_${index}`}>
                              <li className={cs("w-1/3 px-5 py-2 pt-0 text-left", { 'pt-6': index >= 3 })}>
                                <Button
                                  variant={BUTTON_VARIANTS.LINK}
                                  target={submenu.item.link.target}
                                  href={submenu.item.link.href}
                                  ariaLabel={submenu.item.label}
                                  onClick={() => {
                                    setOpenedSubmenu(-1)
                                  }}
                                  analyticsEventName={IAnalyticsEvent.CLIC_CTA}
                                  analyticsDataLayer={{
                                    buttonLabel: submenu.item.label
                                  }}
                                >
                                  <span className={cs("font-bold block text-purple-dark-500 pl-2", styles.header__submenu_text)}>
                                    {submenu.item.label}
                                  </span>
                                </Button>
                                <ul className="pt-2">
                                  {submenu.children.map((subitem, subindex) => (
                                    <li className="pt-1 text-left" key={`menuItem_${menuItemIndex}_subItem_${index}_link_${subindex}`}>
                                      <Button
                                        onMouseEnter={() => {
                                          setOpenedSubmenu(menuItemIndex)
                                        }}
                                        variant={BUTTON_VARIANTS.LINK}
                                        target={subitem.item.link.target}
                                        href={subitem.item.link.href}
                                        ariaLabel={subitem.item.label}
                                        analyticsEventName={IAnalyticsEvent.CLIC_CTA}
                                        analyticsDataLayer={{
                                          buttonLabel: submenu.item.label + "_" + subitem.item.label
                                        }}
                                        className="inline-block	">
                                        <Icon
                                          className="inline text-pink-500 float-left transform scale-50 stroke-current stroke-1 rotate-180"
                                          name={ICONS.Chevron}
                                          width="24"
                                          height="24" />
                                        <span className={cs("inline align-middle font-sans font-normal flex", styles.alignsub)}>{subitem.item.label}</span>
                                      </Button>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                              {(index + 1) % 3 === 0 && <li className="flex-basis" />}
                            </React.Fragment>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <Button
                    className="flex items-center justify-between h-full leading-none"
                    variant={BUTTON_VARIANTS.LINK}
                    target={menuItem.item.link.target}
                    href={menuItem.item.link.href}
                    ariaLabel={menuItem.item.label}
                    onMouseEnter={() => {
                      setOpenedSubmenu(menuItemIndex)
                    }}
                    analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                    analyticsDataLayer={{
                      buttonLabel: menuItem.item.label,
                      zoneCategory: 'header'
                    }}
                  >
                    <span
                      className={cs(
                        'flex items-center h-full mx-2 border-b-4 border-transparent cursor-pointer hover:border-pink-500 whitespace-pre',
                        { 'border-pink-500': menuItemIndex === openedSubmenu }
                      )}
                    >
                      {menuItem.item.label}
                    </span>
                  </Button>
                )}
              </li>
            ))}
          </ul>

          <div
            className="relative flex flex-col justify-center ml-5 flex-grow"
          >
            <button
              onClick={() => {
                setProfileOpen(!isProfileOpen)
                setOpenedSubmenu(-1)
              }}
            >
              <div className="flex flex-inline items-center min-w-max">
                <img width="24" src={(profile as unknown) as string} alt="J'accède à mon espace" />
                <span className="p-2 font-bold text-pink-500 whitespace-pre hidden xl:block">Espace client
                  <Icon
                    className={cs("inline text-orange-500 stroke-current ml-2", styles.header__menu_animation, { 'transform rotate-180': isProfileOpen })}
                    name={ICONS.ArrowMenu}
                    width="10"
                    height="15" />
                </span>
              </div>
            </button>
            {isProfileOpen && (
              <div
                id="profile__tooltip-menu__backdrop"
                className="fixed top-0 left-0 w-screen h-screen"
                onClick={() => {
                  setProfileOpen(false)
                  setOpenedSubmenu(-1)
                }}
              ></div>
            )}
            <div
              id="profile__tooltip-menu"
              className={cs(
                'absolute right-0 bg-white border border-gray-100 rounded-lg shadow-lg',
                { hidden: !isProfileOpen }
              )}
            >
              <div
                id="profile__tooltip-menu__needle"
                className="absolute w-3 h-3 bg-white border-t border-l border-gray-100"
              />
              <div className="py-2 px-4">
                {buttons.map((button, bcIndex) => (
                  <Button
                    key={bcIndex}
                    className="flex items-center py-1 text-xs"
                    variant={BUTTON_VARIANTS.LINK}
                    iconPosition={ICON_POSITIONS.LEFT}
                    iconClassname="text-orange-500"
                    target={button.item.link.target}
                    href={button.item.link.href}
                    ariaLabel={button.item.label}
                    onMouseEnter={() => {
                      setOpenedSubmenu(-1)
                    }}
                    onClick={() => {
                      setProfileOpen(false)
                    }}
                    analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                    analyticsDataLayer={{
                      buttonLabel: button.item.label,
                      zoneCategory: 'header'
                    }}
                  >
                    {button.item.label}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}
