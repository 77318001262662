/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllOffresPackageesQuery, IParagraph__Offres_Packagees } from '../../graphql.types'
import { IColor } from '../../types/gatsby/colors'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_couleur_de_fond,
  field_plain_description,
  field_libelle_onglet_vision_glob,
  relationships
}: IParagraph__Offres_Packagees): IProps => {
  return {
    title: _('title', field_title),
    backgroundColor: field_couleur_de_fond as IColor,
    description: _('description', field_plain_description),
    libelleOnglet: _('onglet', field_libelle_onglet_vision_glob),
    liste: _('listItems',
      relationships?.field_list_items?.map(card => ({
        titre: card?.field_title,
        description: card?.field_plain_description,
        marqueurPrix: card?.field_marqueur_prix,
        listeDeText: {
          titre: card?.relationships?.field_liste_texte?.field_title,
          liste: card?.relationships?.field_liste_texte?.field_liste_de_texte
        },
        population: card?.relationships?.field_population,
        image: {
          alt: card?.field_image?.alt,
          ..._('image', card?.relationships?.field_image?.localFile?.childImageSharp)
        },
        bouton: {
          link: {
            href:
              card?.relationships?.field_button?.field_link?.uri_alias ||
              card?.relationships?.field_button?.field_link?.uri
          },
          title: _('title', card?.relationships?.field_button?.field_title),
          target: card?.relationships?.field_button?.field_link_target,
          action: card?.relationships?.field_button?.field_button_action_id
        }
      }))
    ),
  }
}

const Query = () =>
  useStaticQuery<IAllOffresPackageesQuery>(graphql`
  query AllOffresPackagees {
    data: allParagraphOffresPackagees {
      edges {
        node {
          drupal_id
          field_plain_description
          field_title
          field_couleur_de_fond
          drupal_internal__revision_id
          relationships {
            field_list_items {
              field_title
              field_plain_description
              field_marqueur_prix
              relationships {
                field_button {
                  field_title
                  field_link_target
                  field_link {
                    uri
                    uri_alias
                  }
                }
                field_image {
                  localFile {
                    childImageSharp {
                      fluid(maxHeight: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                field_liste_texte {
                  field_title
                  field_liste_de_texte
                }
                field_population {
                  name
                }
              }
            }
          }
          field_libelle_onglet_vision_glob
        }
      }
    }
  }  
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
