import { object, string } from 'yup'

export const NUMBERPHONE_REGEX = /^((\+)33|0)[1-9](\d{2}){4}$/
export const CODEPOSTAL_REGEX = /^(([0-95]{2}|2A|2B)[0-9]{3})$|^[971-976]$/

export const isPhoneNumber = (value: string) => NUMBERPHONE_REGEX.test(value)
export const isCodePostal = (value: string) => CODEPOSTAL_REGEX.test(value)

export default object().shape(
  {
    lastname: string().required('Le nom est obligatoire'),
    firstname: string().required('Le prénom est obligatoire'),
    email: string().required('L\'adresse email est obligatoire').email("L'adresse email n'est pas valide"),
    telephone: string().required('Le numéro de téléphone est obligatoire').test({
      name: 'numberPhone',
      message: 'Veuillez entrer un numéro de téléphone valide',
      test: value => !value || isPhoneNumber(value)
    }),
    companyName: string().required('La raison sociale est obligatoire'),
    codePostal: string().required('Le code postal est obligatoire').test({
      name: 'codePostal',
      message: 'Le code postal n\'est pas valide',
      test: value => !value || isCodePostal(value)
    }),
  },
)
