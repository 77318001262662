import React, { useState } from 'react'
import cs from 'classnames'
import Icon, { ICONS } from '../../Icon'

export default function LegalMentionsAccordion() {
  const [visible, setVisible] = useState(false)

  return (
    <section className="py-4 bg-green-dark-100">
      <div className="container mx-auto">
        <hr className="mb-8 border-gay-800" />

        <button className="flex" onClick={() => setVisible(!visible)}>
          <span className="md:text-sm">Conditions légales</span>
          <Icon
            className={cs('transform scale-50 rotate-180', { '-rotate-90': visible })}
            name={ICONS.Chevron}
            width="24px"
            height="24px"
          />
        </button>

        <p className={cs('italic mt-4', { hidden: !visible, block: visible })}>
          Vos données sont utilisées par LMG Conseil et Services afin de lui permettre de répondre à
          votre demande et vous proposer des services adaptés à vos besoins. LMG Conseil et Services
          sera amenée à transférer vos données personnelles à La Mutuelle Générale à des fins de
          prospection commerciale.
          <br />
          <br />
          Vous disposez de droits sur vos données. Nous vous invitons à consulter notre politique en
          matière de protection des données pour en savoir plus.
        </p>
      </div>
    </section>
  )
}
