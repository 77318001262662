import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import { navigate } from 'gatsby-link'
import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import breakpoints from '../../assets/css/breakpoints'
import Button, {
  BUTTON_VARIANTS,
  ICON_POSITIONS
} from '../../components/presentationals/Button/Button'
import Icon, { ICONS } from '../../components/presentationals/Icon'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IDrupalButton, IDrupalLink } from '../../types/drupal'
import { ILink } from '../../types/drupal/index'
import { IColor } from '../../types/gatsby/colors'
import { IImage } from '../../types/gatsby/images'
import { IAnalyticsEvent, sendClicCTAAnalytics, sendClicZonePageAnalytics, sendSocialShareAnalytics } from '../../utils/analytics/analytics'
import { SlideCustomArrow } from '../../utils/paragraphs/SlideCustomArrow'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'
import style from './Component.module.css'

interface ICard {
  /** The card's image */
  image: IImage
  /** Boolean which define if the services is new */
  isNew: boolean
  chronometre: string;
  thematique: string;
  /** Sur-titre de la carte */
  surTitre: string
  /** The card's title */
  title: string
  /** Cible de la carte */
  cible: string
  /** Type d'offre de la carte */
  typeOffre: string
  /** The card's */
  description: string
  /** The button at the bottom of the card */
  button: IDrupalButton
  /** The optional social media links */
  socialMediaLinks: {
    icon: IImage
    link: ILink
  }[],
}

export interface IProps {
  // A color to apply to the background - It needs to be one key of tailwind colors
  backgroundColor?: IColor
  /** The title that will be displayed using an H1 */
  title: string
  //Description ajouté en dessous du titre dans un <h3>
  description: string

  button?: IDrupalLink
  /** The list of elements to display */
  list: ICard[]
  /** Taille des cartes null|Small(Petite)|Tall(Grande)*/
  tailleCartes: string
}

export default function Component({
  backgroundColor = 'orange',
  title,
  button,
  list,
  tailleCartes,
  description
}: IProps) {
  const isDesktopLayout = useBreakpoint(breakpoints.lg)
  const SLIDER_CONFIG = {
    dots: true,
    infinite: false,
    arrows: isDesktopLayout,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SlideCustomArrow />,
    prevArrow: <SlideCustomArrow isPrev={true} />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerMode: true
        }
      }
    ]
  }
  const isNotMobile = useBreakpoint(breakpoints.sm);
  useEffect(() => {
    const test = document.getElementsByClassName('slick-list')

    const sliderCard = document.getElementsByClassName('slider-card')

    Array.prototype.forEach.call(sliderCard, function (sc) {
      sc.getElementsByClassName('slick-list')[0].style.padding = 'inherit';
      if (!isNotMobile) {
        sc.getElementsByClassName('slick-list')[0].style.padding = '0px 50px';
      }
    })

    Array.prototype.forEach.call(test, function (sl) {
      sl.style.display = 'flex'

      sl.getElementsByClassName('slick-track')[0].style.display = 'flex'
      Array.prototype.forEach.call(sl.getElementsByClassName('slick-track')[0].children, function (st) {
        st.style.height = 'inherit'
        st.firstChild.style.height = '100%'
      })

    })
  })
  return (
    <section
      className={cs(
        'bg-circle bg-circle-bottom bg-circle-right',
        `bg-${backgroundColor}-100`,
        `bg-circle-${backgroundColor}`
      )}
    >
      <div className="slider-card w-full px-0 pt-16 pb-8 mx-auto container md:px-4">
        <h2 className={cs("px-4 mb-10 text-3xl font-medium leading-none text-center sm:text-3xl font-brand text-purple-dark-500 ", style.title)}>
          {title}
        </h2>

        {description && <h3 className="px-8 w-11/12 md:w-10/12 md:px-0 m-auto md:text-xl mb-10 leading-5 text-dark-grey-100 text-center">{description}</h3>}

        <Slider className={cs('custom-slick-slider md:mx-auto pb-10 md:pb-1', style.slider)} {...SLIDER_CONFIG}>
          {list.map((card, index) => (
            <div key={index} className={cs('focus:outline-none h-full', style.card)} >
              <div
                className={cs('flex-1 h-full w-full overflow-hidden bg-white rounded-lg shadow-sm relative', {
                  'border border-gray-200 shadow-lg':
                    !backgroundColor || backgroundColor === 'white'
                }, card.chronometre ? 'bg-gray-100' : '')}
              >
                <div
                  className={cs(card.button.link?.href ? style.card__cursor : '', style.card__content)}
                  onClick={(e: any) => {
                    if (e.target?.dataset?.type === IAnalyticsEvent.CLIC_ZONE_PAGE ||
                      e.target?.parentElement?.dataset?.type === IAnalyticsEvent.CLIC_ZONE_PAGE) {
                      sendClicZonePageAnalytics({
                        buttonLabel: card.button.title,
                        zoneCategory: 'body'
                      })
                    } else {
                      if (card.button.link?.href) {
                        sendClicCTAAnalytics({
                          targetUrl: card.button.link?.href,
                          buttonLabel: card.button.title
                        })
                      }
                    }
                    navigate(card.button.link?.href || '')
                  }}>
                  <GatsbyImage className="h-40" {...card.image} />
                  {card.chronometre &&
                    <div className={cs('flex', style.timer)}>
                      <Icon
                        name={ICONS.Timer}
                        width="16"
                        height="24"
                        className='mr-1 fill-current text-orange-500 float-left ml-3 mr-0'
                      />
                      <span className="ml-1 align-middle">{card.chronometre}'</span>
                    </div>}
                  <div className={cs("flex flex-col p-4 pb-3 text-purple-dark-500 pt-5", style.container)}>
                    <div className={cs(style.cardContainer, card.button.link?.href ? 'mb-8 relative pt-5' : 'relative pt-5')}>
                      {(card.isNew || card.thematique) &&
                        <p className={cs("text-pink-500 text-xs font-extrabold mb-1 absolute top-0 ", style.nouveau, card.thematique ? 'uppercase' : '')}>
                          {card.isNew && "NOUVEAU"} {card.thematique && card.thematique}
                        </p>
                      }
                      {card.surTitre && <h4 className={cs("text-pink-500 text-xs font-extrabold leading-4 uppercase", style.font_size12, (card.isNew || card.thematique) ? '' : 'absolute top-0')}>{card.surTitre}</h4>}
                      <h3
                        className={cs(
                          'text-lg font-bold leading-none sm:text-xl md:text-lg lg:text-xl font-brand mb-2 mt-2',
                          style.card__title
                        )}
                      >
                        {card?.title}
                      </h3>
                      {tailleCartes != 'Small' && <div className={cs('w-8 h-1 my-4 bg-orange-500 rounded')}></div>}
                      <div className="mb-4">
                        {card.cible && (
                          <div className={cs('mr-2', style.fond_gris_rond)}>
                            <p className={cs(style.texte_bulle)}>{card.cible}</p>
                          </div>
                        )}
                        {card.typeOffre && (
                          <div className={cs(style.fond_gris_rond)}>
                            <p className={cs(style.texte_bulle)}>{card.typeOffre}</p>
                          </div>
                        )}
                      </div>
                      <p
                        className={cs(
                          'font-sans text-sm leading-normal text-dark-grey-100 md:text-base mb-6',
                          style.card__description
                        )}
                      >
                        {card?.description}
                      </p>
                    </div>
                    {card.button.link?.href &&
                      <div className={cs("container-button absolute bottom-0 left-0 p-4 w-full")}>
                        < Button
                          className={cs("items-center w-full text-center", card.button.style === null || card.button.style === 'Défaut' || card.button.style === 'Link' ? 'flex' : style.block)}
                          iconPosition={card.button.style === null || card.button.style === 'Défaut' || card.button.style === 'Link' ? ICON_POSITIONS.LEFT : undefined}
                          iconClassname="text-orange-500 mr-4 -ml-2"
                          analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                          analyticsDataLayer={{
                            buttonLabel: card.button.title,
                            zoneCategory: 'body'
                          }}
                          {...card.button}
                          {...(card.button.link?.href
                            ? buttonPropsFormatter({
                              ...card.button,
                              link: undefined,
                              action: 'do-nothing'
                            })
                            : buttonPropsFormatter({ ...card.button }))}
                          variant={!card.button.style || card.button.style === 'Défaut' ? BUTTON_VARIANTS.LINK : card.button.style === "PRIMARY" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
                        >
                          <span className="-m-4 text-base leading-none md:text-base">
                            {card.button.title}
                          </span>
                        </Button>
                      </div>}
                    {card.socialMediaLinks.length > 0 && (
                      <div className="flex justify-end absolute bottom-0 right-0 p-5 pb-5">
                        {card.socialMediaLinks.map(social => (
                          <a
                            key={social.link.href}
                            {...social.link}
                            className="h-8 ml-2"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              sendSocialShareAnalytics({ targetUrl: social.link.href })
                            }
                          >
                            <GatsbyImage {...social.icon} />
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {
          button && (
            <div className="flex justify-center text-center mt-16">
              <Button
                analyticsDataLayer={{
                  buttonLabel: button.title
                }} {...buttonPropsFormatter(button)} />
            </div>
          )
        }
      </div >
    </section >
  )
}
