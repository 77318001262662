import React, { useState } from 'react'

import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import createMailto from '../../utils/uri/createMailTo'
import style from './Component.module.css'

export interface IProps {
  subject?: string
  body?: string
}

const DEFAULT_BODY = ''
const DEFAULT_SUBJECT = ''

export default function Component({ subject = DEFAULT_SUBJECT, body = DEFAULT_BODY }: IProps) {
  const [email, setEmail] = useState('')

  return (
    <section className="my-16 bg-white">
      <div className="container mx-auto text-center">
        <div className="w-full mx-auto lg:w-2/3">
          <h2 className="mx-auto mb-2 text-xl font-medium leading-none font-brand text-purple-dark-500">
            Vous travaillez dans une entreprise n’ayant pas encore souscrit à Flex ?
          </h2>
          <p className="mb-6 text-lg leading-normal text-center text-gray-400">
            Partagez ce site avec votre DRH ou votre employeur.
          </p>
          <div className="flex mx-auto md:w-2/3">
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="w-full h-12 px-6 py-3 text-sm italic placeholder-gray-400 border border-r-0 rounded-l focus:placeholder-gray-200 border-1"
              placeholder="Adresse email"
            />
            <Button
              className={style.button}
              variant={BUTTON_VARIANTS.PRIMARY}
              href={createMailto(email, subject, body)}
              target="_blank"
              ariaLabel="Envoyer"
              analyticsDataLayer={{
                buttonLabel: "Envoyer"
              }}
            >
              Envoyer
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
