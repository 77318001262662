import { useState, useLayoutEffect } from 'react'
import { throttle } from 'lodash'

// adapted to our needs from https://medium.com/better-programming/usebreakpoint-hook-get-media-query-breakpoints-in-react-3f1779b73568
const useBreakpoint = (threshold: number) => {
  const [matches, setMatches] = useState(false)

  useLayoutEffect(() => {
    const calcInnerWidth = throttle(function() {
      setMatches(window.innerWidth >= threshold)
    }, 200)

    // call on first render to make sure of initial value of 'matches'
    calcInnerWidth()

    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return matches
}
export default useBreakpoint
