/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllTabbableListsQuery, IParagraph__Tabbable_Lists } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({ field_title, relationships }: IParagraph__Tabbable_Lists): IProps => {
  return {
    title: _('title', field_title),
    tabs: _('tabs', relationships?.field_tabbable_lists_items).map(tab => ({
      tabLabel: _('label', tab?.field_tab_label),
      title: tab?.field_title,
      list: _('list', tab?.relationships?.field_list_items).map(item => ({
        image: {
          alt: _('alt', item?.field_image?.alt),
          ..._('image', item?.relationships?.field_image?.localFile?.childImageSharp)
        },
        title: item?.field_title,
        description: item?.field_plain_description,
        description_formattee: item?.field_rich_description?.value
      }))
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllTabbableListsQuery>(graphql`
    query AllTabbableLists {
      data: allParagraphTabbableLists {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            relationships {
              field_tabbable_lists_items {
                field_title
                field_tab_label
                relationships {
                  field_list_items {
                    field_title
                    field_plain_description
                    field_rich_description {
                      value
                    }
                    field_image {
                      alt
                    }
                    relationships {
                      field_image {
                        localFile {
                          childImageSharp {
                            fixed(height: 128) {
                              ...GatsbyImageSharpFixed_withWebp
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
