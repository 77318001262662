/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllPacksPromoQuery, IParagraph__Packs_Promo } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_service_link,
  relationships
}: IParagraph__Packs_Promo): IProps => {
  const { field_packs, field_pack_focus, field_button } = _('relationship', relationships)

  return {
    title: _('title', field_title),
    description: _('description', field_plain_description),
    serviceLink: _('serviceLink', field_service_link),
    packs: _('packs', field_packs).map(pack => ({
      identifier: _('identifier', pack?.field_identifier),
      name: _('name', pack?.title),
      description: _('description', pack?.field_raw_description),
      subPacks: _('subpack', pack?.relationships?.field_subpacks).map(subpack => ({
        name: _('name', subpack?.field_title),
        image: {
          alt: _('alt', subpack?.field_image?.alt),
          ..._('image', subpack?.relationships?.field_image?.localFile?.childImageSharp)
        },
        price: subpack?.field_price,
        libellePrice: subpack?.field_libelle_prix_ligne_1,
        price2: subpack?.field_prix_ligne_2,
        libellePrice2: subpack?.field_libelle_prix_ligne_2,
        mentionSalarie: _('mentionSalarie', subpack?.field_included),
        marquerPrix: _('marquerPrix', subpack?.field_marqueur_prix),
        services: _('services', subpack?.relationships?.field_services).map(service => ({
          name: _('name', service?.relationships?.field_service?.title),
          included: _('included', service?.field_included),
          link: {
            link: {
              href: _('href', service?.relationships?.field_service?.path?.alias)
            },
            target: '_self',
            title: _('title', service?.relationships?.field_service?.title)
          }
        })),
        boutonSousPack: {
          link: {
            href:
              subpack?.relationships?.field_button?.field_link?.uri_alias ||
              subpack?.relationships?.field_button?.field_link?.uri
          },
          title: subpack?.relationships?.field_button?.field_title,
          target: subpack?.relationships?.field_button?.field_link_target,
          action: subpack?.relationships?.field_button?.field_button_action_id
        }
      }))
    })) as [any],
    focusedPackIdentifier: field_pack_focus?.field_identifier,
    button: {
      link: {
        href: field_button?.field_link?.uri_alias || field_button?.field_link?.uri
      },
      title: _('title', field_button?.field_title),
      target: field_button?.field_link_target,
      action: field_button?.field_button_action_id,
      style: _('style', field_button?.field_style)
    }
  }
}

const Query = () =>
  useStaticQuery<IAllPacksPromoQuery>(graphql`
    query AllPacksPromo {
      data: allParagraphPacksPromo {
        edges {
          node {
            id
            drupal_internal__revision_id
            relationships {
              field_packs {
                field_identifier
                field_raw_description
                title
                breadcrumb {
                  title
                  uri
                }
                relationships {
                  field_subpacks {
                    field_image {
                      alt
                    }
                    field_price
                    field_included
                    field_marqueur_prix
                    field_title
                    relationships {
                      field_image {
                        localFile {
                          childImageSharp {
                            fixed(height: 148) {
                              ...GatsbyImageSharpFixed_withWebp
                            }
                          }
                        }
                      }
                      field_services {
                        field_included
                        relationships {
                          field_service {
                            title
                            path {
                              alias
                            }
                          }
                        }
                      }
                      field_button {
                        field_title
                        field_link_target
                        field_link {
                          uri_alias
                          uri
                        }
                        field_button_action_id
                      }
                    }
                    field_prix_ligne_2
                    field_libelle_prix_ligne_1
                    field_libelle_prix_ligne_2
                  }
                }
              }
              field_pack_focus {
                field_identifier
              }
              field_button {
                field_title
                field_link_target
                field_link {
                  uri_alias
                  uri
                }
                field_button_action_id
                field_style
              }
            }
            field_title
            field_service_link
            field_plain_description
            field_service_link
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
