import { useEffect, useState, useCallback } from 'react'

// Taken and adapted to our needs from https://github.com/streamich/react-use/blob/master/src/useSessionStorage.ts
export function useSessionStorage<T>(
  key: string,
  initialValue?: T
): [T | undefined, (value: T) => void, () => void] {
  if (typeof sessionStorage === 'undefined') {
    return [initialValue as T, () => {}, () => {}]
  }

  const [state, setState] = useState<T | undefined>(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key)
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(key, JSON.stringify(initialValue))
        return initialValue
      } else {
        return JSON.parse(sessionStorageValue || 'null')
      }
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // cat throw, too.
      return initialValue
    }
  })

  const reset = useCallback(() => {
    setState(initialValue)
  }, [initialValue])

  useEffect(() => {
    try {
      const serializedState = JSON.stringify(state)
      sessionStorage.setItem(key, serializedState)
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. Also JSON.stringify can throw.
    }
  })

  return [state, setState, reset]
}
