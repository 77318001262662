import { IContext, SESSION_STORAGE_KEY } from '../../machines/autodiagnostic/constants'
import getCompanyTypeFromSize from './getCompanyTypeFromSize'
const getAnalytics = () => (window as CustomWindow).dataLayer

export interface IAnalyticsDataLayer {
  event?: IAnalyticsEvent
  autodiagnosticActions?: string // ie: enjeux.takenActions (concatenated)
  autodiagnosticFamilies?: string // ie: enjeux.employeesWithChild
  autodiagnosticLevel?: string // ie: enjeux.workLifeQuality
  autodiagnosticStakes?: string // ie: enjeux.mainIssues (concatenated)
  buttonLabel?: string
  espaceFlex?: 'public' | 'entreprise' | 'adhérent' | 'tunnel'
  companySize?: string // ie: entreprise.companyId.trancheEffectifsEtablissement
  companyType?: 'TPE' | 'PME' | 'ETI' // ie: TPE si <= 15, PME si >= 16 et <= 49, ETI si >= 50
  page?: string // ie: URL of the page
  virtualPagename?: string // ie. example: autodiagnostic/etape1
  zoneCategory?: string
  gclid?: string
  offerName?: string
  pageName?: string
  scrollPercentage?: string
  targetUrl?: string
  userId?: string
  popinName?: string
}

export enum IAnalyticsEvent {
  VIRTUAL_PAGE_VIEW = 'virtualPageview',
  CLIC_ZONE_PAGE = 'clic_zone_page',
  RECO_AUTODIAGNOSTIQUE = 'recoAutodiagnostique',
  ME_RAPPELER = 'meRappeler',
  ICONE_RAPPEL = 'iconeRappel',
  CLIC_CTA = 'clic_cta',
  CLIC_CTA_APPEL = 'clicCtaAppel',
  CLIC_CTA_HEADER = 'clicCtaHeader',
  PARTAGE_SOCIAL = 'partageSocial',
  POPIN_DISPLAY = 'popinDisplay',
  POPIN_CLICK = 'popinClick',
  SCROLL = 'Scroll'
}

function forgeDataLayer(
  event: IAnalyticsEvent | undefined,
  {
    page = `${document.location.pathname}`,
    espaceFlex = 'public',
    ...dataLayer
  }: IAnalyticsDataLayer
): IAnalyticsDataLayer {
  let ssAutodiagnostique: IContext | null = null

  try {
    ssAutodiagnostique = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) ?? '{}')
  } catch {
    // If user is in private mode or has storage restriction, sessionStorage can throw.
    // JSON.parse and JSON.stringify can throw, too
  }

  return {
    event,
    page,
    espaceFlex,

    // first checking in data layer to allow overriding
    // then in sessionStorage
    // or else setting undefined
    autodiagnosticLevel:
      dataLayer.autodiagnosticLevel ?? ssAutodiagnostique?.enjeux?.workLifeQuality ?? undefined,
    autodiagnosticStakes:
      dataLayer.autodiagnosticStakes ??
      ssAutodiagnostique?.enjeux?.mainIssues?.join(', ') ??
      undefined,
    autodiagnosticActions:
      dataLayer.autodiagnosticActions ??
      ssAutodiagnostique?.enjeux?.takenActions?.join(', ') ??
      undefined,
    autodiagnosticFamilies:
      dataLayer.autodiagnosticFamilies ??
      ssAutodiagnostique?.enjeux?.employeesWithChild?.toString() ??
      undefined,
    companySize:
      dataLayer.companySize?.toString() ??
      ssAutodiagnostique?.entreprise?.companyId?.trancheEffectifsEtablissement?.toString() ??
      undefined,
    userId: dataLayer.userId?.toString() ?? undefined,
    companyType:
      getCompanyTypeFromSize(Number(dataLayer.companySize)) ??
      getCompanyTypeFromSize(
        ssAutodiagnostique?.entreprise?.companyId?.trancheEffectifsEtablissement
      ) ??
      undefined,
    offerName: ssAutodiagnostique?.enjeux?.employeesWithChild
      ? ssAutodiagnostique?.enjeux?.employeesWithChild < 50
        ? 'Pack Flex Santé Bien-être'
        : 'Pack Flex Famille'
      : undefined,

    ...dataLayer // will override previous null values if needed
  }
}

function sendAnalytics(event: IAnalyticsEvent, dataLayer: IAnalyticsDataLayer) {
  const analytics = getAnalytics()
  const finalDataLayer = forgeDataLayer(event, dataLayer)

  // for debug purposes
  // eslint-disable-next-line no-console
  console.debug('dataLayer: ', finalDataLayer)


  //if (analytics) analytics(window, 'trackEvent', finalDataLayer)
  analytics.push(finalDataLayer)
}

export function setPageViewTcVars(dataLayer: IAnalyticsDataLayer = {}) {
  const finalDataLayer = forgeDataLayer(undefined, dataLayer)

  // for debug purposes
  // eslint-disable-next-line no-console
  console.debug('tc_vars: ', finalDataLayer)

  // eslint-disable-next-line @typescript-eslint/camelcase
  return ((window as CustomWindow).tc_vars = finalDataLayer)
}

export function sendVirtualPageViewAnalytics(
  dataLayer: IAnalyticsDataLayer = {},
  eventName: IAnalyticsEvent = IAnalyticsEvent.VIRTUAL_PAGE_VIEW
) {
  sendAnalytics(eventName, dataLayer)
}

export function sendClicZonePageAnalytics(
  dataLayer: IAnalyticsDataLayer = {},
  eventName: IAnalyticsEvent = IAnalyticsEvent.CLIC_ZONE_PAGE
) {
  sendAnalytics(eventName, dataLayer)
}

export function sendIAdvizeModalOpeningAnalytics(
  dataLayer: IAnalyticsDataLayer = {},
  eventName: IAnalyticsEvent = IAnalyticsEvent.ME_RAPPELER
) {
  sendAnalytics(eventName, dataLayer)
}

export function sendCallNumberAnalytics(dataLayer: IAnalyticsDataLayer = {}) {
  sendAnalytics(IAnalyticsEvent.ICONE_RAPPEL, dataLayer)
}

export function sendClicCTAAnalytics(
  dataLayer: IAnalyticsDataLayer = {},
  eventName: IAnalyticsEvent = IAnalyticsEvent.CLIC_CTA
) {
  sendAnalytics(eventName, dataLayer)
}

export function sendPopinDisplayAnalytics(
  dataLayer: IAnalyticsDataLayer = {},
  eventName: IAnalyticsEvent = IAnalyticsEvent.POPIN_DISPLAY
) {
  sendAnalytics(eventName, dataLayer)
}

export function sendPopinClickAnalytics(
  dataLayer: IAnalyticsDataLayer = {},
  eventName: IAnalyticsEvent = IAnalyticsEvent.POPIN_CLICK
) {
  sendAnalytics(eventName, dataLayer)
}

export function sendSocialShareAnalytics(dataLayer: IAnalyticsDataLayer = {}) {
  sendAnalytics(IAnalyticsEvent.PARTAGE_SOCIAL, dataLayer)
}

export function sendScrollAnalytics(dataLayer: IAnalyticsDataLayer = {}) {
  sendAnalytics(IAnalyticsEvent.SCROLL, dataLayer)
}
