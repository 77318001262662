import { IStep } from '../../components/presentationals/AutoDiagnostic/Steps/Steps'
import { IApiEndpoints } from '../../hooks/useApiEndpoints'

export const SESSION_STORAGE_KEY = 'flex__autodiagnostique'

export const SERVICES = {
  SEND: 'send'
}

export interface IContext {
  apiEndpoints: IApiEndpoints
  enjeux: {
    workLifeQuality: string | undefined
    mainIssues: string[] | undefined
    takenActions: string[] | undefined
    employeesWithChild: number | undefined
  }
  entreprise: {
    companyForm: ICompanyForm
    companyList: ICompany[] | undefined
    companyId: ICompany | undefined
    invokeError: string | undefined
    companyContact: IContactForm
  }
}

export interface IEvent<T> {
  type: string
  data?: T
}

export const STEPS_META: IStep[] = [
  {
    number: 1,
    title: 'Vos enjeux',
    subtitle:
      'Mieux vous connaître, c’est pouvoir vous conseiller le pack Flex le mieux adapté à vos besoins. Quelques minutes suffisent pour répondre à ces  questions&nbsp;:'
  },
  {
    number: 2,
    title: 'Votre entreprise',
    subtitle:
      'Pouvez-vous nous en dire plus sur votre entreprise pour nous permettre d’affiner notre recommandation et vous proposer le pack Flex idéal ?'
  },
  {
    number: 3,
    title: 'Notre recommandation',
    subtitle:
      'Voici le pack Flex qui nous semble correspondre le mieux à vos besoins. Avant de souscrire, n’hésitez pas à consulter les autres packs et services à la carte.'
  }
]

export interface ICompanyForm {
  siren: string
  companyName: string
  zipCode: string
}

export interface ICompany {
  siret: string
  uniteLegale: {
    denominationUniteLegale?: string
    prenom1UniteLegale: string
    nomUniteLegale: string
  }
  adresseEtablissement: {
    numeroVoieEtablissement: string
    typeVoieEtablissement: string
    libelleVoieEtablissement: string
    codePostalEtablissement: string
    libelleCommuneEtablissement: string
  }
  trancheEffectifsEtablissement?: number
}

export interface IContactForm {
  lastname: string
  firstname: string
  email: string
}

export const SIREN_404_ERROR =
  "Nous n'avons pu identifier votre entreprise dans la base Sirene.\nNous vous invitons à effacer votre saisie et compléter à nouveau les champs entreprise."
export const SIREN_DEFAULT_ERROR =
  "Nous avons rencontré un problème technique lors de l'appel à la base Sirene permettant d'identifier votre entreprise.\nNous vous invitons à effacer votre saisie et compléter à nouveau les champs entreprise ou recommencer ultérieurement l'identification de vos besoins."
