import React, { ReactNode } from 'react'
import cs from 'classnames'

import Icon, { ICONS } from '../../Icon/Icon'

interface IProps {
  className?: string
  editBtnDataTestId?: string
  onEdit: () => void
  children: ReactNode
}

const EditableQuestionSummary = ({ className, editBtnDataTestId, onEdit, children }: IProps) => {
  return (
    <div
      className={cs(
        'flex justify-between items-center rounded-lg bg-green-dark-500 px-5 py-6',
        className
      )}
    >
      <div className="text-white">{children}</div>

      <button
        className="flex items-center justify-center w-10 h-10 ml-4 text-4xl text-orange-500 bg-white rounded-full"
        onClick={onEdit}
        data-testid={editBtnDataTestId}
      >
        <Icon name={ICONS.EditButton} />
      </button>
    </div>
  )
}

export default EditableQuestionSummary
