/**
 * Same as Number.toFixed, but with a comma instead of a '.'
 * @param nb - The number to fix decimal of
 * @param decimalPlaces - The number of decimal to leave
 */
const toCommaFixed = (nb: number, decimalPlaces: number = 0) =>
  nb
    ?.toFixed(decimalPlaces)
    .split('.')
    .join(',')

export default toCommaFixed
