import { useMachine } from '@xstate/react'
import React from 'react'

import machine from '../../machines/autodiagnostic'
import Step1 from '../../machines/autodiagnostic/components/Step1/Step1'
import Step2 from '../../machines/autodiagnostic/components/Step2/Step2'
import Step3 from '../../machines/autodiagnostic/components/Step3/Step3'
import useApiEndpoints from '../../hooks/useApiEndpoints'
import { IDrupalButton } from '../../types/drupal/index'

export interface IProps {
  button?: IDrupalButton
}

export default function AutoDiagnostic({ button }: IProps) {
  const [state, send] = useMachine(machine, {
    context: {
      apiEndpoints: useApiEndpoints()
    }
  })

  return (
    <div>
      {state.matches('enjeux') && <Step1 state={state} send={send} />}
      {state.matches('entreprise') && <Step2 state={state} send={send} />}
      {state.matches('recommendation') && <Step3 state={state} send={send} button={button} />}
    </div>
  )
}
