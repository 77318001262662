/**
 * Commentted code breaks the storybook
 * @TODO find a way to uncomment
 */

// import tailwindConfig = from './tailwind.config.js'

// interface IBreakpoints {
//   sm: number
//   md: number
//   lg: number
//   xl: number
// }

// const getBreakpointValue = (breakpoint: string): number => {
//   const value: string = (tailwindConfig.theme.screens as Record<string, string>)[breakpoint]
//   return +value.replace('px', '')
// }

// export default Object.keys(tailwindConfig.theme.screens).reduce(
//   (acc, curr) => ({ ...acc, [curr]: getBreakpointValue(curr) }),
//   {}
// ) as IBreakpoints

export default {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
}
