/* eslint-disable @typescript-eslint/camelcase */

import packs_promo from './packs_promo'
import tabbable_lists from './tabbable_lists'
import cards_slider from './cards_slider'
import title_img_desc_button from './title_img_desc_button'
import hero_banner_with_video from './hero_banner_with_video'
import quotes_slider from './quotes_slider/'
import hero_banner_simple from './hero_banner_simple'
import buttons from './buttons'
import double_cards from './double_cards'
import ordered_desc_list from './ordered_desc_list'
import columned_desc_list from './columned_desc_list'
import spacer from './spacer'
import auto_diagnostic from './auto_diagnostic'
import mail_sharing from './mail_sharing'
import error, { ERROR } from './error'
import contact from './contact'
import offrePackagees from './package_offre'
import contactFloatingActionButton from './contact_floating_action_button'
import souscriptionFormulaire from './formulaire_souscription'
import blocMedia from './bloc_media'
import retourSouscription from './retour_soucription'
import miseEnAvantSousPack from './mise_en_avant_sous_pack'

interface IParagraphs {
  [key: string]: IParagraph
}

export interface IParagraph {
  Query?: () => {
    data: {
      edges: {
        node: any
      }[]
    }
  }
  propsFormatter: (content: any) => any
  Component: React.FC<any>
}

/**
 * Register new paragraphs here !
 */
const paragraphs: IParagraphs = {
  paragraph__packs_promo: packs_promo,
  paragraph__tabbable_lists: tabbable_lists,
  paragraph__contact: contact,
  paragraph__cards_slider: cards_slider,
  paragraph__title_img_desc_button: title_img_desc_button,
  paragraph__hero_banner_with_video: hero_banner_with_video,
  paragraph__quotes_slider: quotes_slider,
  paragraph__hero_banner_simple: hero_banner_simple,
  paragraph__buttons: buttons,
  paragraph__double_cards: double_cards,
  paragraph__ordered_desc_list: ordered_desc_list,
  paragraph__columned_desc_list: columned_desc_list,
  paragraph__spacer: spacer,
  paragraph__autodiagnostic: auto_diagnostic,
  paragraph__mail_sharing: mail_sharing,
  paragraph__offres_packagees: offrePackagees,
  paragraph__contact_floating_action_button: contactFloatingActionButton,
  paragraph__souscription_formulaire: souscriptionFormulaire,
  paragraph__bloc_media: blocMedia,
  paragraph__retour_souscription: retourSouscription,
  paragraph__mise_en_avant_sous_pack: miseEnAvantSousPack,
  [ERROR]: error
}

export default paragraphs
