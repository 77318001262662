/* eslint-disable @typescript-eslint/camelcase */
import { useStaticQuery, graphql } from 'gatsby'

import { IParagraph } from '..'
import Component, { IProps } from './Component'
import { unwrapMaybe as _ } from '../utils'
import { IAllAutoDiagnosticQuery, IParagraph__Autodiagnostic } from '../../graphql.types'

const propsFormatter = ({ relationships }: IParagraph__Autodiagnostic): IProps => {
  return {
    button: {
      link: {
        href:
          relationships?.field_button?.field_link?.uri_alias ||
          relationships?.field_button?.field_link?.uri
      },
      title: _('title', relationships?.field_button?.field_title),
      target: relationships?.field_button?.field_link_target,
      action: relationships?.field_button?.field_button_action_id
    }
  }
}

const Query = () =>
  useStaticQuery<IAllAutoDiagnosticQuery>(graphql`
    query AllAutoDiagnostic {
      data: allParagraphAutodiagnostic {
        edges {
          node {
            id
            drupal_internal__revision_id
            relationships {
              field_button {
                field_title
                field_link {
                  uri
                  uri_alias
                }
                field_link_target
                field_button_action_id
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
