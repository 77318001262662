import cs from 'classnames'
import React from 'react'

import Icon, { ICONS } from '../Icon/Icon'

interface IProps {
  value: number
  active: boolean
  showAsChecked?: boolean
  activeClassName?: string
}

export default function BulletNumber({
  value,
  active,
  activeClassName,
  showAsChecked = false
}: IProps) {
  return (
    <div
      className={cs(
        'flex flex-col items-center justify-center w-10 h-10 font-bold rounded-full font-brand',
        {
          'bg-orange-500 text-white': showAsChecked || (active && !activeClassName),
          'bg-gray-500 text-white': !active && !showAsChecked
        },
        active && activeClassName
      )}
    >
      {showAsChecked ? <Icon name={ICONS.Check} width="24" height="24" /> : value}
    </div>
  )
}
