import React from 'react'

import BulletNumberList from '../../components/presentationals/BulletNumberList/BulletNumberList'

export interface IProps {
  /** The title that will be displayed using an H2 */
  title: string
  /** The description displayed under the title */
  description: string
  /** The steps to be displayed */
  steps: { description: string }[]
}

export default function Component({ title, description, steps }: IProps) {
  const computedSteps = steps.map((step, index) => ({
    number: index + 1,
    subtitle: step.description
  }))

  return (
    <section className="mt-24 bg-white">
      <div className="container mx-auto">
        <h2 className="px-6 mb-5 text-3xl font-medium leading-none text-center font-brand text-purple-dark-500">
          {title}
        </h2>
        <p className="px-10 mx-auto text-center text-gray-700 md:text-lg md:w-3/4">{description}</p>

        <div className="px-4 py-6 md:py-10 md:pb-16">
          <BulletNumberList steps={computedSteps} allActive={true} />
        </div>
      </div>
    </section>
  )
}
