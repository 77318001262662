import cs from 'classnames'
import React from 'react'

import Button, { BUTTON_VARIANTS } from '../Button/Button'
import Icon, { ICONS } from '../Icon'

export interface IProps {
  items: {
    uri: string | undefined
    title: string
  }[]
}

const Breadcrumbs = ({ items }: IProps) => {
  return (
    <div className="container relative z-20 w-screen mx-auto xl:w-3/4 mt-16 lg:mt-21">
      <div className="whitespace-no-wrap">
        <div className="absolute top-0 left-0 flex items-center w-full px-3 py-1 md:py-2">
          {items.map((item, index) => (
            <div
              className={cs('flex items-center', { truncate: index === items.length - 1 })}
              key={index}
            >
              <Button
                className={cs('w-full', { 'pointer-events-none': !item.uri })}
                href={item.uri || '#'}
                target="_self"
                analyticsDataLayer={{
                  buttonLabel: item.title
                }}
                variant={BUTTON_VARIANTS.LINK}
                ariaLabel={item.title}
              >
                <p
                  className={cs('text-xs font-normal text-white md:text-base', {
                    truncate: index === items.length - 1
                  })}
                >
                  {item.title}
                </p>
              </Button>
              {index < items.length - 1 && (
                <Icon
                  className="text-white transform scale-50 rotate-180"
                  name={ICONS.Chevron}
                  width="24px"
                  height="24px"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
