/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import Component, { IProps } from './Component'
import { IParagraph__Spacer, IAllSpacerQuery } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'

const propsFormatter = ({ field_title }: IParagraph__Spacer): IProps => {
  return {
    title: _('title', field_title)
  }
}

const Query = () =>
  useStaticQuery<IAllSpacerQuery>(graphql`
    query AllSpacer {
      data: allParagraphSpacer {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
