import { assign } from '@xstate/immer'

import {
  IContext,
  IEvent,
  ICompanyForm,
  ICompany,
  IContactForm,
  SIREN_404_ERROR,
  SIREN_DEFAULT_ERROR
} from './constants'

// -------------------- //
// ----- STEP ONE ----- //
// -------------------- //

/**
 * Step One - Question 1
 */
export const replaceWorkLifeQuality = assign((context: IContext, { data }: IEvent<string>) => {
  context.enjeux.workLifeQuality = data
})

/**
 * Step One - Question 2
 */
export const replaceMainIssues = assign((context: IContext, { data }: IEvent<string[]>) => {
  context.enjeux.mainIssues = data
})

/**
 * Step One - Question 3
 */
export const replaceTakenActions = assign((context: IContext, { data }: IEvent<string[]>) => {
  context.enjeux.takenActions = data
})

/**
 * Step One - Question 4
 */
export const replaceEmployeesWithChild = assign((context: IContext, { data }: IEvent<number>) => {
  context.enjeux.employeesWithChild = data
})

// -------------------- //
// ----- STEP TWO ----- //
// -------------------- //

/**
 * Step Two - Question 1
 */
export const replaceSirenAndCompanyName = assign(
  (context: IContext, { data }: IEvent<ICompanyForm>) => {
    if (!!data) context.entreprise.companyForm = data
  }
)

export const replaceCompanyId = assign((context: IContext, { data }: IEvent<ICompany[]>) => {
  context.entreprise.companyId = data && data[0]
})

export const replaceCompanyList = assign((context: IContext, { data }: IEvent<ICompany[]>) => {
  context.entreprise.companyList = data
})

export const replaceInvokeError = (error: string) =>
  assign((context: IContext) => {
    context.entreprise.invokeError = error
  })

export const replaceSirenInvokeError = assign(
  (context: IContext, { data }: IEvent<{ response: { status: number } }>) => {
    context.entreprise.invokeError =
      data?.response?.status === 404 ? SIREN_404_ERROR : SIREN_DEFAULT_ERROR
  }
)

export const replaceEmployees = assign((context: IContext, { data }: IEvent<number>) => {
  if (!!context.entreprise.companyId)
    context.entreprise.companyId.trancheEffectifsEtablissement = data
})

export const replaceCompanyContact = assign((context: IContext, { data }: IEvent<IContactForm>) => {
  if (!!data) context.entreprise.companyContact = data
})
