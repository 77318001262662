import Axios, { AxiosError } from 'axios'
import cs from 'classnames'
import { Field, Form, Formik } from 'formik'
import { kebabCase } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import uid from 'uid'
import { IDqeSingleV2 } from '../../machines/contactForm'
import { PostSouscriptionFormulaire } from '../../machines/contactForm/services'
import { IDrupalButton } from '../../types/drupal'
import { sendClicCTAAnalytics } from '../../utils/analytics/analytics'
import { apiEndpoints } from '../../utils/environnement'
import CheckboxFormField from '../formik/CheckboxFormField/CheckboxFormField'
import DateField from '../formik/DateField/DateField'
import TextField from '../formik/TextField/TextField'
import Button, { BUTTON_VARIANTS, ICON_POSITIONS } from '../presentationals/Button/Button'
import './Souscription.css'


export interface IProps {
  validator: any
  state: any
  content: {
    cgv: string,
    button: IDrupalButton
    optionChoisie: string
  }
  send: (action: any, payload?: any) => void,
}

export default function SouscriptionComp({ validator, content }: IProps) {

  const initialValues = {
    prenom: '',
    nom: '',
    adresse: '',
    email: '',
    telephone: '',
    dateDeNaissance: '',
    civilite: '',
    codeOffre: '',
    adresseLabel: '',
    adresseDQE: {
      codePostal: '',
      voie: '',
      localite: '',
      numVoie: '',
      label: '',
    }
  }

  const [isFetching, setIsFetching] = useState(false);

  const formattedOnSubmit = useCallback(
    values => {

      setIsFetching(true);
      values.apiEndpoints = apiEndpoints;
      PostSouscriptionFormulaire(values).then(result => {
        if (typeof window !== "undefined") {
          sendClicCTAAnalytics({
            targetUrl: result.data.Prospect.checkout,
            buttonLabel: content.button.title
          });
          window.open(result.data.Prospect.checkout, "_self");
        }
      }).catch((reason: AxiosError) => {
        if (typeof window !== "undefined") {
          if (reason?.response?.status === 400) {
            sendClicCTAAnalytics({
              targetUrl: "/echec",
              buttonLabel: content.button.title
            });
            window.open("/echec", "_self");
          } else {
            sendClicCTAAnalytics({
              targetUrl: '/erreur-technique',
              buttonLabel: content.button.title
            });
            window.open('/erreur-technique', "_self");
          }
          setIsFetching(false);
        }
      });
    },
    [PostSouscriptionFormulaire]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(value, action) => {
      }}
      validateOnMount={true}
      validationSchema={validator}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid, errors } = props

        // in order to show errors, keeping state of fields that have lost focus already
        const [blurredFields, setBlurredFields] = useState({
          prenom: false,
          nom: false,
          email: false,
          adresse: false,
          telephone: false,
          civilite: false,
          dateDeNaissance: false,
          codeOffre: false,
        })

        const handleChange = useCallback(e => {
          setFieldValue(e.target.name, e.target.value);
        }, [
          setFieldValue
        ])

        const handlePressEnter = useCallback(() => isValid && handleSubmit(), [handleSubmit])

        const handleBlur = useCallback(
          e => setBlurredFields({ ...blurredFields, [e.target.name]: true }),
          [blurredFields, setBlurredFields]
        )

        const [checked, setChecked] = useState(false)

        const [searchTerm, setSearchTerm] = useState('')


        const [dqe, setDqe] = useState()

        values['codeOffre'] = content.optionChoisie;
        const inputProps = {
          placeholder: 'Saisissez votre adresse',
          autoComplete: 'abcd',
          name: 'adresse',
          id: 'adresse',
          value: values['adresse'],
          disabled: isFetching,
          onBlur: handleBlur,
          onChange: handleChange,
          className:
            blurredFields.adresse && (errors.adresse || !values.adresseDQE.localite)
              ? 'w-full px-4 py-3 mt-2 border rounded-lg focus:outline-none border-red-600 errorIcon'
              : 'w-full px-4 py-3 mt-2 border rounded-lg focus:outline-none adresseSearch'
        }

        const clearSuggestions = () => {
          setSuggestions([])
        }

        useEffect(() => {
          const delayDebounceFn = setTimeout(() => {
            selectFetchSuggestions({ value: searchTerm });
          }, 1000)

          return () => clearTimeout(delayDebounceFn)
        }, [searchTerm])

        const selectFetchSuggestions = async ({ value }) => {
          if (value.length >= 3 && value) {
            try {
              const result = await Axios.get(apiEndpoints.eole.DQEUrl, {
                params: { Pays: "FRA", Adresse: value },
                headers: {
                  'api-key': apiEndpoints.eole.DQEAPIKey,
                  correlationId: uid(16),
                  appRequestCode: process.env.GATSBY_APP_REQUEST_CODE,
                }
              })
              if (value != values.adresseDQE.label) {
                values.adresseDQE.localite = '';
              }

              if (result.data[1] != undefined) {
                let list = Object.values(result.data).map((row: { name: IDqeSingleV2; }) => ({
                  label: row.label,
                  codePostal: row.CodePostal,
                  voie: row.Voie,
                  localite: row.Localite,
                  numVoie: row.Numero,
                  IDVoie: row.IDVoie,
                }));
                list = list.slice(0, 10)
                list.forEach(element => element.label = element.label.replace('|', ', '));
                setSuggestions(list.map(({ label }) => label));
                setDqe(list);
              }
            } catch (e) { }
          }
        }

        const selectSuggestions = (
          event: { preventDefault: () => void },
          { suggestion, method }: any
        ) => {
          if (method === 'enter') {
            event.preventDefault()
          }
          setFieldValue('adresse', suggestion)
          let valeurSelect = dqe.find(element => element.label == suggestion);
          values.adresseDQE = valeurSelect;
          Axios.get(apiEndpoints.eole.COMPLV2Url, {
            params: { Pays: "FRA", IDVoie: valeurSelect.IDVoie, IDNum: valeurSelect.numVoie },
            headers: {
              'api-key': apiEndpoints.eole.COMPLV2APIKey,
              correlationId: uid(16),
              appRequestCode: process.env.GATSBY_APP_REQUEST_CODE,
            }
          }).then(result => {
          }).catch(errors => {
          })
        }

        const [suggestions, setSuggestions] = React.useState([])

        return (
          <Form>
            <fieldset>
              <div className="w-4/6 mb-6">
                <span className="text-xl text-purple-dark-500 font-bold font-brand">Vos informations</span>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="mb-4 mr-6 w-full md:w-1/4">
                  <label htmlFor={`input-${kebabCase('Civilité')}`} className="font-bold font-brand text-purple-dark-500">Civilité</label>
                  <Field
                    name="civilite"
                    component="select"
                    placeholder="Civilite"
                    label="Civilité"
                    value={values['civilite']}
                    onChange={handleChange}
                    onPressEnter={handlePressEnter}
                    onBlur={handleBlur}
                    disabled={isFetching}
                    className={values.civilite ? 'w-full px-4 py-3 mt-2 mb-2 border rounded-lg focus:outline-none text-black-400 disabled-select' : blurredFields.civilite ? 'w-full px-4 py-3 mt-2 mb-4 border rounded-lg focus:outline-none text-gray-400 border-red-600 disabled-select errorIcon' : 'w-full px-4 py-3 mt-2 mb-4 border rounded-lg focus:outline-none text-gray-400 disabled-select'}
                    error={blurredFields.civilite ? (errors['civilite'] as string) : undefined}
                  >
                    <option disabled value="">Saisissez votre civilité </option>
                    <option className="text-black" value="Monsieur">Monsieur</option>
                    <option className="text-black" value="Madame">Madame</option>
                  </Field>
                  {blurredFields.civilite && (
                    <p className="italic text-red-600">{blurredFields.civilite ? (errors['civilite'] as string) : undefined}</p>)}
                </div>
              </div>
              <div className="flex flex-col md:flex-row">

                <TextField
                  name="nom"
                  className="mb-4 mr-6 w-full md:w-1/2"
                  label="Nom"
                  placeholder="Saisissez votre nom"
                  value={values['nom']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  showErreurIcon={true}
                  disabled={isFetching}
                  error={blurredFields.nom ? (errors['nom'] as string) : undefined}
                />
                <TextField
                  name="prenom"
                  className="mb-4 w-full md:w-1/2"
                  placeholder="Saisissez votre prénom"
                  label="Prénom"
                  value={values['prenom']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  showErreurIcon={true}
                  disabled={isFetching}
                  error={blurredFields.prenom ? (errors['prenom'] as string) : undefined}
                />

              </div>
              <div className="flex flex-col md:flex-row">
                <TextField
                  name="email"
                  className="mb-4 mr-6 w-full md:w-1/2"
                  label="Email"
                  placeholder="Saisissez votre email"
                  value={values['email']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  showErreurIcon={true}
                  disabled={isFetching}
                  error={blurredFields.email ? (errors['email'] as string) : undefined}
                />
                <div className="mb-2 md:mb-6 w-full md:w-1/2 relative">
                  <label
                    className="font-bold font-brand text-purple-dark-500"
                    htmlFor="adresse"
                  >
                    Adresse
                  </label>
                  <Autosuggest
                    inputProps={inputProps}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={e => setSearchTerm(e.value)}
                    onSuggestionsClearRequested={clearSuggestions}
                    onSuggestionSelected={selectSuggestions}
                    getSuggestionValue={(suggestion: any) => suggestion}
                    renderSuggestion={(suggestion: React.ReactNode) => <div>{suggestion}</div>}
                  />
                  {blurredFields.adresse && (
                    <p className="mt-2 italic text-red-600">{blurredFields.adresse ? (errors['adresse'] as string) : undefined}</p>)}
                  {blurredFields.adresse && !values.adresseDQE.localite && !errors['adresse'] && (
                    <p className="mt-2 italic text-red-600">{blurredFields.adresse ? "Merci de sélectionner une adresse valide" : undefined}</p>)}
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:mb-10">

                <DateField
                  name="dateDeNaissance"
                  className={!values['dateDeNaissance'] ? "mb-4 pt-4 w-full md:w-1/4 text-gray-400" : "mb-4 pt-4 w-full md:w-1/4 text-black-400"}
                  label="Date de naissance"
                  value={values['dateDeNaissance']}
                  onChange={(date: Date) => {
                    if (date) {
                      setFieldValue("dateDeNaissance", date);
                      setBlurredFields({ ...blurredFields, "dateDeNaissance": true });
                    } else { setFieldValue("dateDeNaissance", undefined) };
                  }}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  disabled={isFetching}
                  error={blurredFields.dateDeNaissance ? (errors['dateDeNaissance'] as string) : undefined}
                />

                <TextField
                  name="telephone"
                  className="pt-4 md:pl-1 mb-10 md:mb-0 md:ml-64 w-full md:w-1/4"
                  label="Téléphone mobile"
                  placeholder="0600000000"
                  value={values['telephone']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  optionnel={false}
                  disabled={isFetching}
                  error={blurredFields.telephone ? (errors['telephone'] as string) : undefined}
                />
              </div>
              <div>
                <div className="bg-white md:py-4 md:pl-4 md:pr-1">
                  <div className="overflow-y-auto h-32 text-sm p-2 sm:p-0 sm:pr-2">
                    <h2 className="font-bold" >Conditions générales de vente</h2>
                    <div dangerouslySetInnerHTML={{ __html: content.cgv }}></div>
                  </div>
                </div>
                <div className="mt-6">
                  <CheckboxFormField
                    checked={checked}
                    onClick={() => { setChecked(!checked) }}
                    onChange={() => handleChange}
                    disabled={isFetching}
                    text="J'ai lu et j’accepte les conditions générales de vente"
                  />
                </div>

                <div className="text-center">
                  <Button
                    className={cs("flex justify-center my-8 lg:w-3/6 xl:w-1/3", (!isValid) || !checked || !values.adresseDQE.localite || isFetching ? 'btn-hidden ' : '')}
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={() => formattedOnSubmit(values)}
                    ariaLabel={content.button.title}
                    loading={isFetching}
                    iconPosition={isFetching ? ICON_POSITIONS.RIGHT : undefined}
                    disabled={(!isValid) || !checked || !values.adresseDQE.localite || isFetching}
                  >
                    {content.button.title}
                  </Button>
                </div>
              </div>
            </fieldset>
          </Form>
        )
      }}
    </Formik >
  )
}

