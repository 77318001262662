import { IApiEndpoints } from '../../hooks/useApiEndpoints'

export const SESSION_STORAGE_KEY = 'flex__autodiagnostique'

export const SERVICES = {
  SEND: 'send'
}

export interface IContext {
  apiEndpoints: IApiEndpoints
  contact: {
    civilite?: string,
    lastname: string,
    firstname: string,
    email: string,
    telephone: string,
    siren: string,
    companyName: string,
    codePostal: string,
    invokeError: string | undefined,
    prospectionEmail: boolean,
    commentaire: string,
    checked: boolean
  }
}

export interface IDqeSingleV2 {
  CodePostal: string,
  Pays: string,
  Voie: string,
  Localite: string,
}

export interface ISoucription {
  prenom: string,
  nom: string,
  email: string,
  telephone: string,
  dateDeNaissance: string,
  codeOffre: string,
  civilite: string,
  adresse: string,
  adresseDQE: {
    codePostal: string,
    voie: string,
    localite: string,
    numVoie: string
  }
}

export interface IEvent<T> {
  type: string
  data?: T
}

export const SIREN_404_ERROR =
  "Nous n'avons pu identifier votre entreprise dans la base Sirene.\nNous vous invitons à effacer votre saisie et compléter à nouveau les champs entreprise."
export const SIREN_DEFAULT_ERROR =
  "Nous avons rencontré un problème technique lors de l'appel à la base Sirene permettant d'identifier votre entreprise.\nNous vous invitons à effacer votre saisie et compléter à nouveau les champs entreprise ou recommencer ultérieurement l'identification de vos besoins."
