import { IAnalyticsEvent, sendIAdvizeModalOpeningAnalytics } from '../analytics/analytics'

export const AVAILABLE_CLASS_SELECTOR = 'ia-flex-available'
export const FLOATING_AVAILABLE_CLASS_SELECTOR = 'ia-flex-available-floating'
export const SOUSCRIPTION_AVAILABLE_CLASS_SELECTOR = 'ia-flex-available-souscription'

export default function showModal(
  availableClass: string = AVAILABLE_CLASS_SELECTOR,
  analyticsEventName: IAnalyticsEvent = IAnalyticsEvent.ME_RAPPELER
) {
  const button = document.querySelector(`.${availableClass}`) as HTMLElement
  const isButtonVisible = button && button.style.display !== 'none'

  if (isButtonVisible) {
    button.click()
    sendIAdvizeModalOpeningAnalytics({}, analyticsEventName)
  } else {
    // eslint-disable-next-line
    console.warn("Can't find IAdvize button, please check configuration...")
  }
}
