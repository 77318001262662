import applyTestsActions from '../../../utils/tests/applyTestActions'
import { pageWaitFor } from '../../../utils/tests/testActions'
import { IContext } from '../constants'
import {
  replaceWorkLifeQuality,
  replaceMainIssues,
  replaceTakenActions,
  replaceEmployeesWithChild
} from '../actions'

export const ACTIONS = {
  NEXT: 'NEXT',
  EDIT_QUESTION_1: 'EDIT_QUESTION_1',
  EDIT_QUESTION_2: 'EDIT_QUESTION_2',
  EDIT_QUESTION_3: 'EDIT_QUESTION_3',
  EDIT_QUESTION_4: 'EDIT_QUESTION_4'
}

export const INITIAL_CONTEXT = {
  workLifeQuality: undefined,
  mainIssues: undefined,
  takenActions: undefined,
  employeesWithChild: undefined
}

const enjeux = {
  id: 'enjeux',
  initial: 'questionOne',
  states: {
    questionOne: {
      on: {
        [ACTIONS.NEXT]: [
          {
            target: 'questionTwo',
            actions: replaceWorkLifeQuality,
            cond: ({ enjeux }: IContext) => enjeux.mainIssues === undefined
          },
          {
            target: 'questionThree',
            actions: replaceWorkLifeQuality,
            cond: ({ enjeux }: IContext) => enjeux.takenActions === undefined
          },
          {
            target: 'questionFour',
            actions: replaceWorkLifeQuality
          }
        ],
        [ACTIONS.EDIT_QUESTION_1]: 'questionOne',
        [ACTIONS.EDIT_QUESTION_2]: 'questionTwo',
        [ACTIONS.EDIT_QUESTION_3]: 'questionThree',
        [ACTIONS.EDIT_QUESTION_4]: 'questionFour'
      },
      meta: {
        questionIndex: 1,
        testActions: [{ selector: '[data-testid="question-1-form"]', action: pageWaitFor }],
        test: applyTestsActions
      }
    },
    questionTwo: {
      on: {
        [ACTIONS.NEXT]: [
          {
            target: 'questionThree',
            actions: replaceMainIssues,
            cond: ({ enjeux }: IContext) => enjeux.takenActions === undefined
          },
          {
            target: 'questionFour',
            actions: replaceMainIssues
          }
        ],
        [ACTIONS.EDIT_QUESTION_1]: 'questionOne',
        [ACTIONS.EDIT_QUESTION_2]: 'questionTwo',
        [ACTIONS.EDIT_QUESTION_3]: 'questionThree',
        [ACTIONS.EDIT_QUESTION_4]: 'questionFour'
      },
      meta: {
        questionIndex: 2,
        testActions: [
          { selector: '[data-testid="question-2-form"]', action: pageWaitFor },
          { selector: '[data-testid="question-1-edit-button"]', action: pageWaitFor }
        ],
        test: applyTestsActions
      }
    },
    questionThree: {
      on: {
        [ACTIONS.NEXT]: {
          target: 'questionFour',
          actions: replaceTakenActions
        },
        [ACTIONS.EDIT_QUESTION_1]: 'questionOne',
        [ACTIONS.EDIT_QUESTION_2]: 'questionTwo',
        [ACTIONS.EDIT_QUESTION_3]: 'questionThree',
        [ACTIONS.EDIT_QUESTION_4]: 'questionFour'
      },
      meta: {
        questionIndex: 3,
        testActions: [
          { selector: '[data-testid="question-3-form"]', action: pageWaitFor },
          { selector: '[data-testid="question-1-edit-button"]', action: pageWaitFor },
          { selector: '[data-testid="question-2-edit-button"]', action: pageWaitFor }
        ],
        test: applyTestsActions
      }
    },
    questionFour: {
      on: {
        [ACTIONS.NEXT]: {
          target: '#entreprise',
          actions: replaceEmployeesWithChild
        },
        [ACTIONS.EDIT_QUESTION_1]: 'questionOne',
        [ACTIONS.EDIT_QUESTION_2]: 'questionTwo',
        [ACTIONS.EDIT_QUESTION_3]: 'questionThree'
      },
      meta: {
        questionIndex: 4,
        testActions: [
          { selector: '[data-testid="question-4-form"]', action: pageWaitFor },
          { selector: '[data-testid="question-1-edit-button"]', action: pageWaitFor },
          { selector: '[data-testid="question-2-edit-button"]', action: pageWaitFor },
          { selector: '[data-testid="question-3-edit-button"]', action: pageWaitFor }
        ],
        test: applyTestsActions
      }
    }
  }
}

export default enjeux
