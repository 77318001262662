import cs from 'classnames'
import React from 'react'

import { IAnalyticsEvent } from '../../utils/analytics/analytics'
import showIAdvizeModal, { FLOATING_AVAILABLE_CLASS_SELECTOR } from '../../utils/iAdvize/showModal'
import style from './Component.module.css'

export interface IProps { }

export default function Component() {
  return (
    <div
      className={cs('sticky z-20 w-full h-0 sticky__wrapper')}
      onClick={() =>
        showIAdvizeModal(FLOATING_AVAILABLE_CLASS_SELECTOR, IAnalyticsEvent.ICONE_RAPPEL)
      }
    >
      <div className={cs('fixed bottom-0 right-0 pr-4 md:pr-8', style.centrer_verticalement)}>
        <button
          aria-label="Je contacte un conseiller"
          className={cs(
            style.fab_btn,
            'group font-bold overflow-hidden block text-center text-white shadow-lg rounded-full min-w-14 md:min-w-18 min-h-14 md:min-h-18 '
          ) + style.purpleButton}
        >
          <span className={cs('font-brand text-2xl md:text-3xl relative group-hover:pl-28')}>?</span>
        </button>
      </div>
    </div>
  )
}
