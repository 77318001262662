/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import {
  IAllContactFloatingActionButtonQuery, IParagraph__Bloc_Media,
} from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_image,
  relationships,
  field_url_complete_video
}: IParagraph__Bloc_Media): IProps => {
  return {
    title: _('title', field_title),
    description: field_plain_description,
    image: {
      alt: _('alt', field_image?.alt),
      ..._('image', relationships?.field_image?.localFile?.childImageSharp)
    },
    lien: {
      uri: field_url_complete_video?.uri
    },
    button: {
      link: {
        href:
          relationships?.field_button?.field_link?.uri_alias ||
          relationships?.field_button?.field_link?.uri
      },
      title: _('title', relationships?.field_button?.field_title),
      target: relationships?.field_button?.field_link_target,
      action: relationships?.field_button?.field_button_action_id,
      style: _('style', relationships?.field_button?.field_style)
    }
  }
}

const Query = () =>
  useStaticQuery<IAllContactFloatingActionButtonQuery>(graphql`
  query AllBlocMedia {
    data : allParagraphBlocMedia {
      edges {
        node {
          drupal_internal__revision_id
          field_button {
            target_revision_id
          }
          field_image {
            alt
          }
          field_url_complete_video {
            uri
          }
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            field_button {
              field_title
              field_link {
                uri
                uri_alias
              }
              field_link_target
              field_button_action_id,
              field_style
            }
          }
          field_plain_description
          field_title
        }
      }
    }
  }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
