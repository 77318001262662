import { graphql, useStaticQuery } from 'gatsby'

export interface IApiEndpoints {
  siren: {
    href: string | null
    apiKey: string | null
  }
  eole: {
    href: string | null
    apiKey: string | null
  }
}

const useApiEndpoints = (): IApiEndpoints => {
  const data = useStaticQuery(
    graphql`
      query ApiEndpointsQuery {
        allHeader {
          nodes {
            webServices {
              siren {
                apiKey
                href
              }
              eole {
                apiKey
                href
              }
            }
          }
        }
      }
    `
  )

  return data.allHeader.nodes[0].webServices
}

export default useApiEndpoints
