import React from 'react'

import paragraphs from '.'
import { ERROR } from './error'
import { Maybe } from '../graphql.types'

export interface IContent {
  typename: string
  revisionId?: string
  extraProps?: object
}

const renderError = (message: string) => {
  const { Component } = paragraphs[ERROR]
  return <Component message={message} />
}

function throwInvalidContributionError(fieldType?: String): never {
  throw new Error(`InvalidContributionError: Required field ${fieldType} has not been contributed.`)
}

/**
 * Returns the value wrapped in maybe if not undefined, else the default value if provided, else throws an error
 * @param maybe
 * @param def
 */
export function unwrapMaybe<T>(fieldType: String = 'test', maybe: Maybe<T>, defaultValue?: T): T {
  return typeof maybe === 'undefined' ? defaultValue || throwInvalidContributionError(fieldType) : maybe
}

export function getComponent({ typename, revisionId, extraProps = {} }: IContent) {
  let paragraph = paragraphs[typename]
  let revision

  if (!paragraph) {
    return renderError(`Paragraph "${typename}" does not exits`)
  }

  const { Component, propsFormatter, Query } = paragraph

  if (Query) {
    const { data } = Query()
    if (typeof revisionId === 'undefined')
      return <Component {...propsFormatter(data.edges[0].node)} {...extraProps} />

    revision = data.edges.find(({ node }) => node.drupal_internal__revision_id === revisionId)
  }

  if (typeof revisionId === 'undefined') {
    return <Component key={revisionId} {...extraProps} />
  }

  if (!revision || !revision.node) {
    throw new Error(`Erreur lors de la récupération de contenu de type ${typename} avec l'id revision ${revisionId}`)
  }

  return <Component key={revisionId} {...propsFormatter(revision.node)} {...extraProps} />
}
