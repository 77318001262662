import axios from 'axios'
import dayjs from 'dayjs'
import uid from 'uid'
import { ISoucription } from '.'
import { apiEndpoints } from '../../utils/environnement'
import { ICompany } from '../autodiagnostic'
import { IContext } from './constants'


export const fetchCompanyByCompagnyName = (
  { apiEndpoints: { siren } }: IContext,
  data: string
): Promise<ICompany[]> => {
  if (!data) return Promise.reject("Event object doesn't have any 'data'.")
  if (siren.href === null || siren.apiKey === null)
    return Promise.reject('SIREN WebService needs an URL and an API Key.')

  return axios
    .get(siren.href, {
      params: { q: `raisonSociale:${data}` },
      headers: {
        'api-key': siren.apiKey,
        correlationId: uid(16)
      }
    })
    .then((response: any) => response.data.etablissements)
}

export const POSTFormulaireContact = (context: IContext): Promise<any> => {
  const {
    contact,
    apiEndpoints: { eole }
  } = context

  if (eole.href === null || eole.apiKey === null)
    return Promise.reject('EOLE WebService needs an URL and an API Key.')

  const now = dayjs()

  var infosPersonnelles: any = {
    nom: contact.lastname,
    prenom: contact.firstname,
    dateNaissance: contact.companyName ? now.format('DD-MM-YYYY') : dayjs(new Date("01/01/1900")).format('DD-MM-YYYY'),
    email: contact.email,
    telephone1: contact.telephone,
    codePostalParticulier: !contact.companyName ? contact.codePostal : ''
  }

  if (!contact.companyName) {
    infosPersonnelles["civilite"] = contact.civilite
  }

  return axios.post(
    eole.href,
    {
      societeConcernee: 'LMGCS',
      typeFiche: contact.companyName ? 'B2B' : 'B2C',
      idFicheFournisseur: now.format('YYYYDDMMHHmmss'),
      origine: 'SiteInstitutionnelFLEX',
      objetFiche: 'Devis',
      dateEnvoi: now.format('DD-MM-YYYY'),
      infosPersonnelles: infosPersonnelles,
      offre: {
        codeOffre: 'undefined',
        commentaireProspect: contact.commentaire
      },
      entreprise: {
        raisonSociale: contact.companyName,
        siren: contact.siren,
        codePostal: !contact.companyName ? '' : contact.codePostal
      },
      consentements: {
        dateConsentement: now.format('DD-MM-YYYY'),
        consentementProspectionEmail: contact.companyName ? true : contact.checked,
        consentementTransfertFlex: true,
        consentementTransfertLmg: true
      }
    },
    {
      headers: {
        appRequestCode: 'WEBFLEX',
        idConsommateur: 'WEBFLEX',
        'api-key': eole.apiKey,
        correlationId: uid(16),
      }
    }
  )
}

export const PostSouscriptionFormulaire = (context: ISoucription): Promise<any> => {
  const {
    civilite,
    prenom,
    nom,
    email,
    telephone,
    dateDeNaissance,
    codeOffre,
    adresseDQE,
  } = context
  if (apiEndpoints.eole.CreerVenteUrl === null || apiEndpoints.eole.CreerVenteAPIKey === null)
    return Promise.reject('EOLE WebService needs an URL and an API Key.')

  let paramurl;

  if (typeof window !== "undefined" && codeOffre == undefined) {
    const params = new URLSearchParams(location.search);
    paramurl = params.get("offre");
  }

  const now = dayjs();
  const date = dayjs(new Date(dateDeNaissance)).format('DD-MM-YYYY')
  return axios.post(
    apiEndpoints.eole.CreerVenteUrl,
    {
      societeConcernee: 'LMGCS',
      typeFiche: 'B2C',
      idFicheFournisseur: now.format('YYYYDDMMHHmmss'),
      origine: 'FLEX-TDV',
      objetFiche: 'Demande d\'adhésion',
      dateEnvoi: now.format('DD-MM-YYYY'),
      infosPersonnelles: {
        civilite: civilite,
        nom: nom,
        prenom: prenom,
        dateNaissance: date,
        email: email,
        telephone1: telephone != '' ? telephone : undefined,
        telephone2: undefined,
        paysParticulier: 'FRANCE',
        codePostalParticulier: adresseDQE.codePostal,
        villeParticulier: adresseDQE.localite,
        voieParticulier: `${adresseDQE.numVoie} ${adresseDQE.voie}`,
      },
      offre: {
        codeOffre: codeOffre == undefined ? paramurl : codeOffre,
        tarification: 'Tarification annuelle',
        modePaiement: 'CB'
      },
      consentements: {
        dateConsentement: now.format('DD-MM-YYYY'),
        consentementProspectionEmail: true,
        consentementTransfertFlex: true,
        consentementTransfertLmg: true
      }
    },
    {
      headers: {
        appRequestCode: process.env.GATSBY_APP_REQUEST_CODE,
        'api-key': apiEndpoints.eole.CreerVenteAPIKey,
        correlationId: uid(16),
      }
    }
  )
}