import { useMutationObserver } from './useMutationObserver'

export function useIAdvizeObserver(iadvizeAvailableClass: string) {
  const isIAdvizeAvailable = useMutationObserver(
    `.${iadvizeAvailableClass}`,
    { attributes: true, attributeFilter: ['style'] },
    (mutations, _) => {
      for (let mut of mutations) {
        if (mut.attributeName === 'style') {
          return !(mut.target as Element).getAttribute('style')?.includes('display: none')
        }
      }
    }
  )

  return isIAdvizeAvailable ?? false
}
