import { assign } from '@xstate/immer'

import {
  IContext, IEvent,
} from './constants'



export const replaceInvokeError = (error: string) =>
  assign((context: IContext) => {
    context.contact.invokeError = error
  })

  export const replaceContact = assign((context: IContext, { data }: IEvent<any>) => {
    if (!!data) context.contact = data
  })
