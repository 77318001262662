import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import React, { useCallback, useState } from 'react'

import breakpoints from '../../assets/css/breakpoints'
import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import Icon, { ICONS } from '../../components/presentationals/Icon/Icon'
import YoutubeVideoModal from '../../components/presentationals/YoutubeVideoModal/YoutubeVideoModal'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IDrupalButton, IBannerVideoLink } from '../../types/drupal'
import { IImage } from '../../types/gatsby/images'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'
import style from './Component.module.css'

export interface IProps {
  /** The large image displayed on background */
  image: IImage
  /** The main title displayed in an H1 */
  title: string
  /** The base text right under the text */
  description?: string
  /** The ID of a Youtube video */
  youtubeVideoId?: string
  /** 0 to 2 buttons displayed under the description */
  buttons?: IDrupalButton[]
  lien?: IBannerVideoLink
  alignementTexte: string
  couleurTexte: string
}

export default function Component({
  image,
  title,
  description,
  youtubeVideoId,
  buttons = [],
  lien,
  alignementTexte,
  couleurTexte
}: IProps) {
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false)

  const toggleVideoVisibility = useCallback(() => setIsVideoVisible(!isVideoVisible), [
    isVideoVisible,
    setIsVideoVisible
  ])

  const isDesktopLayout = useBreakpoint(breakpoints.md)

  return (
    <>
      <div className="relative w-full bg-white">
        <div className="relative z-10 w-full md:absolute md:h-128">
          <GatsbyImage className="object-cover w-full h-40 md:h-128" {...image} />
        </div>

        {(lien?.uri || youtubeVideoId) && (
          <div className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-40 md:hidden">
            <div className="cursor-pointer" onClick={toggleVideoVisibility}>
              <Icon name={ICONS.Play} width="48" height="48" />
            </div>
          </div>
        )}
      </div>

      <div className="relative bg-white md:bg-gray-600 md:text-white md:h-128">
        <div className="md:px-8 md:mx-8">
          {youtubeVideoId && !lien?.uri && isVideoVisible && (
            <YoutubeVideoModal videoId={youtubeVideoId} onBackdropClick={toggleVideoVisibility} />
          )}
          {lien?.uri && isVideoVisible && (
            <YoutubeVideoModal uri={lien?.uri} onBackdropClick={toggleVideoVisibility} />
          )}
          <div className={"container relative z-10 px-0 text-center flex flex-col justify-center py-8 mx-auto md:py-16  md:h-128"}>
            <h1
              className={'sm:w-2/3 mx-auto mb-5 text-4xl text-purple-dark-500 font-medium leading-none md:mx-0 md:w-8/12 xl:text-6xl 2xl:text-6xl font-brand ' +
                (alignementTexte == "Gauche" ? "md:text-left md:w-8/12 " : (alignementTexte == "Droit" ? "md:text-right md:ml-auto " : " md:ml-auto md:mr-auto md:w-full md:px-8")) +
                (couleurTexte == "Violet" ? "" : " md:text-white")}
            >
              {title}
            </h1>
            <div className={"md:w-7/12 sm:w-2/3 mx-auto" +
              (alignementTexte == "Gauche" ? "mr-auto" : (alignementTexte == "Droit" ? " md:ml-auto md:mr-0 " : " md:ml-auto md:mr-auto"))}>
              <div
                className={cs(
                  'w-12 h-1 mx-auto mb-5 bg-orange-500 rounded md:w-12 md:h-2 md:mx-0 ml-auto mr-auto',
                  style.hero__separator) +
                  (alignementTexte == "Gauche" ? " md:mr-auto " : (alignementTexte == "Droit" ? " md:ml-auto " : " md:ml-auto md:mr-auto ")) +
                  (couleurTexte == "Violet" ? "md:bg-purple-800 " : "md:bg-white ") + style.line}
              ></div>
            </div>

            <p
              className={'px-8 mx-auto font-sans leading-normal text-gray-800 md:mx-0 md:text-lg md:px-0 md:w-9/12 lg:text-xl mb-6 ' +
                (alignementTexte == "Gauche" ? "md:text-left md:w-4/12 " : (alignementTexte == "Droit" ? "md:text-right md:ml-auto" : "md:ml-auto md:mr-auto md:w-full md:px-8")) +
                (couleurTexte == "Violet" ? "md:text-purple-dark-500" : " md:text-white") +
                (buttons.length > 0 ? " xl:mb-12 md:mb-4" : "")}
            >
              {description}
            </p>
            <div className={"flex flex-col  mx-auto md:mx-0 md:flex-row sm:w-1/2 md:w-4/5 lg:w-3/5 " +
              (alignementTexte == "Gauche" ? "" : (alignementTexte == "Droit" ? "md:ml-auto" : "md:mx-auto")) +
              ((buttons.length === 1 && alignementTexte === "Milieu") ? " justify-center" : (buttons.length === 1 && alignementTexte === "Droit") ? " justify-end" : "")
            }>
              {buttons.length > 0 && (
                <Button
                  className={cs('flex items-center justify-center text-center', {
                    'w-full mb-2 md:mb-0 md:w-1/2 md:mr-1': typeof buttons[1] !== 'undefined'
                  })}
                  {...buttonPropsFormatter(buttons[0])}
                />
              )}
              {buttons.length > 1 && (
                <Button
                  className={cs(
                    'flex items-center justify-center w-full md:w-1/2 md:ml-1 text-center'
                  )}
                  {...buttonPropsFormatter(buttons[1])}
                  variant={!buttons[1].style || buttons[1].style === 'Défaut' ? BUTTON_VARIANTS.SECONDARY : buttons[1].style === "PRIMARY" ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.SECONDARY}
                />
              )}
            </div>
            {youtubeVideoId && (
              <div className={"justify-center mt-8 text-lg md:flex md:w-7/12 " +
                (alignementTexte == "Gauche" ? "" : (alignementTexte == "Droit" ? "md:text-right md:ml-auto " : " md:mx-auto "))}>
                <div className="flex cursor-pointer" onClick={toggleVideoVisibility}>
                  <Icon name={ICONS.Play} width="24" height="24" />
                  <p className="inline-block ml-3">Lire la vidéo</p>
                </div>
              </div>
            )}
          </div>
          {(lien?.uri || youtubeVideoId) && (
            <div className={"justify-center hidden md:hidden mt-8 text-lg md:flex md:w-7/12" +
              (alignementTexte == "Gauche" ? "" : (alignementTexte == "Droit" ? "md:text-right md:ml-auto " : " md:mx-auto "))}>
              <div className="flex cursor-pointer" onClick={toggleVideoVisibility}>
                <Icon name={ICONS.Play} width="24" height="24" />
                <p className="inline-block ml-3">Lire la vidéo</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
