/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllParagraphMiseEnAvantSousPack, IParagraph__Mise_En_Avant_Sous_Pack } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_plain_description,
  field_title,
  relationships
}: IParagraph__Mise_En_Avant_Sous_Pack): IProps => {
  return {
    title: field_title,
    description: field_plain_description,
    offre: {
      pack: {
        name: relationships?.field_pack_souscription?.relationships?.field_pack?.field_title,
        code: relationships?.field_pack_souscription?.relationships?.field_pack?.field_code_offre,
        image: {
          alt: _('alt', relationships?.field_pack_souscription?.relationships?.field_pack?.field_image?.alt),
          ..._('image', relationships?.field_pack_souscription?.relationships?.field_pack?.relationships?.field_image?.localFile?.childImageSharp)
        },
        price: relationships?.field_pack_souscription?.relationships?.field_pack?.field_price,
        price2: relationships?.field_pack_souscription?.relationships?.field_pack?.field_prix_ligne_2,
        libellePrice: relationships?.field_pack_souscription?.relationships?.field_pack?.field_libelle_prix_ligne_1,
        libellePrice2: relationships?.field_pack_souscription?.relationships?.field_pack?.field_libelle_prix_ligne_2,
        services: (relationships?.field_pack_souscription?.relationships?.field_pack?.relationships?.field_services).map(service => ({
          name: service.relationships.field_service.title,
          included: service.field_included
        })),
        button: {
          title: relationships?.field_pack_souscription?.relationships?.field_pack?.relationships?.field_button?.field_title,
        }
      },
      option: {
        options: (relationships?.field_pack_souscription?.relationships?.field_option?.relationships?.field_list).map(option => ({
          name: option.field_option,
          code: option.field_code_offre
        })),
        button: {
          title: relationships?.field_pack_souscription?.relationships?.field_option?.relationships?.field_button?.field_title,
        },
        price: relationships?.field_pack_souscription?.relationships?.field_option?.field_price,
        price2: relationships?.field_pack_souscription?.relationships?.field_option?.field_prix_ligne_2,
        libellePrice: relationships?.field_pack_souscription?.relationships?.field_option?.field_libelle_prix_ligne_1,
        libFellePrice2: relationships?.field_pack_souscription?.relationships?.field_option?.field_libelle_prix_ligne_2,
        title: relationships?.field_pack_souscription?.relationships?.field_option?.field_title,
      }
    }
  }
}

const Query = () =>
  useStaticQuery<IAllParagraphMiseEnAvantSousPack>(graphql`
  query AllParagraphMiseEnAvantSousPack {
    data: allParagraphMiseEnAvantSousPack {
      edges {
        node {
          drupal_id
          drupal_internal__revision_id
          field_plain_description
          field_title
          relationships {
            field_pack_souscription {
              relationships {
                field_option {
                  field_title
                  field_price
                  relationships {
                    field_list {
                      field_option
                      field_code_offre
                    }                    
                    field_button {
                      field_title
                    }
                  }
                  field_libelle_prix_ligne_1
                  field_libelle_prix_ligne_2
                  field_prix_ligne_2
                }
                field_pack {
                  field_included
                  field_price
                  field_title
                  field_code_offre
                  relationships {
                    field_services {
                      relationships {
                        field_service {
                          title
                        }
                      }
                      field_included
                    }
                    field_button {
                      field_title
                    }
                    field_image {
                      localFile {
                        childImageSharp {
                          fluid(maxHeight: 285) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  field_image {
                    alt
                  }
                  field_prix_ligne_2
                  field_libelle_prix_ligne_2
                  field_libelle_prix_ligne_1
                }
              }
            }
          }
        }
      }
    }
  }  
`)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
