import {
  replaceSirenAndCompanyName,
  replaceInvokeError,
  replaceCompanyId,
  replaceCompanyList,
  replaceEmployees,
  replaceCompanyContact,
  replaceSirenInvokeError
} from '../actions'
import { fetchCompanyBySiren, fetchCompanyByNameAndZipCode, fetchRecommendation } from '../services'
import { IEvent, ICompanyForm, ICompany, IContext, SIREN_404_ERROR } from '../constants'

import { pageWaitFor } from '../../../utils/tests/testActions'
import applyTestsActions from '../../../utils/tests/applyTestActions'

export const INITIAL_CONTEXT = {
  companyForm: {
    siren: '',
    companyName: '',
    zipCode: ''
  },
  companyList: undefined,
  companyId: undefined,
  invokeError: undefined,
  companyContact: {
    lastname: '',
    firstname: '',
    email: ''
  }
}

export const ACTIONS = {
  NEXT: 'NEXT',
  EDIT_QUESTION_1: 'EDIT_QUESTION_1',
  EDIT_QUESTION_3: 'EDIT_QUESTION_3',
  POST_AUTODIAGNOSTIC: 'POST_AUTODIAGNOSTIC'
}

const entreprise = {
  id: 'entreprise',
  initial: 'questionOne',
  states: {
    questionOne: {
      on: {
        [ACTIONS.NEXT]: [
          {
            target: 'fetchBySiren',
            cond: (_: any, { data }: IEvent<ICompanyForm>) => !!data && !!data.siren,
            actions: replaceSirenAndCompanyName
          },
          {
            target: 'fetchByNameAndZipCode',
            cond: (_: any, { data }: IEvent<ICompanyForm>) =>
              !!data && !!data.companyName && !!data.zipCode,
            actions: replaceSirenAndCompanyName
          }
        ]
      },
      meta: {
        questionIndex: 1,
        testActions: [{ selector: '[data-testid="question-1-form"]', action: pageWaitFor }],
        test: applyTestsActions
      }
    },
    fetchBySiren: {
      entry: replaceInvokeError(''),
      invoke: {
        src: fetchCompanyBySiren,
        onError: {
          target: 'questionOne',
          actions: replaceSirenInvokeError
        },
        onDone: [
          {
            target: 'questionThree',
            cond: (_: any, { data }: IEvent<ICompany[]>) => !!data && data.length === 1,
            actions: replaceCompanyId
          },
          {
            target: 'questionTwo',
            cond: (_: any, { data }: IEvent<ICompany[]>) => !!data && data.length > 1,
            actions: replaceCompanyList
          },
          {
            target: 'questionOne',
            actions: replaceInvokeError(SIREN_404_ERROR)
          }
        ]
      },
      meta: {
        questionIndex: 1,
        testActions: [{ selector: '[data-testid="question-1-form"]', action: pageWaitFor }],
        test: applyTestsActions
      }
    },
    fetchByNameAndZipCode: {
      entry: replaceInvokeError(''),
      invoke: {
        src: fetchCompanyByNameAndZipCode,
        onError: {
          target: 'questionOne',
          actions: replaceSirenInvokeError
        },
        onDone: [
          {
            target: 'questionThree',
            cond: (_: any, { data }: IEvent<ICompany[]>) => !!data && data.length === 1,
            actions: replaceCompanyId
          },
          {
            target: 'questionTwo',
            cond: (_: any, { data }: IEvent<ICompany[]>) => !!data && data.length > 1,
            actions: replaceCompanyList
          },
          {
            target: 'questionOne',
            actions: replaceInvokeError(SIREN_404_ERROR)
          }
        ]
      },
      meta: {
        questionIndex: 1,
        testActions: [{ selector: '[data-testid="question-1-form"]', action: pageWaitFor }],
        test: applyTestsActions
      }
    },
    questionTwo: {
      on: { [ACTIONS.NEXT]: { target: 'questionThree', actions: replaceCompanyId } },
      meta: {
        questionIndex: 2
      }
    },
    questionThree: {
      on: {
        [ACTIONS.NEXT]: { target: 'questionFour', actions: replaceEmployees },
        [ACTIONS.EDIT_QUESTION_1]: [
          {
            target: 'questionOne',
            cond: (context: IContext) => !context.entreprise.companyList
          },
          {
            target: 'questionOne'
          }
        ]
      },
      meta: {
        questionIndex: 3
      }
    },
    questionFour: {
      on: {
        [ACTIONS.POST_AUTODIAGNOSTIC]: {
          target: 'postAutodiagnostic',
          actions: replaceCompanyContact
        },
        [ACTIONS.EDIT_QUESTION_1]: [
          {
            target: 'questionOne',
            cond: (context: IContext) => !context.entreprise.companyList
          },
          {
            target: 'questionOne'
          }
        ],
        [ACTIONS.EDIT_QUESTION_3]: 'questionThree'
      },
      meta: {
        questionIndex: 4
      }
    },
    postAutodiagnostic: {
      entry: replaceInvokeError(''),
      invoke: {
        src: fetchRecommendation,
        onError: {
          target: 'questionFour',
          actions: replaceInvokeError(
            "Nous avons rencontré un problème technique lors de l'appel à la base Sirene permettant d'identifier votre entreprise.\nNous vous invitons à effacer votre saisie et compléter à nouveau les champs entreprise ou recommencer ultérieurement l'identification de vos besoins."
          )
        },
        onDone: [
          {
            target: '#recommendation'
          }
        ]
      },
      meta: {
        questionIndex: 4
      }
    }
  }
}

export default entreprise
