import React from 'react'
import Helmet from 'react-helmet'

interface Props {
  description?: string
  keywords?: string[]
  metatag?: Meta[]
  gtmId?: string
}

interface Meta {
  tag: string
  attributes: Attributes
}

interface Attributes {
  [key: string]: string
}

const writeSeoTags = (meta: Meta, index: number) => {
  const filteredAttributes: Attributes = Object.keys(meta.attributes)
    .filter((key): boolean => !!meta.attributes[key])
    .reduce((acc, key): Attributes => ({ ...acc, [key]: meta.attributes[key] }), {})

  switch (meta.tag) {
    case 'meta': {
      if (meta.attributes.name === 'title') {
        return <title key={`title_${index}`}>{filteredAttributes.content}</title>
      }
      return <meta key={`meta_${index}`} {...filteredAttributes}></meta>
    }
    case 'link':
      return <link key={`link_${index}`} {...filteredAttributes}></link>
    default:
      return null
  }
}
export default function Seo({ metatag = [], gtmId = '' }: Props) {
  return (
    <Helmet
      script={gtmId ? [{ type: 'text/javascript', innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');` }] : []}
    >
      <html lang="fr" />
      {metatag.map(writeSeoTags).filter(Boolean)}
      <meta name="google-site-verification" content="eW1TZCaKca72QYgF-gtIAFoDQfACMzNbJN5hY4IyzD4" />
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
    </Helmet>
  )
}
