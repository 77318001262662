import React from 'react'

import useFooterData from '../../../hooks/useFooterData'
import PresentationalFooter, { IProps } from '../../presentationals/Footer/Footer'
import { unwrapMaybe as _ } from '../../../paragraphs/utils'
import { IMenu, ILink, IImage } from '../../../types/drupal/index'

export default function Footer() {
  const data = useFooterData()
  const node = _('footerNode', data?.allFooter?.nodes[0])

  // some casts are made here because fields are forced on Drupal side,
  // so no need to over type-check
  const formattedData: IProps = {
    logo: _('logo', node.logo) as IImage,
    mobileLogo: _('mobileLogo', node.mobileLogo) as IImage,
    menu: _('menu', node?.menu).map(entry => ({
      item: _('item', entry?.item)
    })) as IMenu[],
    socialMediaLinks: _('socialMediaLinks', node?.socialMediaLinks).map(entry => ({
      icon: _('icon', entry?.icon) as IImage,
      link: _('link', entry?.link) as ILink
    }))
  }

  return <PresentationalFooter {...formattedData} />
}
