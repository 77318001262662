import React from 'react'

import './Header.css'

import { IButtonItem, IImage, IMenu } from '../../../types/drupal'
import HeaderDesktop from '../HeaderDesktop/HeaderDesktop'
import HeaderMobile from '../HeaderMobile/HeaderMobile'
export interface IProps {
  gtmId: string
  mobileLogo: IImage
  logo: IImage
  buttons: IButtonItem[]
  menu: IMenu[]
  onScrollButton: {
    href?: string
    title: string
    target?: string
    actionId?: string
  } | null
}

export default function Header(props: IProps) {
 const { gtmId } = props
  return (
    <>
      {gtmId && (
        <noscript dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }} />
      )
      }

      <HeaderMobile className="lg:hidden" {...props} />
      <HeaderDesktop className="hidden lg:block" {...props} />
    </>
  )
}
