import { Page } from 'puppeteer'

export type ITestAction = (context: any | Page, selector: string) => Promise<any>

/**
 * Call to `page.click` on a given `selector`
 * @param page - The Puppeteer Page to call click on
 * @param selector - The selector to dispatch click event to
 */
export const pageClick: ITestAction = (page: Page, selector: string) => page.click(selector)

/**
 * Call to `page.waitFor` with a given `selector` with a default timeout of 1 second
 * @param page - The Puppeteer Page to call waitFor on
 * @param selector - The selector to wait for
 */
export const pageWaitFor: ITestAction = async (page: Page, selector: string) =>
  page.waitFor(selector, {
    timeout: 1000
  })
