import { replaceContact, replaceInvokeError } from "./actions"
import { POSTFormulaireContact } from "./services"

export const INITIAL_CONTEXT = {
  civilite: '',
  lastname: '',
  firstname: '',
  email: '',
  telephone: '',
  siren: '',
  companyName: '',
  codePostal: '',
  prospectionEmail: false,
  commentaire: undefined,
  invokeError: undefined,
  checked: false
}

export const ACTIONS = {
  INIT_ACTION: 'init',
  POST_CONTACT: 'POST_CONTACT'
}
const contact = {
  id: 'contact',
  initial: 'contactPost',
  states: {
    contactPost: {
      on: {
        [ACTIONS.POST_CONTACT]: {
          target: 'POSTFormulaireContact',
          actions: replaceContact
        }
      }
    },
    POSTFormulaireContact: {
      invoke: {
        src: POSTFormulaireContact,
        onError: {
          actions: replaceInvokeError(
            "error"
          )
        },
        onDone: [
          {
            actions: replaceInvokeError(
              "done"
            )
          }
        ]
      }
    }
  }
}

export default contact
