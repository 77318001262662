/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import {
  IAllContactFloatingActionButtonQuery,
  IParagraph__Contact_Floating_Action_Button
} from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = (): IProps => {
  return {}
}

const Query = () =>
  useStaticQuery<IAllContactFloatingActionButtonQuery>(graphql`
    query ContactFloatingActionButton {
      data: allParagraphContactFloatingActionButton {
        edges {
          node {
            drupal_id
            drupal_internal__revision_id
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
