import React from 'react'

import useHeaderData from '../../../hooks/useHeaderData'
import { unwrapMaybe as _ } from '../../../paragraphs/utils'
import { IButtonItem, IImage, IMenu } from '../../../types/drupal'
import PresentationalHeader, { IProps } from '../../presentationals/Header/Header'

const Header: React.FC = () => {
  const data = useHeaderData()
  const node = data.allHeader.nodes[0]

  const formattedData: IProps = {
    gtmId: _('gtmId', node.gtmId) as string,
    logo: _('logo', node.logo) as IImage,
    mobileLogo: _('mobileLogo', node.mobileLogo) as IImage,
    menu: _('menu', node?.menu).map(entry => ({
      item: _('item', entry?.item),
      children: _('children', entry?.children)
    })) as IMenu[],
    buttons: _('buttons', node.buttons).map(button => ({
      item: _('item', button?.item)
    })) as IButtonItem[],
    onScrollButton: node.mobileButtonLink
      ? {
        ...node.mobileButtonLink,
        title: _('title', node.mobileButtonLink.title)
      }
      : null
  }

  return <PresentationalHeader {...formattedData} />
}

export default Header
