import cs from 'classnames'
import React, { useState, useCallback, useEffect } from 'react'
import { Formik, Form, Field, useFormik, useFormikContext } from 'formik'
import TextField from '../formik/TextField/TextField'
import Button, { BUTTON_VARIANTS, ICON_POSITIONS } from '../presentationals/Button/Button'
import ModalInfo from '../modal/modalInfo'
import ModalAction from '../modal/modalAction'
import TextAreaField from '../formik/TextAreaField/TextAreaField'
import styles from '../../paragraphs/contact/Component.module.css'
import Icon, { ICONS } from '../presentationals/Icon'
import { kebabCase } from 'lodash'
import './ContactForm.css'
import CheckboxField from '../formik/CheckboxField/CheckboxField'
import CheckboxFormField from '../formik/CheckboxFormField/CheckboxFormField'

interface IProps {
  validator: any
  onSubmit: (data: any) => void
  consent: any
  dataTestId?: string
  // 0 = entreprise    1 = particulier
  profil: number
  initialValues?: any
  context?: any
  isFetching: boolean,
  popinSucces: any,
  popinEchec: any
}

const ContactForm = ({ validator, onSubmit, dataTestId, consent, profil, initialValues, context, isFetching, popinSucces, popinEchec }: IProps) => {
  const [showMention, setShowMention] = useState(false);

  const formattedOnSubmit = useCallback(
    (values) => {
      onSubmit({ data: values });
    },
    [onSubmit]
  )

  const modalError = {
    titleColor: popinEchec[0].field_title_color,
    title: popinEchec[0].field_title,
    content: popinEchec[0].field_rich_description.value,
    contentColor: popinEchec[0].field_color,
    buttonTitle: 'Fermer'
  }

  const modalRedirect = {
    titleColor: popinSucces[0].field_title_color,
    title: popinSucces[0].field_title,
    content: popinSucces[0].field_rich_description.value,
    contentColor: popinSucces[0].field_color,
    buttonTitle: "Retour à l'accueil",
    link: '/'
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
      validationSchema={validator}
      enableReinitialize={true}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid, errors } = props

        // in order to show errors, keeping state of fields that have lost focus already
        const [blurredFields, setBlurredFields] = useState({
          civilite: false,
          lastname: false,
          firstname: false,
          email: false,
          telephone: false,
          companyName: false,
          codePostal: false,
          commentaire: true
        })

        const handleChange = useCallback(e => setFieldValue(e.target.name, e.target.value), [
          setFieldValue
        ])

        const handlePressEnter = useCallback(() => isValid && handleSubmit(), [handleSubmit])
        const handleBlur = useCallback(
          e => setBlurredFields({ ...blurredFields, [e.target.name]: true }),
          [blurredFields, setBlurredFields]
        )

        const reinitForm = () => {
          window.location.reload(false);
        }

        //const [] = React.useState([])

        return (
          <Form>
            <fieldset>
              <div data-testid={dataTestId}>
                {profil == 0 && <TextField
                  name="companyName"
                  className="mb-4"
                  placeholder="Raison sociale"
                  label="Raison Sociale"
                  value={values['companyName']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.companyName ? (errors['companyName'] as string) : undefined}
                  disabled={isFetching && !context.contact.invokeError}
                />}

                {profil == 1 && <div className="mb-4 mr-6 w-full">
                  <label htmlFor={`input-${kebabCase('Civilité')}`} className="font-bold font-brand text-purple-dark-500">Civilité</label>
                  <Field
                    name="civilite"
                    component="select"
                    placeholder="Civilite"
                    label="Civilité"
                    value={values['civilite']}
                    onChange={handleChange}
                    onPressEnter={handlePressEnter}
                    onBlur={handleBlur}
                    disabled={isFetching && !context.contact.invokeError}
                    className={values.civilite ? 'w-full px-4 py-3 mt-2 border rounded-lg focus:outline-none text-black-400 disabled-select' : blurredFields.civilite ? 'w-full px-4 py-3 mt-2 border rounded-lg focus:outline-none text-gray-400 disabled-select border-red-600' : 'w-full px-4 py-3 mt-2 border rounded-lg focus:outline-none text-gray-400 disabled-select'}
                    error={blurredFields.civilite ? (errors['civilite'] as string) : undefined}
                  >
                    <option disabled value="">Saisissez votre civilité </option>
                    <option className="text-black" value="Monsieur">Monsieur</option>
                    <option className="text-black" value="Madame">Madame</option>
                  </Field>
                  {blurredFields.civilite && (
                    <p className="mt-2 italic text-red-600">{blurredFields.civilite ? (errors['civilite'] as string) : undefined}</p>)}
                </div>}

                <TextField
                  name="lastname"
                  className="mb-4"
                  placeholder="Nom"
                  label="Nom"
                  value={values['lastname']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.lastname ? (errors['lastname'] as string) : undefined}
                  disabled={isFetching && !context.contact.invokeError}
                />

                <TextField
                  name="firstname"
                  className="mb-4"
                  label="Prénom"
                  placeholder="Prénom"
                  value={values['firstname']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.firstname ? (errors['firstname'] as string) : undefined}
                  disabled={isFetching && !context.contact.invokeError}
                />

                <TextField
                  name="email"
                  className="mb-4"
                  label="Adresse email"
                  placeholder="email@exemple.fr"
                  value={values['email']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  error={blurredFields.email ? (errors['email'] as string) : undefined}
                  disabled={isFetching && !context.contact.invokeError}
                />
                <div className="mb-4 sm:mb-0 sm:flex">
                  <TextField
                    name="telephone"
                    className="mb-4 sm:mr-4 sm:w-3/6"
                    label="Téléphone mobile"
                    placeholder="0600000000"
                    value={values['telephone']}
                    onChange={handleChange}
                    onPressEnter={handlePressEnter}
                    onBlur={handleBlur}
                    error={blurredFields.telephone ? (errors['telephone'] as string) : undefined}
                    disabled={isFetching && !context.contact.invokeError}
                  />

                  <TextField
                    name="codePostal"
                    className="sm:w-3/6"
                    placeholder="01000"
                    label="Code postal"
                    value={values['codePostal']}
                    onChange={handleChange}
                    onPressEnter={handlePressEnter}
                    onBlur={handleBlur}
                    error={blurredFields.codePostal ? (errors['codePostal'] as string) : undefined}
                    disabled={isFetching && !context.contact.invokeError}
                  />
                </div>

                <TextAreaField
                  name="commentaire"
                  className=""
                  placeholder="Écrivez ici le détail de votre demande."
                  label="Commentaire"
                  value={values['commentaire']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isFetching && !context.contact.invokeError}
                />

                {context.contact.invokeError === 'error' && (
                  <div className="mb-2 italic text-red-500 whitespace-pre-wrap md:mb-4">
                    <ModalInfo {...modalError} onAfterClose={() => reinitForm()}></ModalInfo>
                  </div>
                )}

                {context.contact.invokeError === 'done' && (
                  <div className="mb-2 italic text-red-500 whitespace-pre-wrap md:mb-4">
                    <ModalAction {...modalRedirect} onAfterClose={() => reinitForm()}></ModalAction>
                  </div>
                )}
                {profil == 1 && <>
                  <div className="mt-6 flex mb-2">
                    <CheckboxFormField
                      checked={values['checked']}
                      onClick={() => { values['checked'] = !values['checked'] }}
                      onChange={() => handleChange}
                      text={consent.legend}
                      disabled={isFetching && !context.contact.invokeError}
                    />
                  </div>
                  <div className="lg:ml-6">
                    <span onClick={() => {
                      setShowMention(!showMention)
                    }}>
                      <Icon
                        className={cs("inline mr-1", showMention ? 'rotateChevron' : '')}
                        name={ICONS.ChevronOrange}
                        width="15"
                        height="15"
                      />
                      <span className="btn-link">Les mentions légales</span>
                    </span>
                  </div>
                  <Button
                    className="flex items-center lg:ml-6"
                    variant={BUTTON_VARIANTS.LINK}
                    iconPosition={ICON_POSITIONS.LEFT}
                    iconClassname="text-orange-500 ml-auto"

                  > {consent?.button?.title} </Button>
                  {showMention && <p className="lg:ml-6 w-full text-xs" dangerouslySetInnerHTML={{ __html: consent.mention?.value }}></p>}

                </>
                }
                <div className="text-center">
                  <Button
                    className={cs("relative flex justify-center my-8 lg:w-3/6 xl:w-1/3", (!isValid || isFetching && !context.contact.invokeError) ? 'btn-hidden ' : '')}
                    dataTestId="trigger-next-button"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    onClick={() => {
                      handleSubmit();
                    }}
                    loading={isFetching && !context.contact.invokeError}
                    iconPosition={isFetching && !context.contact.invokeError ? ICON_POSITIONS.RIGHT : undefined}
                    analyticsDataLayer={{
                      buttonLabel: 'Je valide'
                    }}
                    iconClassname="absolute right-0 top-0 mr-4 mt-1 transform scale-75"
                    disabled={!isValid || isFetching && !context.contact.invokeError}
                    ariaLabel="Je valide"
                  >
                    Je valide
                  </Button>
                </div>
              </div>
            </fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContactForm
