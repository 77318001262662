import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'

import SliderField from '../../../../../components/formik/SliderField/SliderField'
import validationSchema, { ACCEPTED_VALUES } from './Question4Form.validationSchema'
import Question from '../../../../../components/presentationals/AutoDiagnostic/Question/Question'
import cs from 'classnames'
import Button, { BUTTON_VARIANTS } from '../../../../../components/presentationals/Button/Button'

interface IProps {
  onEdit?: () => void
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  showForm: boolean
  disableEditionButton: boolean
}

const Question4Form = ({
  onSubmit,
  onEdit,
  dataTestId,
  initialValues,
  showForm,
  disableEditionButton
}: IProps) => {
  const formattedOnSubmit = useCallback(
    ({ employeesWithChild }) => onSubmit({ data: employeesWithChild }),
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid } = props

        return (
          <div data-testid={dataTestId}>
            <Question
              className={cs({ 'shadow-lg': showForm })}
              onClick={showForm && !disableEditionButton ? undefined : onEdit}
              editBtnDataTestId="question-4-edit-button"
              text={
                <b aria-hidden={true}>
                  4/4. Quelle est la proportion de vos salariés ayant des enfants ?
                </b>
              }
            >
              {showForm && (
                <Form className="pt-20 pb-8">
                  <SliderField
                    name="4/4. Quelle est la proportion de vos salariés ayant des enfants ?"
                    handleDirection="top"
                    acceptedValues={ACCEPTED_VALUES}
                    onChange={newValue => setFieldValue('employeesWithChild', newValue)}
                    value={values['employeesWithChild']}
                    disabled={disableEditionButton}
                  />

                  <ul className="flex items-center justify-between pt-12 text-xs text-gray-700 font-brand md:text-base">
                    <li>Aucun</li>
                    <li>Tous</li>
                  </ul>
                </Form>
              )}
            </Question>

            {showForm && (
              <div className="mt-10 text-center">
                <Button
                  tabIndex={0}
                  className="flex justify-center w-full md:w-2/5"
                  dataTestId="trigger-next-button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  onClick={handleSubmit}
                  disabled={!isValid || disableEditionButton}
                  ariaLabel="Suivant"
                  analyticsDataLayer={{
                    buttonLabel: "Suivant"
                  }}
                >
                  Suivant
                </Button>
              </div>
            )}
          </div>
        )
      }}
    </Formik>
  )
}

export default Question4Form
