import React from 'react'
import cs from 'classnames'

interface IProps {
  tick: { percent: number; value: number }
  actualValue: number
}

const SliderTick = ({ tick: { percent, value }, actualValue }: IProps) => (
  <div
    style={{ left: `${percent}%` }}
    className={cs(
      'absolute w-2 h-2  rounded-full cursor-pointer slider__tick',
      value >= actualValue ? 'bg-gray-300' : 'bg-orange-500'
    )}
  ></div>
)

export default SliderTick
