import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import React from 'react'

import Button, { BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import { IDrupalButton } from '../../types/drupal'
import { IImage } from '../../types/gatsby/images'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

interface IItem {
  title?: string
  description: string
}

export interface IProps {
  title?: string
  image?: IImage
  button?: IDrupalButton
  annotation?: string
  list: IItem[]
}

export default function Component({ title, image, button, annotation, list }: IProps) {
  // splitting the `list` prop in two to facilite two columns layout
  const splittedList =
    list.length === 1
      ? [list]
      : [
        list.slice(0, Math.ceil(list.length / 2)),
        list.slice(Math.ceil(list.length / 2), list.length)
      ]

  return (
    <section className="mt-8 mb-12 bg-white">
      <div className="container mx-auto">
        {title && (
          <h2 className="mb-5 text-3xl font-medium leading-none text-center md:mb-12 font-brand text-purple-dark-500">
            {title}
          </h2>
        )}

        {image && <GatsbyImage className="mb-8" {...image} />}

        <div className="md:flex">
          {splittedList.map((sublist, sublistIndex) => (
            <div
              key={sublistIndex}
              className={cs(
                'flex flex-col md:pr-12',
                splittedList.length === 2 ? 'md:w-1/2' : 'w-full'
              )}
            >
              {sublist.map((item, itemIndex) => (
                <div key={itemIndex}>
                  {item.title && (
                    <h2 className="mb-1 text-lg font-bold font-brand text-purple-dark-500">
                      {item.title}
                    </h2>
                  )}
                  <div
                    className="mb-6 rich-contribution md:text-base"
                    dangerouslySetInnerHTML={{ __html: item.description.replace('"/sites', `"${process.env.GATSBY_DRUPAL_URL}/sites`) }}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        {button && (
          <div className="flex justify-center text-center md:mt-12">
            <Button {...buttonPropsFormatter(button)} />
          </div>
        )}

        {annotation && <p className="py-1 text-sm text-center text-gray-500">{annotation}</p>}
      </div>
    </section>
  )
}
