import { object, string } from 'yup'
import differenceInYears from "date-fns/differenceInYears";

export const NUMBERPHONE_REGEX = /^((\+)33|0)[1-9](\d{2}){4}$/

export const isPhoneNumber = (value: string) => NUMBERPHONE_REGEX.test(value)

export default object().shape(
  {
    nom: string().required('Merci de renseigner ce champ'),
    prenom: string().required('Merci de renseigner ce champ'),
    email: string().required('Merci de renseigner ce champ').email("Le format de l’adresse mail fourni est incorrect"),
    adresse: string().required('Merci de renseigner ce champ'),
    civilite: string().required('Merci de renseigner ce champ'),
    dateDeNaissance: string().required('Merci de renseigner ce champ').test({
      name: 'age',
      message: 'La date saisie ne peut désigner une personne mineure',
      test: value => {
        value
        return differenceInYears(new Date(), new Date(value)) >= 18;
      }
    }),
    telephone: string().required('Merci de renseigner ce champ').test({
      name: 'numberPhone',
      message: 'Merci de renseigner un numéro de téléphone valide',
      test: value => !value || isPhoneNumber(value)
    }),
  },
)

