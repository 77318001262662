export default function getCompanyTypeFromSize(size?: number) {
  if (typeof size === 'undefined') return undefined

  switch (true) {
    case size <= 15:
      return 'TPE'
    case size <= 49:
      return 'PME'
    default:
      return 'ETI'
  }
}
