import cs from 'classnames'
import React, { useCallback, useState } from 'react'
import SouscriptionComp from '../../components/Souscription/SouscriptionComp'
import { IDrupalButton } from '../../types/drupal'
import validationSchema from '../../components/Souscription/SouscriptionForm.validationSchema'
import SouscriptionOptionsSelected from '../SouscriptionOptionsSelected/Component'



export interface IProps {

  /** The title that will be displayed using an H1 */
  title: string
  /** Conditions générales de vente */
  cgv?: string
  /** Button */
  button?: IDrupalButton,

}

export default function Component({
  title,
  cgv,
  button,

}: IProps) {

  const [optionChoisie, setFieldValue] = useState()
  const onItemClick = useCallback(data => {
    setFieldValue(data);
  }, [optionChoisie]);

  let params = new URLSearchParams();

  if (typeof window !== "undefined") {

    params = new URLSearchParams(location.search);
    const offre = params.get("offre");

    if (offre === '' || offre === null) {
      window.location = '/';
      return null;
    }
  }



  return (
    <>
      <section className={cs('bg-white bg-circle-bottom bg-circle-left bg-circle-green bg-green-dark-100')}>
        <div className="">
          <div className="container mx-auto">
            <h1
              className="mb-3 text-2xl pt-16 font-medium leading-none text-center whitespace-pre-line text-3xl rich-contribution font-brand text-purple-dark-500">
              {title}
            </h1>
            <div className="w-12 h-1 mx-auto bg-orange-500 rounded mb-12 md:mb-16"></div>

            <SouscriptionOptionsSelected onItemClick={onItemClick} />

            <SouscriptionComp validator={validationSchema} content={{ button: button, cgv: cgv, optionChoisie: optionChoisie }} />

          </div>
        </div>
      </section>
    </>
  )
}

