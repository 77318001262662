import React from 'react'

import styles from './Component.module.css'

interface Props {
  message?: string
  typename: string
}

export default function Component({ message }: Props) {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{message}</h1>
    </div>
  )
}
