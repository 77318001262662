import React, { ChangeEvent, KeyboardEvent, ClipboardEvent, FormEvent } from 'react'
import cs from 'classnames'
import { kebabCase } from 'lodash'
import './TextField.css'

interface IProps {
  id?: string
  className?: string
  dataTestId?: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (e: FormEvent) => void
  name?: string
  label?: string
  error?: string
  placeholder?: string
  pattern?: string
  maxLength?: number
  disabled?: boolean
  trimWhitespace?: boolean
  tabIndex?: number
  showErreurIcon?: boolean
  required?: boolean
  optionnel?: boolean
}

const TextField = ({
  className,
  dataTestId,
  value,
  onChange,
  onPressEnter,
  onBlur,
  name,
  label,
  error,
  placeholder,
  pattern,
  maxLength,
  id = `input-${kebabCase(label)}`,
  tabIndex = 0,
  disabled = false,
  trimWhitespace = false,
  required = false,
  showErreurIcon = false,
  optionnel = false,
}: IProps) => (
  <div className={className} data-testid={dataTestId}>
    <label htmlFor={id} className="font-bold font-brand text-purple-dark-500">
      {label}

    </label>
    {optionnel &&
      <span className="float-right text-xs font-sans disabled-background font-normal mt-2 text-purple-dark-500">optionnel</span>
    }
    <input
      id={id}
      className={cs('w-full px-4 py-3 mt-2 border rounded-lg disabled-input focus:outline-none', {
        "border-red-600 ": error, "errorIcon": showErreurIcon && error
      })}
      tabIndex={disabled ? -1 : tabIndex}
      title={placeholder}
      aria-label={label}
      name={name}
      type="text"
      placeholder={placeholder}
      pattern={pattern}
      maxLength={maxLength}
      disabled={disabled}
      value={value}
      onPaste={(event: ClipboardEvent<HTMLInputElement>) => {
        if (trimWhitespace)
          (event.target as any).value = event.clipboardData.getData('text').replace(/ /g, '')

        onChange(event as any)
      }}
      onInput={({ target }: ChangeEvent<HTMLInputElement>) =>
        trimWhitespace && (target.value = target.value.replace(/ /g, ''))
      }
      onChange={onChange}
      onKeyDown={e => e.key === 'Enter' && typeof onPressEnter !== 'undefined' && onPressEnter(e)}
      onBlur={onBlur}
      required={required}
      aria-required={required}
      aria-invalid={!!error}
      aria-describedby={error}
    />
    {error && <p className="mt-2 italic text-red-600">{error}</p>}
  </div>
)

export default TextField
