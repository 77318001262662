import { graphql, useStaticQuery } from 'gatsby'

import { IHeaderQuery } from '../graphql.types'

const useHeaderData = () => {
  const data = useStaticQuery<IHeaderQuery>(
    graphql`
      query Header {
        allHeader {
          nodes {
            gtmId
            logo {
              alt
              src
              image {
                childImageSharp {
                  fixed(height: 64) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            mobileLogo {
              alt
              src
              image {
                childImageSharp {
                  fixed(height: 40) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            menu {
              children {
                item {
                  id
                  label
                  weight
                  link {
                    href
                    target
                    title
                  }
                }
                children {
                  item {
                    id
                    label
                    weight
                    link {
                      href
                      target
                      title
                    }
                  }
                }
              }
              item {
                id
                label
                link {
                  href
                  target
                  title
                  description
                }
                weight
              }
            }
            buttons {
              item {
                id
                label
                link {
                  href
                  target
                  title
                }
                weight
              }
            }
          }
        }
      }
    `
  )

  return data
}

export default useHeaderData
