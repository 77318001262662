/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import { IAllTitleImgDescButtonQuery, IParagraph__Title_Img_Desc_Button } from '../../graphql.types'
import { unwrapMaybe as _ } from '../utils'
import Component, { IProps } from './Component'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_image,
  relationships
}: IParagraph__Title_Img_Desc_Button): IProps => {
  return {
    title: _('title', field_title),
    description: _('description', field_plain_description),
    image: {
      alt: _('alt', field_image?.alt),
      ..._('image', relationships?.field_image?.localFile?.childImageSharp)
    },
    button: {
      link: {
        href:
          relationships?.field_button?.field_link?.uri_alias ||
          relationships?.field_button?.field_link?.uri
      },
      title: _('title', relationships?.field_button?.field_title),
      target: relationships?.field_button?.field_link_target,
      action: relationships?.field_button?.field_button_action_id,
    }
  }
}

const Query = () =>
  useStaticQuery<IAllTitleImgDescButtonQuery>(graphql`
    query AllTitleImgDescButton {
      data: allParagraphTitleImgDescButton {
        edges {
          node {
            id
            drupal_internal__revision_id
            field_title
            field_plain_description
            field_image {
              alt
            }
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              field_button {
                field_title
                field_link_target
                field_link {
                  uri_alias
                  uri
                }
                field_button_action_id
              }
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
