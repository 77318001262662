import React from 'react'
import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import Slider from 'react-slick'
import breakpoints from '../../assets/css/breakpoints'
import Button from '../../components/presentationals/Button/Button'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IDrupalButton } from '../../types/drupal'
import { IColor } from '../../types/gatsby/colors'
import { IImage } from '../../types/gatsby/images'
import { IAnalyticsEvent } from '../../utils/analytics/analytics'
import { SlideCustomArrow } from '../../utils/paragraphs/SlideCustomArrow'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

interface IQuote {
  /** The circle image */
  avatar: IImage
  /** The quote text */
  quote: string
  /** Informations about the author */
  author: string

  button?: IDrupalButton
}

export interface IProps {
  // A color to apply to the background - It needs to be one key of tailwind colors
  backgroundColor: IColor
  /** The title that will be displayed using an H1 */
  title: string
  /** The list of elements to display */
  list: IQuote[]
}

export default function Component({ title, backgroundColor, list }: IProps) {
  const isDesktopLayout = useBreakpoint(breakpoints.lg)
  const SLIDER_CONFIG = {
    dots: true,
    infinite: false,
    arrows: isDesktopLayout,
    nextArrow: <SlideCustomArrow />,
    prevArrow: <SlideCustomArrow isPrev={true} />
  }

  return (
    <section
      className={cs(
        'bg-circle bg-circle-top bg-circle-left',
        `bg-circle-${backgroundColor}`,
        `bg-${backgroundColor}-100`
      )}
    >
      <div className="container pt-16 pb-12 mx-auto ">
        <h2 className="mb-10 text-3xl font-medium leading-none text-center font-brand text-purple-dark-500">
          {title}
        </h2>
        <Slider className="mb-12 pb-4 md:mb-6" {...SLIDER_CONFIG}>
          {list.map((quote, index) => (
            <div key={index} className="outline-none">
              <div className="flex flex-col items-center mx-auto md:flex-row md:items-start xl:w-2/3">
                <div className="w-fit">
                  <GatsbyImage className="mb-6 rounded-full md:mr-10 " {...quote.avatar} />
                </div>
                <figure className="ml-4">
                  <blockquote>
                    <p className="px-8 font-sans text-lg italic leading-snug text-center md:px-0 md:text-left md:text-xl text-purple-dark-500">
                      ‘’ {quote.quote} ‘’
                    </p>
                  </blockquote>

                  <div className="w-8 h-1 mx-auto my-5 bg-orange-500 rounded-lg md:mx-0" />

                  <figcaption className="font-sans text-sm leading-tight text-center md:text-base text-purple-dark-500 md:text-left">
                    {quote.author}
                  </figcaption>
                  {quote.button && (
                    <div className="flex justify-center md:justify-start text-center mt-8">
                      <Button
                        analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                        analyticsDataLayer={{
                          buttonLabel: quote.button.title,
                          zoneCategory: 'body'
                        }}
                        {...buttonPropsFormatter(quote.button)}
                      />
                    </div>
                  )}
                </figure>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
