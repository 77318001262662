import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import React, { useState } from 'react'

import { IImage } from '../../types/gatsby/images'
import './Component.css'

interface IItem {
  /** The square image of the item */
  image: IImage
  /** The bold title */
  title?: string
  /** The text description of the item */
  description?: string
  description_formattee?: string
}

interface ITab {
  /** Text displayed into the clickable tab */
  tabLabel: string
  /** The H3 title at the top of the tab body */
  title?: string
  /** List of image + title + description in the body of the tab */
  list: IItem[]
}

export interface IProps {
  /** The title that will be displayed using an H2 */
  title: string
  /** Clickable tabs (maximum 2) */
  tabs: ITab[]
}

interface TitleProps {
  tag: 'h3' | 'h4'
  className: string
  children: any
}

const Title = ({ tag, ...props }: TitleProps) => {
  const TagName = tag
  return <TagName {...props} />
}

export default function Component({ title, tabs }: IProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  return (
    <section className="tabbable_lists my-8 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="mb-12 text-3xl font-medium leading-none font-brand text-purple-dark-500">
          {title}
        </h2>
        {tabs.length > 1 && (
          <div className="flex justify-center">
            {tabs.map((tab, index) => (
              <div
                key={tab.tabLabel}
                className="flex flex-col items-center justify-center w-1/2 w-full mb-12 md:w-1/3"
              >
                <button
                  id={`tab-${tab.tabLabel}`}
                  onClick={() => setSelectedTab(index)}
                  className={cs(
                    'w-full focus:outline-none pb-2 font-bold',
                    { 'text-orange-500': selectedTab === index },
                    { 'text-gray-500': selectedTab !== index }
                  )}
                  aria-expanded={selectedTab === index}
                  aria-controls={`tab-content-${tab.tabLabel}`}
                >
                  {tab.tabLabel}
                </button>
                <div
                  className={cs('w-full self-end', {
                    'border-b-4 border-orange-500': selectedTab === index,
                    'border-b h-1 border-gray-400': selectedTab !== index
                  })}
                />
              </div>
            ))}
          </div>
        )}

        {tabs.map((tab, index) => (
          <div
            role="region"
            aria-labelledby={`tab-${tab.tabLabel}`}
            key={tab.tabLabel}
            id={`tab-content-${tab.tabLabel}`}
            className={cs({ hidden: selectedTab !== index })}
          >
            {tab.title && (
              <h3 className="px-16 mb-8 text-3xl font-brand text-purple-dark-500">{tab.title}</h3>
            )}

            <div className="flex flex-col justify-center md:flex-row md:items-start flex-wrap" >
              {tab.list.map((entry, index) => (
                <div
                  key={index}
                  className={`flex flex-col justify-center mb-8 md:px-8 md:w-1/${Math.min(tab.list.length, 5)}`}
                >
                  <div className="h-32 mb-4">
                    <GatsbyImage className={'gatsby-image-w-full'} {...entry.image} />
                  </div>
                  {entry.title && (
                    <Title
                      tag={tab.title ? 'h4' : 'h3'}
                      className="font-sans font-extrabold leading-normal text-center text-purple-dark-500"
                    >
                      {entry.title}
                    </Title>
                  )}
                  {!entry.description_formattee && (
                    <p className="font-sans text-sm leading-normal text-gray-600">
                      {entry.description}
                    </p>
                  )}
                  {entry.description_formattee && (
                    <div
                      className="font-sans text-sm leading-normal text-gray-600"
                      dangerouslySetInnerHTML={{ __html: entry.description_formattee }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
