import './HeaderMobile.css'

import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import Link from 'gatsby-link'
import React, { useLayoutEffect, useMemo, useReducer, useRef } from 'react'

import profile from '../../../assets/svg/profile.svg'
import { IButtonItem, IImage, IMenu } from '../../../types/drupal'
import { IAnalyticsEvent, sendClicZonePageAnalytics } from '../../../utils/analytics/analytics'
import buttonPropsFormatter from '../../../utils/paragraphs/buttonPropsFormatter'
import Button, { BUTTON_VARIANTS, ICON_POSITIONS } from '../Button/Button'
import Icon, { ICONS } from '../Icon'

interface IProps {
  className?: string
  mobileLogo: IImage
  buttons: IButtonItem[]
  menu: IMenu[]
  onScrollButton: {
    href?: string
    title: string
    target?: string
    actionId?: string
  } | null
}

interface IState {
  isMenuOpen: boolean
  selectedEntry: IMenu | null
  isProfileOpen: boolean
  showScrollButton: boolean
}

enum Actions {
  MENU_OPENED,
  MENU_CLOSED,
  MENU_BACK,
  MENU_ENTRY_SELECTED,
  PROFILE_OPENED,
  PROFILE_CLOSED,
  PAGE_SCROLLED_DOWN,
  PAGE_HIT_TOP
}

const initialState: IState = {
  isMenuOpen: false,
  selectedEntry: null,
  isProfileOpen: false,
  showScrollButton: false
}

const reducer = (
  state: IState,
  action: { type: Actions; payload?: { selectedEntry: IMenu } }
): IState => {
  switch (action.type) {
    case Actions.MENU_OPENED:
      return { ...state, isMenuOpen: true, isProfileOpen: false, selectedEntry: null }
    case Actions.MENU_CLOSED:
      return { ...state, isMenuOpen: false, selectedEntry: null }
    case Actions.MENU_BACK:
      return { ...state, selectedEntry: null }
    case Actions.MENU_ENTRY_SELECTED:
      return {
        ...state,
        isMenuOpen: true,
        selectedEntry: typeof action.payload !== 'undefined' ? action.payload.selectedEntry : null
      }
    case Actions.PROFILE_OPENED:
      return { ...state, isMenuOpen: false, isProfileOpen: true, selectedEntry: null }
    case Actions.PROFILE_CLOSED:
      return { ...state, isProfileOpen: false }
    case Actions.PAGE_SCROLLED_DOWN:
      return { ...state, showScrollButton: true, isProfileOpen: false }
    case Actions.PAGE_HIT_TOP:
      return { ...state, showScrollButton: false }
  }
}

export default function HeaderMobile({
  className,
  mobileLogo,
  menu,
  buttons,
  onScrollButton
}: IProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const hideScrollButton = useMemo(
    () =>
      /* eslint-disable */
      typeof location !== 'undefined' &&
      onScrollButton &&
      onScrollButton.href === location.pathname,
    /* eslint-enable */
    []
  )

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0 && onScrollButton && !hideScrollButton && !state.showScrollButton)
        dispatch({ type: Actions.PAGE_SCROLLED_DOWN })
      else dispatch({ type: Actions.PAGE_HIT_TOP })
    }

    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  const refMenu = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const handleResize = () => {
      let hauteur = refMenu?.current?.clientHeight;
      if (hauteur != 0) {
        document.documentElement.style.setProperty("--menu-height", hauteur + 'px');
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize();
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <header ref={refMenu}
      className={cs(className, 'fixed w-full bg-white top-0 z-30')}
      onClick={() => dispatch({ type: Actions.PROFILE_CLOSED })}
    >
      <div className="flex items-center justify-between h-16 p-3 border-b border-gray-100 shadow">
        {!state.isMenuOpen && (
          <button
            className="text-orange-500 cursor-pointer"
            onClick={() => dispatch({ type: Actions.MENU_OPENED })}
            aria-label="Ouvrir le menu"
          >
            <Icon name={ICONS.Burger} width="32" height="32" />
          </button>
        )}
        {state.isMenuOpen && !state.selectedEntry && (
          <button
            className="text-orange-500 cursor-pointer"
            onClick={() => dispatch({ type: Actions.MENU_CLOSED })}
            aria-label="Fermer le menu"
          >
            <Icon name={ICONS.Cross} width="32" height="32" />
          </button>
        )}
        {state.isMenuOpen && state.selectedEntry && (
          <button
            tabIndex={0}
            className="text-orange-500 cursor-pointer"
            onClick={() => dispatch({ type: Actions.MENU_BACK })}
            aria-label="Retour au menu précédent"
          >
            <Icon className="transform scale-75" name={ICONS.Chevron} width="24" height="24" />
          </button>
        )}

        {state.selectedEntry ? (
          <p className="text-xl font-bold text-purple-dark-500 font-brand">
            {state.selectedEntry.item.label}
          </p>
        ) : (
          mobileLogo.image.childImageSharp && (
            <Link
              className={cs({
                'mr-auto ml-5': onScrollButton && !hideScrollButton && state.showScrollButton
              })}
              to="/"
              aria-label="Page d'accueil"
            >
              <GatsbyImage
                alt={mobileLogo.alt}
                className="h-10 md:hidden"
                {...mobileLogo.image.childImageSharp}
                placeholderClassName="hidden"
              />
            </Link>
          )
        )}

        <div className="relative leading-tight">
          {(!onScrollButton || hideScrollButton || !state.showScrollButton) && (
            <button
              onClick={e => {
                dispatch({
                  type: state.isProfileOpen ? Actions.PROFILE_CLOSED : Actions.PROFILE_OPENED
                })
                e.stopPropagation()
              }}
            >
              <img src={(profile as unknown) as string} alt="J'accède à mon espace" />
            </button>
          )}

          {onScrollButton && state.showScrollButton && !hideScrollButton && (
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              analyticsEventName={IAnalyticsEvent.CLIC_CTA_HEADER}
              {...buttonPropsFormatter({
                ...onScrollButton,
                action: onScrollButton.actionId,
                link: { href: onScrollButton.href }
              })}
            >
              <small>{onScrollButton.title}</small>
            </Button>
          )}

          {state.isProfileOpen && (
            <>
              <div
                id="profile__tooltip-menu__backdrop"
                className="fixed top-0 left-0 w-screen h-screen"
                onClick={() => dispatch({ type: Actions.PROFILE_CLOSED })}
              ></div>
              <div
                id="profile__tooltip-menu"
                className="absolute right-0 bg-white border border-gray-100 rounded-lg shadow-lg"
                onClick={e => e.stopPropagation()}
              >
                <div
                  id="profile__tooltip-menu__needle"
                  className="absolute w-3 h-3 bg-white border-t border-l border-gray-100"
                />
                <div className="py-2 px-4">
                  {buttons.map((button, bcIndex) => (
                    <Button
                      key={bcIndex}
                      className="flex items-center py-1 text-xs"
                      variant={BUTTON_VARIANTS.LINK}
                      iconPosition={ICON_POSITIONS.LEFT}
                      iconClassname="text-orange-500"
                      target={button.item.link.target}
                      href={button.item.link.href}
                      ariaLabel={button.item.label}
                      analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                      analyticsDataLayer={{
                        buttonLabel: button.item.label,
                        zoneCategory: 'header'
                      }}
                    >
                      {button.item.label}
                    </Button>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        id="menu__lateral-panel"
        className={cs('absolute w-full h-screen bg-white overflow-y-scroll', {
          visible: state.isMenuOpen
        })}
      >
        <div className="px-8 py-5">
          {menu.map((menuItem, index) =>
            menuItem.children.length ? (
              <Button
                key={index}
                className="flex items-center justify-between w-full py-3 font-bold text-purple-dark-500"
                variant={BUTTON_VARIANTS.LINK}
                onClick={() => {
                  dispatch({
                    type: Actions.MENU_ENTRY_SELECTED,
                    payload: { selectedEntry: menuItem }
                  })
                  sendClicZonePageAnalytics({
                    buttonLabel: menuItem.item.label,
                    zoneCategory: 'header'
                  })
                }}
                aria-label={menuItem.item.label}
              >
                <span className="text-lg">{menuItem.item.label}</span>
                <Icon
                  className="text-orange-500 transform scale-75 -rotate-90"
                  name={ICONS.ArrowMenu}
                  width="18"
                  height="18"
                />
              </Button>
            ) : (
              <Button
                key={index}
                className="flex items-center justify-between w-full py-3 font-bold text-purple-dark-500"
                variant={BUTTON_VARIANTS.LINK}
                target={menuItem.item.link.target}
                href={menuItem.item.link.href}
                ariaLabel={menuItem.item.label}
                analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                analyticsDataLayer={{
                  buttonLabel: menuItem.item.label,
                  zoneCategory: 'header'
                }}
              >
                <span className="text-lg">
                  {menuItem.item.label}
                </span>
              </Button>
            )
          )}
        </div>
      </div>

      <div
        id="submenus__lateral-panel"
        className={cs('absolute w-full h-screen bg-white overflow-y-scroll', {
          visible: state.selectedEntry !== null
        })}
      >
        {state.selectedEntry !== null && (
          <>
            <div className="text-left mx-8 px-4 py-5 border-b-2 border-pink-500">
              <Button
                variant={BUTTON_VARIANTS.LINK}
                target={state.selectedEntry.item.link.target}
                href={state.selectedEntry.item.link.href}
                ariaLabel={state.selectedEntry.item.label}
                analyticsEventName={IAnalyticsEvent.CLIC_CTA}
                analyticsDataLayer={{
                  buttonLabel: state.selectedEntry.item.label
                }}
                onClick={() => {
                  dispatch({ type: Actions.MENU_CLOSED })
                }}
              >
                <span className="text-lg text-left">{state.selectedEntry.item.label}</span>
              </Button>
              <p className="text-dark-grey-100 text-left font-sans mt-4">{state.selectedEntry.item.link.description}</p>
            </div>
          </>
        )}
        <ul className="pt-5">
          {state.selectedEntry !== null &&
            state.selectedEntry.children.map((submenu, index) => (
              <li key={index}>
                <Button
                  className="flex w-full"
                  variant={BUTTON_VARIANTS.LINK}
                  target={submenu.item.link.target}
                  href={submenu.item.link.href}
                  ariaLabel={submenu.item.label}
                  analyticsEventName={IAnalyticsEvent.CLIC_CTA}
                  analyticsDataLayer={{
                    buttonLabel: submenu.item.label
                  }}
                  onClick={() => {
                    dispatch({ type: Actions.MENU_CLOSED })
                  }}
                >
                  <span className="w-full p-8 pl-12 py-3 pb-6 font-sans text-lg leading-none font-bold text-purple-dark-500">
                    {submenu.item.label}
                  </span>
                </Button>
                <ul className="pb-6">
                  {submenu.children.map((subitem, subindex) => (
                    <li key={subindex}>
                      <Button
                        className="flex w-full"
                        variant={BUTTON_VARIANTS.LINK}
                        target={subitem.item.link.target}
                        href={subitem.item.link.href}
                        ariaLabel={subitem.item.label}
                        analyticsEventName={IAnalyticsEvent.CLIC_CTA}
                        analyticsDataLayer={{
                          buttonLabel: submenu.item.label + "_" + subitem.item.label
                        }}
                      >
                        <span className="w-full text-base p-12 py-2 font-sans leading-none text-dark-grey-100">
                          <Icon
                            className="inline text-pink-500 transform scale-50 stroke-current stroke-1 rotate-180"
                            name={ICONS.Chevron}
                            width="24"
                            height="24" />
                          <span className="inline align-middle font-normal">{subitem.item.label}</span>
                        </span>
                      </Button>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
      </div>
    </header>
  )
}
