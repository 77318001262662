import axios from 'axios'
import dayjs from 'dayjs'
import uid from 'uid'

import { ICompany, IEvent, IContext, ICompanyForm } from './constants'

export const fetchCompanyBySiren = (
  { apiEndpoints: { siren } }: IContext,
  { data }: IEvent<ICompanyForm>
): Promise<ICompany[]> => {
  if (!data) return Promise.reject("Event object doesn't have any 'data'.")
  if (siren.href === null || siren.apiKey === null)
    return Promise.reject('SIREN WebService needs an URL and an API Key.')

  return axios
    .get(siren.href, {
      params: { q: `siren:${data.siren}` },
      headers: {
        'api-key': siren.apiKey,
        correlationId: uid(16)
      }
    })
    .then((response: any) => response.data.etablissements)
}

export const fetchCompanyByNameAndZipCode = (
  { apiEndpoints: { siren } }: IContext,
  { data }: IEvent<ICompanyForm>
): Promise<ICompany[]> => {
  if (!data) return Promise.reject("Event object doesn't have any 'data'.")
  if (siren.href === null || siren.apiKey === null)
    return Promise.reject('SIREN WebService needs an URL and an API Key.')

  return axios
    .get(siren.href, {
      params: {
        q: `denominationUniteLegale:"${data.companyName}" AND codePostalEtablissement:${data.zipCode}`
      },
      headers: {
        'api-key': siren.apiKey,
        correlationId: uid(16)
      }
    })
    .then((response: any) => response.data.etablissements)
}

export const fetchRecommendation = (context: IContext): Promise<any> => {
  const {
    enjeux,
    entreprise,
    apiEndpoints: { eole }
  } = context

  if (eole.href === null || eole.apiKey === null)
    return Promise.reject('EOLE WebService needs an URL and an API Key.')

  const now = dayjs()
  return axios.post(
    eole.href,
    {
      societeConcernee: 'LMGCS',
      typeFiche: 'B2B',
      idFicheFournisseur: now.format('YYYYDDMMHHmmss'),
      origine: 'SiteInstitutionnelFLEX',
      objetFiche: 'Devis',
      dateEnvoi: now.format('DD-MM-YYYY'),
      infosPersonnelles: {
        civilite: undefined,
        nom: entreprise.companyContact.lastname,
        prenom: entreprise.companyContact.firstname,
        dateNaissance: undefined,
        email: entreprise.companyContact.email
      },
      offre: {
        codeOffre:
          (enjeux.employeesWithChild ?? -1) < 50
            ? 'Pack Flex Santé Bien-Etre'
            : 'Pack Flex Famille',
        descriptionOffre: Object.entries({
          /* eslint-disable */
          Qualite_de_vie_au_travail: enjeux.workLifeQuality,
          Principaux_enjeux_RH: enjeux.mainIssues,
          Actions_mises_en_place: enjeux.takenActions,
          Proportion_employes_avec_enfants: enjeux.employeesWithChild
          /* eslint-enable */
        })
          .map(entry => `${entry[0]} = ${JSON.stringify(entry[1])}\n`)
          .join('')
      },
      entreprise: {
        raisonSociale: entreprise.companyId?.uniteLegale.denominationUniteLegale,
        siren: entreprise.companyId?.siret.slice(0, -5),
        effectifTotal: entreprise.companyId?.trancheEffectifsEtablissement,
        codePostal: entreprise.companyId?.adresseEtablissement.codePostalEtablissement,
        ville: entreprise.companyId?.adresseEtablissement.libelleCommuneEtablissement,
        voie: entreprise.companyId?.adresseEtablissement.libelleVoieEtablissement
      },
      consentements: {
        dateConsentement: now.format('DD-MM-YYYY'),
        consentementProspectionEmail: true,
        consentementTransfertFlex: false,
        consentementTransfertLmg: true
      }
    },
    {
      headers: {
        appRequestCode: 'WEBFLEX',
        'api-key': eole.apiKey,
        correlationId: uid(16)
      }
    }
  )
}
