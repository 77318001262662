import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import cs from 'classnames'

import validationSchema, { ACCEPTED_VALUES, VALUES_MAPPING } from './Question1Form.validationSchema'
import Question from '../../../../../components/presentationals/AutoDiagnostic/Question/Question'
import SliderField from '../../../../../components/formik/SliderField/SliderField'
import Button, { BUTTON_VARIANTS } from '../../../../../components/presentationals/Button/Button'

interface IProps {
  onEdit: () => void
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  showForm: boolean
  isRevision: boolean
  disableEditionButton?: boolean
  context?: any
}

const Question1Form = ({
  onSubmit,
  onEdit,
  dataTestId,
  initialValues,
  showForm,
  isRevision,
  context,
  disableEditionButton = false
}: IProps) => {
  const formattedOnSubmit = useCallback(
    ({ workLifeQuality }) => onSubmit({ data: VALUES_MAPPING[workLifeQuality] }),
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid } = props

        const {
          enjeux: { workLifeQuality }
        } = context

        return (
          <div data-testid={dataTestId}>
            <Question
              className={cs({ 'shadow-lg': showForm })}
              onClick={showForm ? undefined : onEdit}
              editBtnDataTestId="question-1-edit-button"
              text={
                showForm ? (
                  <b aria-hidden={true}>
                    1/4. Pour vous, la qualité de vie au travail est une préoccupation :
                  </b>
                ) : (
                  <>
                    1/4. La qualité de vie au travail est <b>{workLifeQuality}</b> pour moi
                  </>
                )
              }
            >
              {showForm && (
                <Form className="pt-16">
                  <SliderField
                    name="1/4. Pour vous, la qualité de vie au travail est une préoccupation :"
                    step={33.3}
                    acceptedValues={ACCEPTED_VALUES}
                    onChange={newValue => setFieldValue('workLifeQuality', newValue)}
                    value={values['workLifeQuality']}
                  />

                  <ul className="flex items-center justify-between pt-12 pb-8 text-xs text-gray-700 font-brand md:text-base">
                    {ACCEPTED_VALUES.map(v => (
                      <li className="capitalize" key={v}>
                        {VALUES_MAPPING[v]}
                      </li>
                    ))}
                  </ul>

                  {isRevision && (
                    <div className="text-center">
                      <Button
                        className="flex justify-center w-full md:w-2/5"
                        variant={BUTTON_VARIANTS.PRIMARY}
                        onClick={handleSubmit}
                        disabled={!isValid}
                        ariaLabel="Enregistrer la modification"
                      >
                        Enregistrer la modification
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Question>

            {showForm && !isRevision && (
              <div className="mt-10 text-center">
                <Button
                  className="flex justify-center w-full md:w-2/5"
                  dataTestId="trigger-next-button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  onClick={handleSubmit}
                  disabled={!isValid || disableEditionButton}
                  ariaLabel="Suivant"
                >
                  Suivant
                </Button>
              </div>
            )}
          </div>
        )
      }}
    </Formik>
  )
}

export default Question1Form
