import {
  BUTTON_VARIANTS,
  IProps as ButtonProps
} from '../../components/presentationals/Button/Button'
import { IDrupalButton } from '../../types/drupal'
import openPanel from '../privacy/openPanel'

export const BUTTON_ACTIONS_NAMES = {
  COOKIES_POPIN: 'cookies-popin',
  CONTACT_POPIN: 'contact-popin',
  DO_NOTHING: 'do-nothing'
}

export const BUTTON_ACTIONS: IMap<() => void> = {
  // eslint-disable-next-line
  'cookies-popin': openPanel,
  'do-nothing': () => {}
}

export default function buttonPropsFormatter({
  action,
  link,
  title,
  target,
  style,
  affichageFleche
}: IDrupalButton): ButtonProps {
  let onClick
  if (action) {
    onClick = BUTTON_ACTIONS[action] || BUTTON_ACTIONS['do-nothing']
  }
  let variant
  switch (style) {
    case 'SECONDARY':
      variant = BUTTON_VARIANTS.SECONDARY
      break
    default:
      // "Défaut" || "PRIMARY" | *
      variant = BUTTON_VARIANTS.PRIMARY
      break
  }
  return {
    children: title,
    ariaLabel: title,
    analyticsDataLayer: {
      buttonLabel: title
    },
    href: link?.href || '',
    target,
    onClick,
    action,
    variant,
    affichageFleche
  }
}
