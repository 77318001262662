import { Machine } from 'xstate'
import enjeux, { INITIAL_CONTEXT as enjeuxContext } from './steps/enjeux'
import entreprise, { INITIAL_CONTEXT as entrepriseContext } from './steps/entreprise'
import recommendation from './steps/recommendation'

import { IContext, IEvent } from './constants'

export * from './constants'

export const autodiagnostic = Machine<IContext, IEvent<any>>({
  id: 'autodiagnostic',
  initial: 'enjeux',
  context: {
    apiEndpoints: {
      siren: {
        href: null,
        apiKey: null
      },
      eole: {
        href: null,
        apiKey: null
      }
    },
    enjeux: enjeuxContext,
    entreprise: entrepriseContext,
  },
  states: {
    enjeux,
    entreprise,
    recommendation
  }
})

export default autodiagnostic
