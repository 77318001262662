/* eslint-disable @typescript-eslint/camelcase */
import { graphql, useStaticQuery } from 'gatsby'

import { IParagraph } from '..'
import Component, { IProps } from './Component'
import { unwrapMaybe as _ } from '../utils'
import { IParagraph__Ordered_Desc_List, IAllOrderedDescListQuery } from '../../graphql.types'

const propsFormatter = ({
  field_title,
  field_plain_description,
  field_rich_descriptions
}: IParagraph__Ordered_Desc_List): IProps => {
  return {
    title: _('title', field_title),
    description: _('description', field_plain_description),
    steps: _('steps', field_rich_descriptions).map(richDesc => ({
      description: _('Richedescription', richDesc?.value)
    }))
  }
}

const Query = () =>
  useStaticQuery<IAllOrderedDescListQuery>(graphql`
    query AllOrderedDescList {
      data: allParagraphOrderedDescList {
        edges {
          node {
            drupal_id
            drupal_internal__revision_id
            field_plain_description
            field_title
            field_rich_descriptions {
              value
            }
          }
        }
      }
    }
  `)

const paragraph: IParagraph = {
  propsFormatter,
  Query,
  Component
}

export default paragraph
