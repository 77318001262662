import * as yup from 'yup'

export const ACCEPTED_VALUES = [0, 33.3, 66.6, 100]
export const VALUES_MAPPING: Record<number, string> = {
  [ACCEPTED_VALUES[0]]: 'mineure',
  [ACCEPTED_VALUES[1]]: 'assez importante',
  [ACCEPTED_VALUES[2]]: 'importante',
  [ACCEPTED_VALUES[3]]: 'majeure'
}

export default yup
  .object()
  .shape({
    workLifeQuality: yup.number().required()
  })
  // this test doesn't override built-in tests, it is additional
  .test('is-accepted-value', '', (form: any) => {
    if (!ACCEPTED_VALUES.includes(form.workLifeQuality))
      throw new yup.ValidationError('La valeur selectionnée est invalide.', '', '')

    return false
  })
