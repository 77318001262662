import { graphql, useStaticQuery } from 'gatsby'

import { IFooterQuery } from '../graphql.types'

const useFooterData = () => {
  const data = useStaticQuery<IFooterQuery>(graphql`
    query Footer {
      allFooter {
        nodes {
          logo {
            alt
            src
            image {
              childImageSharp {
                fixed(height: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          mobileLogo {
            alt
            src
            image {
              childImageSharp {
                fixed(height: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          menu {
            item {
              id
              label
              weight
              link {
                href
                target
                title
                actionId
              }
            }
          }
          socialMediaLinks {
            icon {
              alt
              src
              image {
                childImageSharp {
                  fixed(height: 40) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            link {
              href
              target
              title
            }
          }
        }
      }
    }
  `)

  return data
}

export default useFooterData
