/**
 * Deep search into nested object for a specified key, then returns its value
 * @param obj - The object to search the key into
 * @param key - The key to find into the obj
 */
export default function deepFindKey(obj: { [key: string]: any }, key: string): any {
  if (obj.hasOwnProperty(key)) return obj[key]

  let value = null

  for (let v of Object.values(obj)) {
    if (typeof v === 'object') {
      value = v.hasOwnProperty(key) ? v[key] : deepFindKey(v, key)
    }
  }

  return value
}
