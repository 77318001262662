import { Machine } from 'xstate'
import contact, {INITIAL_CONTEXT as contactContext } from './contact'

import { IContext, IEvent } from './constants'

export * from './constants'

export const contactForm = Machine<IContext, IEvent<any>>({
  id: 'contactForm',
  initial : 'contact',
  context: {
    apiEndpoints: {
      siren: {
        href: null,
        apiKey: null
      },
      eole: {
        href: null,
        apiKey: null
      }
    },
    contact: contactContext
  },
  states: {
    contact
  }
})

export default contactForm
