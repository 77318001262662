import React from 'react'
import cs from 'classnames'

import BulletNumber from '../BulletNumber/BulletNumber'
import './BulletNumberList.css'

interface IStep {
  number: number
  subtitle: string
}

interface IProps {
  steps: IStep[]
  preventVertical?: boolean
  allActive?: boolean
  currentStep?: number
  activeClassName?: string
  activeSubtitleClassName?: string
}

const BulletNumberList = ({
  steps,
  currentStep,
  activeClassName,
  activeSubtitleClassName,
  preventVertical = false,
  allActive = false
}: IProps) => (
  <ol
    className={cs('flex w-full md:flex-row md:items-start', {
      'flex-col items-center': !preventVertical
    })}
  >
    {steps.map((step, index) => (
      <li
        key={index}
        className={cs('flex flex-col items-center dashed-line pb-6', `sm:w-1/2 md:px-5 lg:px-10`, {
          [`dashed-line--prevent-vertical w-1/${steps.length}`]: preventVertical
        })}
      >
        <div className="z-10 p-2 bg-white rounded-full">
          <BulletNumber
            value={step.number}
            active={allActive || currentStep === index}
            showAsChecked={typeof currentStep !== 'undefined' && currentStep > index}
            activeClassName={activeClassName}
          />
        </div>
        <div
          className={cs(
            'relative rich-contribution px-4 pt-2 pb-1 text-sm text-center bg-white sm:px-0 md:text-base',
            typeof currentStep !== 'undefined' && currentStep >= index
              ? activeSubtitleClassName
              : 'text-gray-700'
          )}
          dangerouslySetInnerHTML={{ __html: step.subtitle }}
        />
      </li>
    ))}
  </ol>
)

export default BulletNumberList
