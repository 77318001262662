import cs from 'classnames'
import GatsbyLink from 'gatsby-link'
import React, { MouseEvent, ReactNode, useCallback } from 'react'

import './Button.css'

import { useIAdvizeObserver } from '../../../hooks/useIAdvizeObserver'
import { IAnalyticsDataLayer, IAnalyticsEvent, sendClicCTAAnalytics } from '../../../utils/analytics/analytics'
import showIAdvizeModal, { AVAILABLE_CLASS_SELECTOR } from '../../../utils/iAdvize/showModal'
import { BUTTON_ACTIONS_NAMES } from '../../../utils/paragraphs/buttonPropsFormatter'
import isExternal from '../../../utils/uri/isExternal'
import Icon, { ICONS } from '../Icon'

export interface IProps {
  /** The label */
  children?: ReactNode
  /** If provided component will use "button" tag */
  onClick?: () => void
  /** Callback */
  onMouseEnter?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  /** If provided component will use "a" tag or a GatsbyLink*/
  href?: string
  /** the target of the link */
  target?: '_blank' | '_self' | string
  /** The type of button */
  type?: 'submit' | 'button'
  /** The variariation of display to use */
  variant?: BUTTON_VARIANTS | string
  /** Additional classNames that can influence the display*/
  className?: string
  /** If not null, we show the arrow icon to the given position */
  iconPosition?: ICON_POSITIONS
  /** If not null, className is added to the arrow icon */
  iconClassname?: string
  /** Wether to display a spinner into the button or not */
  loading?: boolean
  /** Wether the button is disable or not */
  disabled?: boolean
  /** Wether the analytics is disable or not */
  disabledAnalytics?: boolean
  /** 'data-testid' to set on the rendered element */
  dataTestId?: string
  /** 'tabIndex' of the rendered element */
  tabIndex?: number
  /** The `aria-label` attribute value */
  ariaLabel?: string
  /** A string that describe a button action */
  action?: string | null | undefined
  /** A string overidding the default `clicCta` analytics event name */
  analyticsEventName?: IAnalyticsEvent
  /** Object with analytics data */
  analyticsDataLayer?: IAnalyticsDataLayer
  /** IAdvize class override */
  actionIAdvizeAvailableClass?: string
  actionIAdvizeUnavailableClass?: string
  affichageFleche?: boolean
}

export enum BUTTON_VARIANTS {
  PRIMARY,
  PRIMARY_LIGHT,
  SECONDARY,
  SECONDARY_LIGHT,
  LINK,
  DROPDOWN,
  NONE
}

export enum ICON_POSITIONS {
  LEFT = 'left',
  RIGHT = 'right'
}

export function Button({
  children,
  href,
  onClick,
  onMouseEnter,
  className,
  loading = false,
  disabled = false,
  disabledAnalytics = false,
  iconPosition,
  iconClassname,
  dataTestId,
  tabIndex = 0,
  type = 'button',
  variant = BUTTON_VARIANTS.PRIMARY,
  target = '_self',
  ariaLabel,
  action,
  analyticsEventName,
  analyticsDataLayer,
  actionIAdvizeAvailableClass = AVAILABLE_CLASS_SELECTOR,
  affichageFleche
}: IProps) {
  const varianteClass = {
    'btn-primary': variant === BUTTON_VARIANTS.PRIMARY,
    'btn-primary-light': variant === BUTTON_VARIANTS.PRIMARY_LIGHT,
    'btn-secondary': variant === BUTTON_VARIANTS.SECONDARY,
    'btn-secondary-light': variant === BUTTON_VARIANTS.SECONDARY_LIGHT,
    'btn-link': variant === BUTTON_VARIANTS.LINK,
    'btn-link btn-dropdown': variant === BUTTON_VARIANTS.DROPDOWN
  }

  const finalClasses = cs(varianteClass, className, {
    'pointer-events-none opacity-50': disabled
  })

  const handleLinkClick = useCallback(() => {
    const sauvegardeAncre = location.hash
    location.hash = ''
    location.hash = sauvegardeAncre
    if (typeof onClick === 'function') onClick()
    if (typeof href !== 'undefined' && !disabledAnalytics) {
      if (href.startsWith("tel:")) {
        analyticsEventName = IAnalyticsEvent.CLIC_CTA_APPEL;
      }
      sendClicCTAAnalytics(Object.assign({
        targetUrl: href,
        buttonLabel: analyticsDataLayer?.buttonLabel ??
          typeof children?.toString() === 'string' ? children?.toString() : undefined
      }, analyticsDataLayer), analyticsEventName)
    }
  }, [onClick, href])

  const handleButtonClick = useCallback(() => {
    if (action && action === BUTTON_ACTIONS_NAMES.CONTACT_POPIN) {
      showIAdvizeModal(actionIAdvizeAvailableClass, analyticsEventName)
    } else if (typeof onClick === 'function') onClick()
  }, [action, onClick])

  const isIAdvizeAvailable = useIAdvizeObserver(actionIAdvizeAvailableClass)

  if (!href && !action && !onClick) {
    return null
  }

  if (action && action === BUTTON_ACTIONS_NAMES.CONTACT_POPIN && !isIAdvizeAvailable) {
    return null
  }

  if (href) {
    if (isExternal(href)) {
      const extra = target === '_blank' ? { rel: 'noopener' } : {}

      return (
        <a
          tabIndex={tabIndex}
          className={finalClasses}
          href={href}
          onClick={handleLinkClick}
          onMouseEnter={onMouseEnter}
          target={target}
          data-type={analyticsEventName}
          data-testid={dataTestId}
          aria-label={ariaLabel}
          {...extra}
        >
          <ButtonIcon
            icon={loading ? ICONS.Spinner : ICONS.ArrowRight}
            position={iconPosition}
            className={iconClassname}
          >
            {children}
          </ButtonIcon>
        </a>
      )
    } else {
      return (
        <GatsbyLink
          tabIndex={tabIndex}
          className={finalClasses}
          onClick={handleLinkClick}
          onMouseEnter={onMouseEnter}
          to={href}
          target={target}
          data-type={analyticsEventName}
          data-testid={dataTestId}
          aria-label={ariaLabel}
        >
          <ButtonIcon
            icon={loading ? ICONS.Spinner : ICONS.ArrowRight}
            position={iconPosition}
            className={iconClassname}
          >
            {children}
          </ButtonIcon>
          {affichageFleche && (
            <Icon className="ml-2" name={ICONS.ArrowDownWhiteSvg} width="18" height="18" />
          )}
        </GatsbyLink>
      )
    }
  }

  return (
    <button
      tabIndex={tabIndex}
      className={finalClasses}
      onClick={handleButtonClick}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
      type={type}
      data-type={analyticsEventName}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      <ButtonIcon
        icon={loading ? ICONS.Spinner : ICONS.ArrowRight}
        position={iconPosition}
        className={iconClassname}
      >
        {children}
      </ButtonIcon>
    </button>
  )
}

interface IButtonIcon {
  icon: ICONS
  className?: string
  position?: ICON_POSITIONS
  children: ReactNode
}

function ButtonIcon({ icon, className, children, position }: IButtonIcon) {
  return (
    <>
      {position && position === ICON_POSITIONS.LEFT && (
        <Icon name={icon} className={cs('flex text-3xl', className)} />
      )}
      {children}
      {position && position === ICON_POSITIONS.RIGHT && (
        <Icon name={icon} className={cs('flex text-3xl', className)} />
      )}
    </>
  )
}

export default Button
