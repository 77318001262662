import cs from 'classnames'
import React, { useState } from 'react'
import style from './CheckboxFormField.module.css'

import Icon, { ICONS } from '../../presentationals/Icon/'

interface IProps {
  className?: string
  checked: boolean
  onClick: () => void
  onChange: () => void
  text?: string
  disabled?: boolean
}

const CheckboxFormField = ({
  className,
  text,
  checked,
  onClick,
  onChange,
  disabled,
}: IProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <div className={cs(className, '')}>
      <span>
        <label for="checkbox">
          {isChecked &&
            <Icon name={ICONS.Checkboxchecked} className='inline' width="22" height="22" />
          }
          {!isChecked &&
            <Icon name={ICONS.CheckboxUnchecked} className='inline' width="22" height="22" />
          }
          <span className={`${style.label} ml-2 text-xs`}>{text}</span></label>
        <input
          id="checkbox"
          checked={isChecked}
          className="hidden"
          type="checkbox"
          onClick={() => { onClick(); setIsChecked(!isChecked) }}
          onChange={onChange}
          disabled={disabled}
        />
      </span>


    </div>
  )
}

export default CheckboxFormField
