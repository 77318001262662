import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'

import validationSchema from './Question3Form.validationSchema'
import Button, { BUTTON_VARIANTS } from '../../../../../components/presentationals/Button/Button'
import NumberField from '../../../../../components/formik/NumberField/NumberField'

interface IProps {
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  context?: any
}

const Question3Form = ({ onSubmit, dataTestId, initialValues }: IProps) => {
  const formattedOnSubmit = useCallback(
    ({ employees }) =>
      onSubmit({
        data: employees
      }),
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid } = props

        const handleChange = useCallback(e => setFieldValue('employees', e.target.value), [
          setFieldValue
        ])
        const increment = useCallback(
          count => setFieldValue('employees', parseInt(values['employees']) + count),
          [setFieldValue, values['employees']]
        )

        return (
          <Form>
            <div data-testid={dataTestId}>
              <p className="mb-4 font-bold text-purple-dark-500">Nombre de salariés</p>

              <NumberField
                min={0}
                value={values['employees']}
                onChange={handleChange}
                increment={increment}
              />

              <div className="mt-10 text-center">
                <Button
                  className="relative flex justify-center w-full md:w-2/5"
                  dataTestId="trigger-next-button"
                  iconClassname="absolute right-0 top-0 mr-4 mt-1 transform scale-75"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  onClick={handleSubmit}
                  disabled={!isValid}
                  ariaLabel="Je valide"
                  analyticsDataLayer={{
                    buttonLabel: "Je valide"
                  }}
                >
                  Je valide
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Question3Form
