import cs from 'classnames'
import GatsbyImage from 'gatsby-image'
import { navigate } from 'gatsby-link'
import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import breakpoints from '../../assets/css/breakpoints'
import Button from '../../components/presentationals/Button/Button'
import Icon, { ICONS } from '../../components/presentationals/Icon/Icon'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IDrupalButton } from '../../types/drupal'
import { IColor } from '../../types/gatsby/colors'
import { IImage } from '../../types/gatsby/images'
import { IAnalyticsEvent, sendClicCTAAnalytics, sendClicZonePageAnalytics } from '../../utils/analytics/analytics'
import { SlideCustomArrow } from '../../utils/paragraphs/SlideCustomArrow'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'
import './Component.module.css'
import style from './Component.module.css'

interface ICard {
  /**Associated Taxonomy term*/
  population?: IPopulation
  /** The card's image */
  image?: IImage
  /** The card's title */
  titre: string
  /** The card's */
  description: string
  /**Text list */
  listeDeText: {
    liste?: string[]
    titre?: string
  }
  marqueurPrix?: string
  /** The button at the bottom of the card */
  bouton: IDrupalButton
}

interface IPopulation {
  /** Text displayed into the clickable tab */
  name: string
}

export interface IProps {
  // A color to apply to the background - It needs to be one key of tailwind colors
  backgroundColor?: IColor
  /** The title that will be displayed using an H1 */
  title: string
  tailleCartes: string
  libelleOnglet: string
  description: string
  /** The list of elements to display */
  liste: ICard[]
}

function AddToStart(index: number, tabDisplay: number[]) {
  const difference = tabDisplay[0] - index;

  //ajout le nombre de difference au debut
  for (let i = 0; i < difference; i++) {
    tabDisplay.unshift(tabDisplay[0] - 1)
    tabDisplay.pop()
  }

  return tabDisplay
}

function AddToEnd(index: number, tabDisplay: number[]) {
  const difference = index - tabDisplay[tabDisplay.length - 1];
  //ajouter le nombre de difference a la fin
  for (let i = 0; i < difference; i++) {
    tabDisplay.push(tabDisplay[tabDisplay.length - 1] + 1)
    tabDisplay.shift()
  }
  return tabDisplay

}

export default function Component({
  backgroundColor,
  title,
  description,
  libelleOnglet,
  tailleCartes,
  liste
}: IProps) {
  let backgroundColorTop = backgroundColor && backgroundColor != 'defaut' ? backgroundColor : 'white';
  let backgroundColorBot = backgroundColor && backgroundColor != 'defaut' ? backgroundColor : 'gray';

  //Calcul des onglets
  const tabs: [string] = [libelleOnglet]
  liste.forEach(card => {
    if (card.population && !tabs.includes(card.population.name)) {
      tabs.push(card.population.name)
    }
  })

  const [selectedTab, setSelectedTab] = React.useState<number>(0)
  const [tabDisplay, setTabDisplay] = React.useState<number[]>([0, 1, 2, 3, 4, 5])

  const isDesktopLayout = useBreakpoint(breakpoints.lg)
  const SLIDER_CONFIG = {
    dots: true,
    infinite: false,
    arrows: isDesktopLayout,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SlideCustomArrow />,
    prevArrow: <SlideCustomArrow isPrev={true} />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerMode: true
        }
      }
    ]
  }
  const slider = useRef<Slider>(null);

  const isNotMobile = useBreakpoint(breakpoints.md);

  useEffect(() => {

    const test = document.getElementsByClassName('slick-list')
    if (document.getElementsByClassName('slick-list')[1] != undefined) {
      document.getElementsByClassName('slick-list')[1].style.padding = 'inherit';
      if (!isNotMobile) {
        document.getElementsByClassName('slick-list')[1].style.padding = '0px 50px';
      }
    }
    Array.prototype.forEach.call(test, function (sl) {
      sl.style.display = 'flex'
      sl.getElementsByClassName('slick-track')[0].style.display = 'flex'
      Array.prototype.forEach.call(sl.getElementsByClassName('slick-track')[0].children, function (st) {
        st.style.width = 'inherit';
        st.firstChild.style.height = '100%';
      })
    })
    if (typeof window !== 'undefined') {
      let id = window.location.hash.replace('#', '');
      if (id) {
        let tabIndex = 0;
        window.history.pushState("", document.title, window.location.pathname + window.location.search);
        tabs.forEach((x, index) => {
          x = x.toLowerCase()
          if ((_.deburr(x).indexOf(id) !== -1)) {
            tabIndex = index;
          }
        })
        setSelectedTab(tabIndex)
        if (!!slider.current && !tabDisplay.includes(tabIndex)) {
          (slider.current as Slider)?.slickGoTo(tabIndex);
        } else {
          (slider.current as Slider)?.slickGoTo(tabIndex - 1);
        }
        if (!tabDisplay.includes(tabIndex)) {
          if (tabIndex > tabDisplay[tabDisplay.length - 1]) {
            setTabDisplay(AddToEnd(tabIndex, tabDisplay));
          } else if (tabIndex < tabDisplay[0]) {
            setTabDisplay(AddToStart(tabIndex, tabDisplay))
          }
        }
      }
    }

  });

  const SLIDER_CONFIG_CATEGORIES = {
    dots: false,
    infinite: false,
    arrows: isDesktopLayout,
    slidesToShow: tabs.length < 6 ? tabs.length : 6,
    slidesToScroll: 6,
    variableWidth: true,
    nextArrow: <SlideCustomArrow color="orange" classNameCustom={style.slick_arrow_custom} />,
    prevArrow: <SlideCustomArrow color="orange" classNameCustom={style.slick_arrow_custom} isPrev={true} />,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          centerMode: false
        }
      }
    ]
  }

  const carteAAfficher: ICard[] = selectedTab === 0 ? liste : liste.filter(element => element.population?.name === tabs[selectedTab])

  const slider2 = useRef<Slider>(null);
  return (
    <section
      className={cs(
        'package-offre__component',
        'bg-circle bg-circle-bottom bg-circle-right',
        `bg-${backgroundColorTop}-100`,
        `bg-circle-${backgroundColorTop}`)}
    >
      <a className="ancre" id="package-offre" />
      <div className="w-full px-0 py-16 mx-auto md:container">
        <div className="container mx-auto">
          <h2 className="px-16 mb-6 md:mb-10 text-3xl font-medium leading-none text-center font-brand text-purple-dark-500">
            {title}
          </h2>
          {description && (
            <h3 className="mx-auto mb-8 md:mb-10 text-l md:text-xl font-medium leading-6 text-center text-dark-grey-100  md:w-3/4">{description}</h3>
          )}
        </div>
        {tabs.length > 1 && (
          <div className="justify-center pl-3 md:px-3">
            <Slider className={cs('custom-slick-slider md:mx-auto', style.sliderCategories)} ref={slider} {...SLIDER_CONFIG_CATEGORIES}>
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className="justify-center h-full w-full"
                >
                  <button
                    id={`${tab}`}
                    onClick={() => {
                      setSelectedTab(index)
                      if (!isDesktopLayout) {
                        slider.current?.slickGoTo(index - 1, false)
                      }
                      setTimeout(() => {
                        slider2.current?.slickGoTo(0, false)
                      }, 600);

                    }}
                    className={cs(
                      'w-full h-full focus:outline-none pb-2 md:px-0 px-1 font-bold',
                      { 'text-orange-500 border-b-4 border-orange-500': selectedTab === index },
                      { 'text-gray-500 border-b h-1 border-gray-400': selectedTab !== index }
                    )}
                    aria-expanded={selectedTab === index}
                    aria-controls={`tab-content-${tab}`}
                  >
                    {tab}
                  </button>
                </div>
              ))}
              {!isNotMobile && <div className="justify-center h-full w-full"></div>}
            </Slider>
          </div>
        )}
        {carteAAfficher && (
          <div className={cs(style.bottomSlider, `bg-${backgroundColorBot}-100 pb-12 bg-circle bg-circle-bottom bg-circle-right bg-circle-orange`, tabs.length > 1 ? 'pt-8' : '')}>
            <Slider className={cs('custom-slick-slider md:mx-auto pb-6 md:pb-1', style.slider)} ref={slider2} {...SLIDER_CONFIG}>
              {carteAAfficher.map((card, index) => (
                <div key={index} className={cs('focus:outline-none h-full w-full', style.card)}>
                  <div
                    className={cs(
                      'flex-1 h-full md:w-72 overflow-hidden bg-white relative rounded-xl',
                      {
                        '':
                          !backgroundColor || backgroundColor === 'white'
                      }
                    )}
                  >
                    <div
                      className={card.bouton?.link?.href ? style.card__cursor : ''}
                      onClick={(e: any) => {
                        if (e.target?.dataset?.type === IAnalyticsEvent.CLIC_ZONE_PAGE ||
                          e.target?.parentElement?.dataset?.type === IAnalyticsEvent.CLIC_ZONE_PAGE) {
                          sendClicZonePageAnalytics({
                            buttonLabel: card.bouton.title,
                            zoneCategory: 'body'
                          })
                        } else {
                          if (card.bouton?.link?.href) {
                            sendClicCTAAnalytics({
                              targetUrl: card.bouton?.link?.href,
                              buttonLabel: card.bouton.title
                            })
                          }
                        }
                        navigate(card.bouton?.link?.href || '')
                      }}>
                      <GatsbyImage className="h-40" {...card.image} />
                      <div className={cs('flex flex-col text-purple-dark-500', style.card__bottom_card)}>
                        <div className={cs('card-container px-4 pt-5', style.cardContainer)}>
                          <div className="min-h-20">
                            <h3
                              className={cs(
                                'text-lg font-bold leading-none sm:text-xl md:text-lg lg:text-xl font-brand',
                                style.card__title
                              )}
                            >
                              {card?.titre}
                            </h3>
                            <div className={cs('w-8 h-1 my-4 bg-orange-500 rounded')}></div>
                          </div>
                          <p
                            className={cs(
                              'font-sans text-xs leading-normal text-dark-grey-100 text-base mb-5',
                              style.card__description
                            )}
                          >
                            {card?.description}
                          </p>
                          <h4 className={cs('text-orange-500 mb-3')}>{card.listeDeText.titre}</h4>
                          {card.listeDeText?.liste &&
                            card.listeDeText?.liste?.map((text, id) => (
                              <div key={id} className={cs('block')}>
                                <Icon
                                  name={ICONS.BasicCheck}
                                  width="18"
                                  height="18"
                                  className={cs('mr-1 fill-current text-orange-500 float-left')}
                                />
                                <p className={cs('text-sm text-dark-grey-100 flex')}>{text}</p>
                              </div>
                            ))}
                        </div>
                        <div className={cs('px-4 pb-6 h-full')}>
                          <div className={cs('', style.card__bottom_card_align)}>
                            <h4 className={cs('text-purple-dark-500 font-semibold text-sm mt-1')}>
                              {card.marqueurPrix}
                            </h4>
                            <div className="flex justify-center text-center text-sm mt-5">
                              <Button
                                className={cs('px-10 py-3')}
                                analyticsEventName={IAnalyticsEvent.CLIC_ZONE_PAGE}
                                analyticsDataLayer={{
                                  buttonLabel: card.bouton.title,
                                  zoneCategory: 'body'
                                }}
                                {...(card.bouton?.link?.href
                                  ? buttonPropsFormatter({
                                    ...card.bouton,
                                    link: undefined,
                                    action: 'do-nothing'
                                  })
                                  : buttonPropsFormatter({ ...card.bouton }))}
                              >
                                <span className="text-2xs leading-none md:text-base">
                                  {card.bouton?.title}
                                </span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </section >
  )
}
