import * as yup from 'yup'

export const VALUES_MAPPING: Record<string, string> = {
  'engagement-salarie': "Favoriser l'engagement des salariés",
  'fideliser-talents': 'Attirer/fidéliser les talents',
  'concilier-vie-privee-pro': "Concilier l'équilibre vie privée/vie professionnelle des salariés",
  'ameliorer-marque': 'Améliorer votre marque employeur',
  'diminuer-absenteisme': "Diminuer l'absentéisme",
  aucune: 'Aucune'
}

export default yup
  .object()
  .shape({
    'engagement-salarie': yup.boolean().required('Required'),
    'fideliser-talents': yup.boolean().required('Required'),
    'concilier-vie-privee-pro': yup.boolean().required('Required'),
    'ameliorer-marque': yup.boolean().required('Required'),
    'diminuer-absenteisme': yup.boolean().required('Required'),
    aucune: yup.boolean().required('Required')
  })
  // this tests don't override built-in tests, they are additional
  .test('one-true', '', (form: any) => {
    if (!Object.values(form).some(Boolean))
      throw new yup.ValidationError('Au moins un des choix doit être sélectionné.', '', '')

    return false
  })
  .test('two-maximum', '', (form: any) => {
    if (Object.values(form).filter(Boolean).length > 2)
      throw new yup.ValidationError('Au maximum deux choix doivent être sélectionnés.', '', '')

    return false
  })
