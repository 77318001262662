import { Page } from 'puppeteer'

import deepFindKey from '../misc/deepFindKey'

/**
 * Iterate on state.meta.testSelectorsActions and call actions on matching selectors
 * @param page - The Puppeteer Page to call actions on
 * @param state - The current tested state
 */
export const applyTestsActions = async (page: Page, state: any) => {
  for (let { selector, action } of deepFindKey(state.meta, 'testActions')) {
    await action(page, selector)
  }
}

export default applyTestsActions
