import React, { useLayoutEffect, useRef } from 'react'

// Allows to scroll to next question upon question index change
export default function ScrollAnchor({ active }: { active: boolean }) {
  const anchor = useRef<null | HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (active && anchor.current) {
      anchor.current.scrollIntoView()
    }
  }, [active])

  return (
    <div className="relative">
      <div ref={anchor} className="absolute md:hidden" style={{ top: '-70px' }} />
    </div>
  )
}
