import React from 'react'

import './Steps.css'

import BulletNumberList from '../../BulletNumberList/BulletNumberList'

export interface IStep {
  number: number
  title: string
  subtitle: string
}

interface IProps {
  steps: IStep[]
  currentIndex: number
}

export default function Steps({ currentIndex, steps }: IProps) {
  return (
    <div className="text-center">
      <div className="h-20 bg-orange-500 md:h-32">&nbsp;</div>
      <div className="flex justify-center py-6 mx-6 -mt-10 bg-white rounded-md md:w-5/6 xl:w-4/6 md:mx-auto md:px-10">
        <BulletNumberList
          steps={steps.map(({ number, title }) => ({ number, subtitle: title }))}
          preventVertical={true}
          activeClassName="bg-white border-2 border-orange-500 text-orange-500"
          activeSubtitleClassName="text-purple-dark-500 font-bold"
          currentStep={currentIndex}
        />
      </div>

      <div className="flex flex-col items-center justify-center w-4/5 px-4 pb-4 mx-auto sm:w-3/5 md:py-4 md:pb-12 md:px-1 md:w-1/2 lg:w-2/5">
        <h2 className="text-4xl font-bold font-brand text-purple-dark-500">
          {steps[currentIndex].title}
        </h2>
        <div className="w-12 h-1 my-2 bg-orange-500 rounded-lg" />
        <div
          className="text-lg text-gray-500"
          dangerouslySetInnerHTML={{ __html: steps[currentIndex].subtitle }}
        />
      </div>
    </div>
  )
}
