import GatsbyImage from 'gatsby-image'
import React, { useCallback, useState } from 'react'
import breakpoints from '../../assets/css/breakpoints'
import { Button, BUTTON_VARIANTS } from '../../components/presentationals/Button/Button'
import Icon, { ICONS } from '../../components/presentationals/Icon'
import YoutubeVideoModal from '../../components/presentationals/YoutubeVideoModal/YoutubeVideoModal'
import useBreakpoint from '../../hooks/useBreakpoint'
import { IBannerVideoLink, IDrupalButton } from '../../types/drupal'
import { IImage } from '../../types/gatsby/images'
import buttonPropsFormatter from '../../utils/paragraphs/buttonPropsFormatter'

export interface IProps {
  title: string
  description?: string
  button?: IDrupalButton
  lien?: IBannerVideoLink
  image?: IImage
}

export default function Component({
  title,
  description,
  button,
  lien,
  image,
}: IProps) {
  const isDesktop = useBreakpoint(breakpoints.md);
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>(false)
  const toggleVideoVisibility = useCallback(() => setIsVideoVisible(!isVideoVisible), [
    isVideoVisible,
    setIsVideoVisible
  ])

  return (
    <section className="bg-gray-100">
      <div className="container px-0 py-16 md:py-24 mx-auto">
        {lien?.uri && isVideoVisible && (
          <YoutubeVideoModal uri={lien?.uri} onBackdropClick={toggleVideoVisibility} />
        )}
        <div className="flex flex-col-reverse md:flex-row text-center md:text-left">
          {button && !isDesktop && (
            <div className="flex justify-center mt-6">
              <Button
                {...buttonPropsFormatter(button)}
              />
            </div>
          )}
          {image ? (
            <div className="self-center">
              <div className="relative w-64 lg:w-128">
                <GatsbyImage className="w-full h-full" {...image} />
                {lien?.uri && (
                  <div className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full">
                    <div className="cursor-pointer" onClick={toggleVideoVisibility}>
                      <Icon name={ICONS.Play} width="48" height="48" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="self-center">
              <div className="relative w-64 lg:w-128">
                <iframe src={lien?.uri}
                  width={isDesktop ? '512' : '256'}
                  height={isDesktop ? '256' : '192'}
                  allowFullScreen
                  frameBorder="0"
                />
              </div>
            </div>
          )}
          <div className="w-full md:ml-8">
            <h2 className="text-3xl font-medium leading-none md:text-3xl font-brand text-purple-dark-500">
              {title}
            </h2>
            <div className="w-12 h-1 mx-auto my-4 bg-orange-500 rounded-lg md:mx-0" />
            <p className="m-4 md:my-5 md:mx-0 font-sans leading-normal text-gray-800">
              {description}
            </p>
            {button && isDesktop && (
              <div className="flex justify-start">
                <Button
                  {...buttonPropsFormatter(button)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
