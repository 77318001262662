import { Form, Formik } from 'formik'
import React, { useState, useCallback } from 'react'

import TextField from '../../../../../components/formik/TextField/TextField'
import Spacer from '../../../../../paragraphs/spacer/Component'

import Button, {
  BUTTON_VARIANTS,
  ICON_POSITIONS
} from '../../../../../components/presentationals/Button/Button'
import { useIAdvizeObserver } from '../../../../../hooks/useIAdvizeObserver'
import { IAnalyticsEvent } from '../../../../../utils/analytics/analytics'
import { AVAILABLE_CLASS_SELECTOR } from '../../../../../utils/iAdvize/showModal'
import { BUTTON_ACTIONS_NAMES } from '../../../../../utils/paragraphs/buttonPropsFormatter'
import validationSchema from './Question1Form.validationSchema'

interface IProps {
  onSubmit: (data: any) => void
  dataTestId?: string
  initialValues?: any
  context?: any
  isFetching: boolean
}

const Question1Form = ({ onSubmit, dataTestId, initialValues, context, isFetching }: IProps) => {
  const formattedOnSubmit = useCallback(values => onSubmit({ data: values }), [onSubmit])

  const isIAdvizeAvailable = useIAdvizeObserver(AVAILABLE_CLASS_SELECTOR)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formattedOnSubmit}
      validateOnMount={true}
      validationSchema={validationSchema}
    >
      {props => {
        const { setFieldValue, values, handleSubmit, isValid, errors } = props

        // in order to show errors, keeping state of fields that have lost focus already
        const [blurredFields, setBlurredFields] = useState({
          siren: false,
          companyName: false,
          zipCode: false
        })

        const handleChange = useCallback(e => setFieldValue(e.target.name, e.target.value), [
          setFieldValue
        ])
        const handlePressEnter = useCallback(() => isValid && handleSubmit(), [handleSubmit])
        const handleBlur = useCallback(
          e => setBlurredFields({ ...blurredFields, [e.target.name]: true }),
          [blurredFields, setBlurredFields]
        )

        return (
          <Form>
            <div data-testid={dataTestId}>
              <TextField
                className="mb-8"
                name="siren"
                label="Trouver mon entreprise dans la base Siren"
                pattern="^\d+$"
                maxLength={9}
                placeholder="Numéro de Siren"
                value={values['siren']}
                onChange={handleChange}
                onPressEnter={handlePressEnter}
                onBlur={handleBlur}
                disabled={isFetching}
                trimWhitespace={true}
                error={blurredFields.siren ? (errors['siren'] as string) : undefined}
              />

              <Spacer title="ou" />

              <fieldset>
                <TextField
                  name="companyName"
                  className="mb-4"
                  label="Raison sociale"
                  placeholder="La raison sociale de mon entreprise"
                  value={values['companyName']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  disabled={isFetching}
                  error={blurredFields.companyName ? (errors['companyName'] as string) : undefined}
                />

                <TextField
                  name="zipCode"
                  label="Code postal"
                  placeholder="Mon code postal"
                  pattern="^\d+$"
                  maxLength={5}
                  value={values['zipCode']}
                  onChange={handleChange}
                  onPressEnter={handlePressEnter}
                  onBlur={handleBlur}
                  disabled={isFetching}
                  error={blurredFields.zipCode ? (errors['zipCode'] as string) : undefined}
                />
              </fieldset>

              <div className="mt-10 text-center">
                {context.entreprise.invokeError && (
                  <div className="mb-2 italic text-red-500 whitespace-pre-wrap md:mb-4">
                    <span className="mr-1">{context.entreprise.invokeError}</span>
                    {isIAdvizeAvailable && (
                      <>
                        <br />
                        <span className="mr-1">Si les difficultés persistent vous pouvez</span>
                        <Button
                          className="italic text-red-500 underline"
                          action={BUTTON_ACTIONS_NAMES.CONTACT_POPIN}
                          variant={BUTTON_VARIANTS.NONE}
                          analyticsEventName={IAnalyticsEvent.ICONE_RAPPEL}
                          ariaLabel="Être rappelé"
                        >
                          demander à être rappelé(e).
                        </Button>
                      </>
                    )}
                  </div>
                )}

                <Button
                  className="relative flex justify-center w-full md:w-2/5"
                  dataTestId="trigger-next-button"
                  loading={isFetching}
                  iconPosition={isFetching ? ICON_POSITIONS.RIGHT : undefined}
                  iconClassname="absolute right-0 top-0 mr-4 mt-1 transform scale-75"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  onClick={handleSubmit}
                  disabled={!isValid || isFetching}
                  ariaLabel="Je valide"
                  analyticsDataLayer={{
                    buttonLabel: "Je valide"
                  }}
                >
                  {isFetching ? 'Recherche...' : 'Je valide'}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Question1Form
