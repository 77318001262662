import { useState, useEffect, useMemo } from 'react'

const defaultCallback = (mutationList: MutationRecord[], _: MutationObserver) => mutationList

// Extracted and adpated to our needs from https://github.com/danielr18/react-hook-mutation-observer
export function useMutationObserver(
  selector: string,
  observerOptions: MutationObserverInit = { attributes: true },
  callback: (mutations: MutationRecord[], observer: MutationObserver) => any = defaultCallback
) {
  const [value, setValue] = useState(undefined)
  const [targetNode, setTargetNode] = useState<Element | null>(null)

  const observer = useMemo(
    () =>
      targetNode
        ? new MutationObserver((mutations, obs) => {
            const result = callback(mutations, obs)
            setValue(result)
          })
        : null,
    [callback]
  )

  useEffect(() => {
    setTargetNode(document.querySelector(selector))
  }, [selector])

  useEffect(() => {
    if (targetNode && observer) {
      observer.observe(targetNode, observerOptions)

      return () => {
        observer.disconnect()
      }
    }
  }, [targetNode, observerOptions])

  return value
}
